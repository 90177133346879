import { ServerCode } from "../constants";
import { store } from "../store";
import { SIGN_OUT } from "../store/actions";
import { errorToaster } from "../utils/loggerUtils";
import { Socket } from "../utils/socketUtils";

// export const handleFetchError = async (res) => {
//     const response = await res.clone().json();
//     if (
//         response.status === ServerCode.INTERNAL_SERVER_ERROR ||
//         response.status === ServerCode.SERVICE_UNAVAILABLE
//     ) {
//         // toaster({ title: response.Message, type: "danger" });
//         console.log("response from server", response.data.Message);
//         throw await res.json();
//     } else if (
//         response.status >= ServerCode.BAD_REQUEST &&
//         response.status < ServerCode.CONNECTION_TIMED_OUT
//     ) {
//         if (response.status === ServerCode.UNAUTHORIZED) {
//             // store.dispatch({ type: SIGN_OUT });
//             // localStorage.clear();
//             localStorage.isAuthenticated = false;
//             Socket.disconnect();
//         }
//         console.log("response from server", response.data.Message);
//         // toaster({ title: response.Message, type: "danger" });
//         throw await res.json();
//     }
//     return res.json();
// };

export const handleFetchError = async (res, showError) => {
  // console.log(res, "RESPONSE")
  if (res.status >= ServerCode.BAD_REQUEST && res.status < ServerCode.CONNECTION_TIMED_OUT) {

    const response = await res.clone().json();
    let errRes = {
      ...response,
      Message: response.Message,
      Status: res.status
    };
    if (res.status === ServerCode.UNAUTHORIZED) {
      console.log("inside first condition clause", response)
      store.dispatch({ type: SIGN_OUT });
      localStorage.isAuthenticated = false;
      localStorage.clear();
      Socket.disconnect();
      if (showError && res.status!= ServerCode.NOT_FOUND) {
        errorToaster(response.Errors || response.Message || `Your Session has been Expired!`);
      }
      //Socket.disconnect();
    } else {
      if (showError && res.status!= ServerCode.NOT_FOUND) {
        errorToaster(response.Errors || response.Message || "Your Session has been Expired!");
      }
    }
    throw errRes;
  }

  return res.json();
};
