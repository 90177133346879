import {
    ENTITY_ADD_CHAT,
    ENTITY_UPDATE_CHAT,
    ENTITY_UPDATE_CHAT_MESSAGE_READ_STATUS,
    ENTITY_UPDATE_CHAT_MESSAGE,
    ENTITY_UPDATE_CHAT_USER
} from "../actions";

const initialState = {
    chats: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ENTITY_ADD_CHAT:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    ...action.payload
                }
            };
        case ENTITY_UPDATE_CHAT_MESSAGE:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[action.id],
                        Message: {
                            ...state.chats[action.id]?.Message,
                            ...action.data
                        }
                    }
                }
            };
        case ENTITY_UPDATE_CHAT_MESSAGE_READ_STATUS:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[action.id],
                        Message: {
                            ...state.chats[action.id]?.Message,
                            ...action.data
                        }
                    }
                }
            };
        case ENTITY_UPDATE_CHAT:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[action.id],
                        ...action.data
                    }
                }
            };
        case ENTITY_UPDATE_CHAT_USER:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[action.id],
                        User: {
                            ...state.chats[action.id]?.User,
                            ...action.data
                        }
                    }
                }
            };
        default:
            return state;
    }
};

