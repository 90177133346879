import React from "react";
import "./ChatBoxScreen.scss";
import { connect } from "react-redux";
import ChatProfile from "../../assets/images/profile.PNG";
import AttachmentsIcon from "../../assets/images/AttachmentsIcon.svg";
import Sendoffwidgetbutton from "../../assets/images/Sendoffwidgetbutton.svg";
import Config from "../../Config";
import { MdSend } from "react-icons/md";
import { store } from "../../store";

const ChatBoxScreen = ({ description, userFiles, websiteInfo , user }) => {
    
    return (
        <section className="chat-box-sec">
            <div className="chat-box-header">
                <figure>
                    <div className="logo-container">
                        <span className="status-indicator status-online" />
                        <div className="chat-profile mt-32">
                            <img src={`${Config.env().BASE_IMG_PATH}/${user.Company?.Logo?.ThumbPath}`} />
                        </div>
                    </div>
                    <figcaption>
                        {websiteInfo?.websiteName ? (<h2>{websiteInfo?.websiteName}</h2>) : null}
                        <p>
                            {store.getState()?.auth?.user?.Name ? store.getState()?.auth?.user?.Name : null} <span class="status">(online)</span>
                        </p>
                    </figcaption>
                </figure>
            </div>
            <div className="chat-box-body">
                <div className="chat-bubble">{description}</div>

                <div className="quick-msg">
                    {userFiles.length > 0 &&
                        userFiles.map((item) => {
                            return (
                                <>
                                    <span className=" q-bubble LDA-max-width-chatBox ">
                                        <span className="  multiline-truncate ">
                                            <strong title={item.Name}>{item.Name}</strong>
                                        </span>
                                    </span>
                                </>
                            );
                        })}
                </div>
            </div>
            <div className="chat-box-input">
                <input type="text" placeholder="Type your message..."></input>
                <div className="chat-attachment">
                    <img src={AttachmentsIcon} alt="Attachment" />
                </div>
                <div className="chat-send">
                    <img src={Sendoffwidgetbutton} alt="Send off widget button" />
                    <MdSend />
                </div>
            </div>
            {websiteInfo?.brandedFooter ? <div className="chat-box-footer">
                POWERED BY <b>LONG DRIVE</b>
                AGENCY
            </div> : null}
        </section>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};
export default connect(mapStateToProps, null)(ChatBoxScreen);

