import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { message, Popover, Spin } from "antd";
import "moment-timezone";
import { connect, useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical } from "@react-icons/all-files/bs/BsThreeDotsVertical";
import uuidv4 from "uuid/v4";

import "./inbox.scss";

import Details from "./details";
import { useChatMessagesHook } from "../../ApiHook/ChatMessagesHook";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import Onboardlogo from "../../assets/images/Onboardlogo.svg";
import { InboxChatBoxInput } from "../../components/Inbox";
import {
    addMessage,
    clearCurrentChat,
    getChatById,
    getMessageListing,
    moveChatToTop,
    postChatMedia,
    removeSelectedChat,
    setCurrentChat,
    updateChatInEntity,
    updateChatMessageData,
    updateChatMessageId,
    updateChatMessageReadStatus,
    updateChatMessageInEntity,
    setUnreadWebChatList
} from "../../store/actions";
import { scrollToBottom } from "../../utils/domUtil";
import ChatMessageList from "./ChatMessageList";
import Archive from "../../assets/svg-icon/archive.svg";
import ResolveChatModal from "../../components/automation-modal/ResolveChatModal";
import { OnlineBadge } from "../../assets/svg-icon";

const TYPING_DURATION = 2000;

const ChatMessageSection = (props) => {
    const unreadWebChatList = useSelector((state) => state.chat.unreadWebChatList)
    const {
        currentChat,
        fetching,
        dataAvailable,
        messages,
        chatDetails,
        unReadMessages,
        entities,
        user
    } = props;
    const chatMessageAbortController = useRef(null);
    const typingInterval = useRef(null);
    const { id, tabname } = useParams();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isConversation, setIsConversation] = useState(false);
    const [showUnReadMessages, setShowUnReadMessages] = useState(false);
    const [allMessages, setAllMessages] = useState([]);
    const [unReadMessagesStateData, setUnReadMessagesStateData] = useState([]);
    const [page, setPage] = useState(1);
    const [isTyping, setIsTyping] = useState(false);
    const [chatResolveModal, setChatResolveModal] = useState(false);

    useEffect(() => {
        setIsTyping(false);
        updateReadMessages();
        setTimeout(() => {
            unReadMessages != undefined && unReadMessages?.length != 0 && hideReadMessages();
        }, 4000);
    }, [messages, id]);

    const updateReadMessages = () => {
        if (unReadMessages != undefined && unReadMessages?.length != 0) {
            setShowUnReadMessages(true);
            let updatedArray = messages;
            // for (let i = 0; i < unReadMessages.length; i++) {
            //     updatedArray = messages.filter((item) => item.Id != unReadMessages[i].Id);
            // }
            let firstUnreadId = unReadMessages[0]?.Id;
            updatedArray = messages.filter((item) => item?.Id < firstUnreadId)
            setAllMessages(updatedArray);
            setUnReadMessagesStateData(unReadMessages);
        } else {
            setUnReadMessagesStateData([]);
            setShowUnReadMessages(false);
            setAllMessages(messages);
        }
    };

    const hideReadMessages = async () => {
        setShowUnReadMessages(false);
        let message = currentChat?.Message;
        await dispatch(updateChatMessageData(currentChat?.Id, { ...message, unReadMessages: [], unReadStatus: false }));
    };

    useEffect(() => {
        chatMessageAbortController.current = new AbortController();
        dispatch(
            updateChatMessageReadStatus(currentChat?.Id, {
                unReadStatus: false
            })
        );
        dispatch(getMessageListing(id, {}, chatMessageAbortController.current.signal))
            .then((res) => {
                setTimeout(() => {
                    scrollToBottom();
                    setIsLoading(false);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 80)
                }, 100);
            })
            .catch((err) => { });
        setIsConversation(false)
        return () => {
            setPage(1);
            dispatch(clearCurrentChat());
            if (chatMessageAbortController.current) {
                chatMessageAbortController.current.abort();
            }
        };
    }, [id, dispatch]);

    useEffect(() => {
        if (!currentChat) {
            setIsLoading(true);
            dispatch(getChatById(id)).finally(() => {
            });
        } else {
            dispatch(setCurrentChat(parseInt(id)));
        }

        //send read socket 
        Socket.emitReadUnreadChat({
            ChatMessageId: currentChat?.Message?.Id,
            ChatId: currentChat?.Id,
            AgentId: currentChat?.AgentId,
        })
        handleWebchatReadUnread(currentChat?.Id)

    }, [id, currentChat, dispatch]);

    useEffect(() => {
        function handleMessageTyping(data) {
            if (data.ChatId === currentChat?.Id) {
                if (typingInterval.current) {
                    clearInterval(typingInterval.current);
                }
                setIsTyping(true);
                typingInterval.current = setTimeout(() => {
                    setIsTyping(false);
                }, TYPING_DURATION);
            }
        }
        function handleStatusChange(data) {
            if (data.ChatId) {
                let chatFound = entities?.chats[data.ChatId];
                if (chatFound) {
                    let User = chatFound.User;
                    if (User.Id == data.VisitorId) {
                        User.OnlineStatus = data.OnlineStatus;
                        dispatch(updateChatInEntity(data.ChatId, { User }));
                        handleWebchatReadUnread(data.ChatId)
                    }
                }
            }
        }

        Socket.onUserTyping(handleMessageTyping);
        Socket.onUserStatus(handleStatusChange);

        return () => {
            Socket.remove(SocketTypes.USER_Typing, handleMessageTyping);
            Socket.remove(SocketTypes.USER_STATUS, handleStatusChange);
        };
    }, [id, currentChat]);

    const handleWebchatReadUnread = (id) => {
        console.log("removing read conversation in chat message sections", id)
        const unreadConvos = unreadWebChatList?.map(item => item)
        const newUnreadConvos = unreadConvos.filter(item => item.Id !== id)
        dispatch(setUnreadWebChatList([...newUnreadConvos]))
    }

    const handleScroll = (e) => {
        const isTop = e.target.scrollTop < 30;

        if (isTop && !fetching && dataAvailable && !isConversation) {
            setUnReadMessagesStateData([]);
            setShowUnReadMessages(false);
            setAllMessages(messages);
            let container = document.getElementById("chatbox-scroll-div");
            let prevScrollTop = container.scrollTop;
            let prevScrollHeight = container.scrollHeight;
            let currentPage = page + 1;
            dispatch(getMessageListing(id, { Page: currentPage })).then((res) => {
                container.scrollTop = container.scrollHeight - (prevScrollHeight - prevScrollTop);
            });
            setPage(currentPage);
        }
    };

    const handleAddMessage = async (messageContent, mediaObj) => {
        const uniqueId = uuidv4();

        const socketMsgData = {
            Content: messageContent,
            SenderType: 2,
            RoomKey: currentChat?.ChatRoomKey,
            AgentId: user.Id,
        };

        const addMsgData = {
            Id: uniqueId,
            CreatedAt: Date.now(),
            UpdatedAt: Date.now(),
            SenderType: 2,
            Content: messageContent
        };

        if (mediaObj && mediaObj.type === "image") {
            const body = new FormData();
            body.append("name", mediaObj.name);
            body.append("file", mediaObj.Obj);
            let url = await dispatch(postChatMedia(body));
            socketMsgData["MediaUrl"] = url.location;
            addMsgData["MediaUrl"] = url.location;
        }

        if (mediaObj && mediaObj.type === "giyf") {
            socketMsgData["MediaUrl"] = mediaObj.url;
            addMsgData["MediaUrl"] = mediaObj.url;
        }
        await dispatch(updateChatMessageInEntity(currentChat?.Id, addMsgData));

        Socket.joinChat({
            RoomKey: currentChat?.ChatRoomKey,
            AgentId: user?.Id
        }, () => {
            Socket.sendMessage(socketMsgData, async (data) => {
                dispatch(updateChatMessageId(uniqueId, data?.Id));
                // await dispatch(updateChatMessageData(currentChat.Id, addMsgData));
                // dispatch(moveChatToTop(currentChat.Id, tabname));
            });
        });

        await dispatch(updateChatMessageData(currentChat?.Id, addMsgData));
        dispatch(moveChatToTop(currentChat?.Id, tabname));

        dispatch(addMessage(addMsgData));
    };

    const handleChatResolve = () => {
        Socket.emitChatClosed(
            {
                ChatRoomKey: currentChat?.ChatRoomKey,
                UserType: 2,
                // UserId: currentChat.AgentId
                UserId: user?.Id
            },
            (data) => {
                console.log("sfdljdsfkldsjf sdjfkds fjds", data);
            }
        );
        dispatch(removeSelectedChat(currentChat?.Id));
        dispatch(updateChatInEntity(currentChat?.Id, { Status: "closed" }));
    };

    const handleChatResolveModal = () => {
        setChatResolveModal(true);
    };

    const handleDeselectConversation = () => {
        setIsConversation(false);
        setPage(1);
        dispatch(getMessageListing(id, {}, chatMessageAbortController.current.signal))
            .then((res) => {
                setTimeout(() => {
                    scrollToBottom();
                }, 30);
            })
            .catch((err) => { });
    };

    const actionOptions = () => {
        return (
            <ul className="customer-popover">
                <li onClick={handleChatResolveModal} className="icon-wrapper">
                    <img src={Archive} width={20} className="margin-right-10" />
                    <p className="margin-0">Close Conversation</p>
                </li>
            </ul>
        );
    };

    const dropDown = () => {
        return (
            <Popover placement="bottomLeft" content={() => actionOptions()} trigger="click">
                <div className="cursor">
                    <BsThreeDotsVertical className="menu-icon" />
                </div>
            </Popover>
        );
    };

    return !isLoading && currentChat ? (
        <React.Fragment>
            <div className="inbox-main-panel">
                <div className="inbox-header">
                    <div className="ih-left">
                        {currentChat?.Status === "active" ? dropDown() : null}
                        {currentChat?.User?.Name ? (
                            <h3 className="margin-left-10">{currentChat?.User?.Name}</h3>
                        ) : (
                            <h3 className="margin-left-10">Visitor</h3>
                        )}
                        {currentChat?.User?.Name ? (
                            <span className={`badge ${currentChat?.User?.OnlineStatus ? "badge-green" : "badge-red"}`}>
                                {currentChat?.User?.OnlineStatus ? "Online" : "Offline"}
                            </span>
                        ) : null}
                    </div>
                    {
                        <div>
                            {isConversation && (
                                <button
                                    onClick={handleDeselectConversation}
                                    className="deselectBtn"
                                >
                                    Deselect Conversation
                                </button>
                            )}
                            {currentChat?.Status === "active" ? (chatResolveModal ? (
                                <ResolveChatModal
                                    show={() => setChatResolveModal(true)}
                                    hide={() => setChatResolveModal(false)}
                                    classchat={"as-complete-modal"}
                                    title={"Resolve Chat"}
                                    text={
                                        "This will close the chat. Are you sure, you want to do this?"
                                    }
                                    btntext1={"Confirm Resolve Chat"}
                                    onClickPA={() => {
                                        handleChatResolve();
                                        setChatResolveModal(false);
                                    }}
                                />
                            ) : null) : null}
                        </div>
                    }
                </div>

                <div className="inbox-body" id="chatbox-scroll-div" onScroll={handleScroll}>
                    <div className="inbox-msgs">
                        {fetching ? (
                            <div
                                className={
                                    !messages.length
                                        ? "center-spiner msg-loader-height"
                                        : "msg-loadig"
                                }
                            >
                                <Spin />
                            </div>
                        ) : null}
                        {!fetching && allMessages.length == 0 && !unReadMessages && (
                            <section className="blankInbox">
                                <img src={Onboardlogo} alt="Logo" />
                                <h3>No Messages</h3>
                            </section>
                        )}
                        {allMessages.length != 0 && (
                            <ChatMessageList
                                unReadStatus={false}
                                messages={allMessages}
                                currentChat={currentChat}
                            />
                        )}
                        {!fetching &&
                            unReadMessages != undefined &&
                            unReadMessages?.length != 0 &&
                            showUnReadMessages && (
                                <div className="unread-msg-panel">
                                    <div className="unread-msg-bar">Unread Messages</div>
                                </div>
                            )}
                        {!fetching && unReadMessagesStateData.length != 0 && (
                            <ChatMessageList
                                unReadStatus={true}
                                messages={unReadMessagesStateData}
                                currentChat={currentChat}
                            />
                        )}
                        {isTyping ? (
                            <div className="ib-panel ib-left-panel">
                                <div className="ib-panel-body">
                                    <div className="ib-panel-msg msg-typing">
                                        <div class="tiblock">
                                            <div class="tidot"></div>
                                            <div class="tidot"></div>
                                            <div class="tidot"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {currentChat?.Status === "active" && (
                    <div id="input-container" className="middle-chat-form">
                        <div style={{ flexGrow: "1", maxWidth: "calc(100vw - 550px)" }}>
                            <InboxChatBoxInput
                                onMessageSend={handleAddMessage}
                                currentChat={currentChat}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Details setValue={setIsConversation} />
        </React.Fragment>
    ) : !isLoading && !currentChat ? (
        <section className="blankInbox">
            <img src={Onboardlogo} alt="Logo" />
            <h3>No Messages</h3>
        </section>
    ) : (
        <div className="center-spiner">
            <Spin />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const currentChat = state.entities.chats[ownProps.match.params?.id];

    return {
        currentChat,
        unReadMessages: currentChat?.Message?.unReadMessages,
        fetching: state.chat.currentChat.fetching,
        messages: state.chat.currentChat.messages,
        dataAvailable: state.chat.currentChat.dataAvailable,
        chatDetails: state.chat.currentChat.details,
        entities: state.entities,
        user: state.auth?.user,
    };
};

export default connect(mapStateToProps)(ChatMessageSection);

