export default {
    SUCCESS: 200,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    CONNECTION_TIMED_OUT: 599,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
};
