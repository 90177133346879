import React, { useState } from "react";
import { WebChatHook } from "../ApiHook/WebChatHook";
import { AccessOtherHook } from "../ApiHook/AccessOtherHook";
import { LoginUserApiHook } from "../ApiHook/LoginUserApiHook";
export const GlobalContext = React.createContext();

const Global = ({ children }) => {
    const [greet, setGreet] = useState("Message");
    const [current, setCurrent] = React.useState(0);
    const [redirect, setRedirect] = React.useState(0);
    const [store, setStore] = useState({});
    const [postData, setpostData] = useState({});
    const [assignUsersRes, setassignUsersRes] = useState({});

    const { companyData, checkCache, setCheckCache } = WebChatHook();
    const { teamName, checkCacheTeam, setCheckCacheTeam } = AccessOtherHook();
    const { loginUserData } = LoginUserApiHook();

    const [activeAutomationItem, setActiveAutomationItem] = useState({
        Id: "",
        InformationAutoResponse: "",
        PageUrl: "",
        Name: "",
        IntroMessage: "",
        ChatTrigger: "",
        InformationCollection: ""
    });

    const [automationData, setAutomationData] = useState({});
    // For Status
    const [statusRequestLive, setStatusRequestLive] = useState({});

    const [welcomMessage, setWelcomMessage] = useState("");

    const [getAutomationDataActive, setGetAutomationDataActive] = useState({});

    const [tbldata, settbldata] = useState([]);

    const [mediaData, setMediaData] = useState([]);

    const [automationformValue, setAutomationformValue] = useState([]);

    // const [automationPostData,]

    return (
        <GlobalContext.Provider
            value={{
                store,
                setStore,
                greet,
                setGreet,
                companyData,
                checkCache,
                setCheckCache,
                current,
                setCurrent,
                postData,
                setpostData,
                teamName,
                checkCacheTeam,
                setCheckCacheTeam,
                assignUsersRes,
                setassignUsersRes,
                welcomMessage,
                setWelcomMessage,
                automationData,
                setAutomationData,
                activeAutomationItem,
                setActiveAutomationItem,
                getAutomationDataActive,
                setGetAutomationDataActive,
                tbldata,
                settbldata,
                automationformValue,
                setAutomationformValue,
                mediaData,
                setMediaData,
                statusRequestLive,
                setStatusRequestLive,
                loginUserData
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default Global;
