import { errorToaster } from "../../utils/loggerUtils";
import { appendQueryParams } from "../../utils/urlUtil";
import { fetchUtil } from "../../utils/fetchUtil";

export const AUTH_TOKEN_REQUEST = "AUTH_TOKEN_REQUEST";
export const AUTH_TOKEN_SUCCESS = "AUTH_TOKEN_SUCCESS";
export const AUTH_TOKEN_FAIL = "AUTH_TOKEN_FAIL";
export const SSO_ME_SUCCESS = "SSO_ME_SUCCESS";
export const SSO_ME_FAIL = "SSO_ME_FAIL";
export const CLEAR_SSO_SESSION = "CLEAR_SSO_SESSION";

export const getSSOAuthToken = (apiKey) => (dispatch, getState) => {
    dispatch({ type: AUTH_TOKEN_REQUEST });

    return fetchUtil({
        url: appendQueryParams("/auth-token", { ApiKey: apiKey }),
        method: "POST"
    })
        .then((res) => {
            dispatch({ type: AUTH_TOKEN_SUCCESS, token: res.Token, apiKey });
            dispatch(ssoGetMe(res.Token));
            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({ type: AUTH_TOKEN_FAIL, payload: err.Message });
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const ssoGetMe = (token) => (dispatch, getState) => {
    fetchUtil({
        url: "/me",
        token
    })
        .then((res) => {
            dispatch({ type: SSO_ME_SUCCESS, payload: res.User });
        })
        .catch((err) => {
            dispatch({ type: SSO_ME_FAIL, payload: err.Message });
            errorToaster(err.Message);
        });
};

export const clearSSOSession = () => {
    return { type: CLEAR_SSO_SESSION };
};
