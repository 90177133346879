import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { APP_ROUTES, getRoute } from "../../helpers/routesHelper";
import { setActiveCompany, setCurrent, SET_ACTIVE_COMPANY } from "../../store/actions/automationActions";
import ClubGreenCheck from "../../assets/svg-icon/ClubGreenCheck.svg";
import "./card.scss";

const CardInActive = ({ item }) => {
    const { WebsiteDomain, WebsiteName, Users } = item;
    const history = useHistory();
    const dispatch = useDispatch();

    const mapUsers = (items) => {
        if (items.length == 2) {
            return `${items[0].Name} and ${items[1].Name}`;
        } else if (Users.length > 2) {
            return `${items[0].Name} and ${items.length - 1} more`;
        } else {
            return items[0]?.Name;
        }
    };

    return (
        <div className="wc-card color-in-acive wc-card-top space-between">
            <div>
                <div className="icon-wrapper">
                    <img src={ClubGreenCheck} className="icon-style" width={15} />
                    <div className="status-title">SETUP COMPLETED</div>
                </div>
                <div>
                    <p className="text margin-top-35">WEBSITE NAME</p>
                    <p className="bold-content ellipses" title={WebsiteName}>
                        {WebsiteName}
                    </p>
                    <p className="text font-14 ellipses" title={WebsiteDomain}>
                        {WebsiteDomain}
                    </p>
                </div>
                <div className="wc-mt-32">
                    <p className="text">ACCESS TO</p>
                    <p className="wc-text-blue font-weight-500 margin-0">{mapUsers(Users)}</p>
                </div>
            </div>
            <div>
                <button
                    className="d-btn margin-0"
                    onClick={() => {
                        dispatch(setCurrent(0));
                        dispatch(setActiveCompany(item.Id))
                        if (item.UserAssigned == 0) {
                            history.push(getRoute(APP_ROUTES.ACCOUNT_SETUP, { id: item.Id }));
                        } else {
                            history.push("/dashboard/inbox/active");
                        }
                    }}
                >
                    Continue to Messages
                </button>
            </div>
        </div>
    );
};

export default CardInActive;

