import React, { useState, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import "../Form.css";

import { CloseEyeLogo, OpenEyeLogo, LogoSvg } from "../../../assets/svg-icon/index";
import { Spinner } from "../../../components/Loader";
import WithBodyBackground from "../WithBackground";
import { changePassword } from "../../../store/actions";

const ChangePassword = (props) => {
    let { Code } = useMemo(() => {
        let params = new URLSearchParams(props.location.search);
        let Code = params.get("Code");
        return { Code };
    }, [props.location]);

    const [password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [passwordError, setpasswordError] = useState(null);
    const [changePasswordComplete, setchangePasswordComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hidden, toggleShow] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setpasswordError(true);
            return;
        }
        setIsLoading(true);
        dispatch(changePassword(password, Code))
            .then(() => {
                setIsLoading(false);
                setchangePasswordComplete(true);
            })
            .catch((e) => {
                setIsLoading(false);
            });
    };

    const handleOnPasswordChange = (e) => {
        setPassword(e.target.value);
        setpasswordError(null);
    };

    const handleOnConfirmPasswordChange = (e) => {
        setconfirmPassword(e.target.value);
        setpasswordError(null);
    };

    if (changePasswordComplete) {
        return <Redirect to="/account/login" />;
    }

    return (
        <form className="login-form" onSubmit={handleSubmit}>
            <div className="login-logo">
                <img src={LogoSvg} className="img-fluid" alt="img" />
            </div>
            <div className="login-form">
                <div className="form-group">
                    <h2>Create a new password</h2>
                    {passwordError && (
                        <h2>
                            Passwords <strong>must</strong> match
                        </h2>
                    )}

                    <div className="p-relative">
                        <input
                            minLength={6}
                            type={!hidden ? "password" : "text"}
                            placeholder="New Password"
                            className="form-control"
                            value={password}
                            required
                            onChange={handleOnPasswordChange}
                        />
                        <span className="eye" onClick={() => toggleShow(!hidden)}>
                            {!hidden ? (
                                <img src={CloseEyeLogo} className="img-fluid" alt="img" />
                            ) : (
                                <img src={OpenEyeLogo} className="img-fluid" alt="img" />
                            )}
                        </span>
                    </div>
                    <input
                        type={!hidden ? "password" : "text"}
                        placeholder="Confirm Password"
                        className="form-control"
                        value={confirmPassword}
                        required
                        onChange={handleOnConfirmPasswordChange}
                    />
                </div>
                <div className="form-group">
                    <button type="submit" disabled={isLoading}>
                        <span>Save Password</span>
                        {isLoading ? <Spinner color="white" size="lg" /> : null}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default WithBodyBackground(ChangePassword);
