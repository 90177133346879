import React from "react";
import InActiveCard from "./card-in-active";
import ActiveCard from "./card-active";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { APP_ROUTES, getRoute } from "../../helpers/routesHelper";
const Cards = ({ data, user }) => {
    if (data.length == 0) {
        <Redirect to={getRoute(APP_ROUTES.WEB_CHAT, { cId: 1 })} />;
    }
    if (user.Type == 2) {
        return <Redirect to={'/dashboard/inbox/active'} />;
    }

    return (
        <div className="card-component">
            <div className="bold-content page-header font-weight-900">Complete Website Setup</div>
            <div className="text wc-text-blue font-16 font-weight-500">
                Complete setup for pending websites or continue to messages
            </div>
            <div className="all-cards">
                {data?.map((item) => {
                    if (item.IsCompleted == 1 && item.UserAssigned == 1) {
                        return <InActiveCard item={item} />;
                    } else {
                        return <ActiveCard item={item} />;
                    }
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const data = state.automation.companyListing;
    const user = state.auth.user;
    return { data, user };
};
export default connect(mapStateToProps)(Cards);

