import { ME_SUCCESS, SET_SOUND_STATUS, CHANGE_ADMIN_VIEW, SET_HEADER_STATUS, SET_WEBCHAT_SOUND_STATUS } from "../actions";

const initialState = {
  isAuthenticated: false,
  isVerified: false,
  // token: "254fe759-2bd4-4e8a-85f4-d903aeafe9f0",
  token: "",
  redirectUrl: null,
  isAdmin: false,
  user: {},
  isHeaderStatus: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ME_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          ...action.payload.User
        },
        token: action.authToken
      };
    case SET_HEADER_STATUS:
      return {
        ...state,
        isHeaderStatus: action.isHeaderStatus
      };
    case SET_SOUND_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          Company: {
            ...state.user.Company,
            SoundStatus: action.soundStatus
          }
        }
      };
    case SET_WEBCHAT_SOUND_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          Company: {
            ...state.user.Company,
            SoundStatus: action.payload
          }
        }

      }
    case CHANGE_ADMIN_VIEW:
      return {
        ...state,
        isAdmin: action.isAdmin,
        redirectUrl: action.redirectUrl
      };
    default:
      return state;
  }
};
