import axios from "axios";
import Config from "../Config";

export default axios.create({
    // baseURL:"http://192.168.0.182:3010/"
    // baseURL:"http://localhost:3006/"
    // baseURL: "http://54.215.117.148:3010/",
    baseURL: Config.env().API_URL
    // baseURL: "https://apirangeconnect.tk/"
});
