import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./modal.scss";

const PauseAutomation = (props) => {
    // Set Automation Live
    const { show, hide, classchat, title, text, btntext2, btntext1, onClickPA } = props;
    return (
        <section className="as-complete-sec">
            <Modal
                show={show}
                onHide={hide}
                className={classchat}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton> </Modal.Header>
                <Modal.Body>
                    <h3>{title}</h3>
                    <p>{text}</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className=" w-100 text-center">
                        <Button variant="primary" style={{opacity:0.5}} className="m-2" onClick={hide}>
                            {btntext2}
                        </Button>
                        <Button variant="primary" onClick={onClickPA}>
                            {btntext1}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </section>
    );
};
export default PauseAutomation;
