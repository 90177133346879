import React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { APP_ROUTES, getRoute } from "../../helpers/routesHelper";
import { setActiveCompany, setCurrent } from "../../store/actions/automationActions";
import { BsCircle } from "react-icons/bs";
import "./card.scss";

const CardActive = ({ item }) => {
    const { WebsiteDomain, WebsiteName, UserAssigned } = item;
    const history = useHistory();
    const dispatch = useDispatch()

    return (
        <div className="wc-card color-in-acive wc-card-top  space-between">
            <div>
                <div className="icon-wrapper">
                    <BsCircle className="icon-style stroke-width" />
                    <div className="status-title">FINISH SETUP</div>
                </div>
                <div>
                    <p className="text margin-top-35">WEBSITE NAME</p>
                    <p className="bold-content ellipses" title={WebsiteName}>
                        {WebsiteName}
                    </p>
                    <p className="text font-14 ellipses" title={WebsiteDomain}>
                        {WebsiteDomain}
                    </p>
                </div>

                <div className="wc-mt-32">
                    <p className="text">FINISH SETUP</p>
                    {UserAssigned == 0 && (
                        <p className="wc-text-blue font-weight-500 margin-0">Install Widget</p>
                    )}
                    <p className="wc-text-blue font-weight-500 margin-0">Set Welcome Message</p>
                </div>
            </div>
            <div>
                <button
                    className="d-btn margin-0 green"
                    onClick={() => {
                        setCurrent(0);
                        dispatch(setActiveCompany(item.Id))
                        if (item.UserAssigned == 0) {
                            history.push(getRoute(APP_ROUTES.ACCOUNT_SETUP, { id: item.Id }));
                        } else {
                            history.push(getRoute(APP_ROUTES.WEB_CHAT, { cId: item.Id }));
                        }
                    }}
                >
                    Complete Installation
                </button>
                {/* <button className="d-btn mt-1 disable" disabled={true}>
                    Finish Later
                </button> */}
            </div>
        </div>
    );
};

const mapDispatchToProps = { setCurrent };
export default connect(null, mapDispatchToProps)(CardActive);

