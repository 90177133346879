import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../list.scss";
import { Spin } from "antd";
import moment from "moment";

import AutomationHeader from "../../../components/automation-header/automation-header";
import { APP_ROUTES, getRoute } from "../../../helpers/routesHelper";
import { inactiveAutomationListing } from "../../../store/actions/automationActions";

function InActiveList() {
    const inActiveListParams = useParams();
    const [tbldata, settbldata] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoadingInactive, setIsLoadingInactive] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [inActiveListParams?.id, inActiveListParams?.status]);
    const unixToDate = (date) => {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        interval = seconds / 3600;
        if (interval > 1) {
            if (interval <= 12) {
                return Math.floor(interval) + " h";
            }

            if (Math.floor(interval) <= 24) {
                return " today";
            }

            if (Math.floor(interval) < 48) {
                return " Yesterday";
            }
            return moment(date).format("MM/DD/YYYY");
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " m";
        }
        return Math.floor(seconds) + " s";
    };

    // Get Automation Data

    useEffect(() => {
        setIsLoadingInactive(true);
        dispatch(inactiveAutomationListing(inActiveListParams?.cId))
            .then((res) => {
                settbldata(res);
                setIsLoadingInactive(false);
            })
            .catch((err) => {
                settbldata([]);
                setIsLoadingInactive(false);
            });
    }, [inActiveListParams?.cId]);

    return (
        <>
            {!isLoadingInactive ? (
                <div className="list-table-sc">
                    <AutomationHeader
                        headerTxt={"inactive"}
                        route={getRoute(APP_ROUTES.ADD_WEB_CHAT, { cId: inActiveListParams?.cId })}
                        cId={inActiveListParams?.cId}
                    />
                    <div className="tblResponsive">
                        <table className="w-100">
                            <tr>
                                <th>Name</th>
                                <th>Page URL</th>
                                <th>Sent</th>
                                <th>Engaged</th>
                                <th>First set live</th>
                            </tr>
                            {tbldata?.length > 0
                                ? tbldata.map((tbdata, index) => {
                                      var lastMsgDateTime = "-";
                                      if (tbdata.FirstSetLive)
                                          lastMsgDateTime = unixToDate(
                                              new Date(tbdata.FirstSetLive)
                                          );
                                      return (
                                          <tr key={tbdata.Id} className="parent_class"
                                          onClick={()=>history.push(getRoute(APP_ROUTES.UPDATE_WEB_CAHT, {
                                            cId: inActiveListParams?.cId,
                                            status: APP_ROUTES.IN_ACTIVE,
                                            id: tbdata.Id
                                        }))}
                                          >
                                              {/* <td className="childClass">
                                                  <Link
                                                      to={getRoute(APP_ROUTES.UPDATE_WEB_CAHT, {
                                                          cId: inActiveListParams?.cId,
                                                          status: APP_ROUTES.IN_ACTIVE,
                                                          id: tbdata.Id
                                                      })}
                                                  >
                                                      <span className="link">{tbdata.Name}</span>{" "}
                                                  </Link>
                                              </td> */}

                                              <td className="childClass">{tbdata.Name}</td>
                                              <td className="childClass">{tbdata.PageUrl}</td>
                                              <td className="childClass">{tbdata.Sent}</td>
                                              <td className="childClass">{ Math.round(tbdata.Engaged * 100) / 100}%</td>
                                              <td className="childClass">{lastMsgDateTime}</td>
                                          </tr>
                                      );
                                  })
                                : null}
                        </table>
                        {tbldata?.length <= 0 && (
                            <div>
                                <p className="LDA-no-text">There's nothing here</p>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="loading">
                    <Spin />
                </div>
            )}
        </>
    );
}

export default InActiveList;
