import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { Spin } from "antd";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { APP_ROUTES, getRoute } from "../../../helpers/routesHelper";
import DefaultButton from "../../../components/buttons/buttons";
import SubSidebar from "./subSidebar";
import "./subSidebar.scss";
import { companyWidgetsListingAgent } from "../../../store/actions/automationActions";
const Sidebar = ({ statusChanged }) => {
    const history = useHistory();
    const sidebarParams = useParams();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [show, setShow] = useState();

    const [companyListing, setCompanyListing] = useState([]);
    useEffect(() => {
        setLoading(true);
        dispatch(companyWidgetsListingAgent())
            .then((res) => {
                setCompanyListing(res);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusChanged]);

    return (
        <div className={`left-sidebar ${show ? "show" : ""}`}>
            <button className="left-side-hamburg toggle" onClick={() => setShow(!show)}>
                {show ? <AiOutlineClose /> : <GiHamburgerMenu />}
            </button>
            <div className="ls-breadcrumbs automation-inbox-switch">
                <NavLink exact activeClassName="active" to="#" className="active">
                    Automations
                </NavLink>
                <NavLink exact activeClassName="active" to="/dashboard/inbox/active">
                    Inbox
                </NavLink>
            </div>
            <div className="ls-title">
                <h3 className="d-title">Automations</h3>
            </div>
            <DefaultButton
                _class={"d-btn green icon-btn fit-content"}
                title={"New Automation"}
                disabled={Boolean(companyListing?.length <= 0)}
                tooltip={companyListing?.length <= 0 ? "You do not have access to any websites yet" : ""}
                icon={<AiOutlinePlusCircle />}
                onClick={() => {
                    // history.push(getRoute(APP_ROUTES.ADD_WEB_CHAT, { cId: sidebarParams?.cId }));
                    history.push({
                        pathname: getRoute(APP_ROUTES.ADD_WEB_CHAT, {
                            cId: sidebarParams?.cId
                        }),

                        state: {
                            pageUrl: companyListing.find(
                                (item) => item.Id == sidebarParams?.cId
                            )?.WebsiteDomain,
                            websiteName: companyListing.find(
                                (item) => item.Id == sidebarParams?.cId
                            )?.WebsiteName,
                            brandedFooter: companyListing.find(
                                (item) => item.Id == sidebarParams?.cId
                            )?.BrandedFooter,
                        }
                    })
                }}
            />
            <div className="ls-title"></div>
            {!loading ? (
                companyListing?.map((item, index) => {
                    return (
                        <>
                            <span
                                title={item.WebsiteName}
                                className={
                                    sidebarParams?.cId
                                        ? item.Id == sidebarParams?.cId
                                            ? "companyName multiline-truncate wc-color"
                                            : "companyName multiline-truncate"
                                        : index == 0
                                            ? "companyName multiline-truncate wc-color"
                                            : "companyName multiline-truncate"
                                }
                            >
                                {item.WebsiteName}
                            </span>
                            <ul>
                                <SubSidebar
                                    sidebarParams
                                    cId={item.Id}
                                    statusChanged={statusChanged}
                                    disableAutomation={item.IsCompleted == 0}
                                />
                            </ul>
                        </>
                    );
                })
            ) : (
                <div className="loading ">
                    <Spin />
                </div>
            )}
        </div>
    );
};

export default Sidebar;

