import React from "react";
import Header from "./layouts/header/header";
import Sidebar from "./layouts/sidebar";
import InboxSidebar from "./layouts/inbox-sidebar";
import { useSelector } from "react-redux";

const Master = ({ children, isSideBar, pageType = false, isInboxSidebar }) => {
    const isHeaderStatus = useSelector((state)=>state.auth.isHeaderStatus)
    return (
        <>
            {/* <Header /> */}
            <div className="auto-sec"  style={{ height: !isHeaderStatus ? '100vh' : 'calc(100vh - 75px)' }}>
                {isSideBar && <Sidebar pageType={pageType} />}
                {isInboxSidebar && <InboxSidebar />}
                {/* <div className="inner-sec"> */}
                {/* <div className={isSideBar ? "inner-sec" : "inner-sec-w-side"}> */}
                {children}
                {/* </div> */}
            </div>
        </>
    );
};

export default Master;
