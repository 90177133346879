import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DefaultButton from "../../components/buttons/buttons";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { companyWidgetsListingAgent } from "../../store/actions/automationActions";

function AutomationHeader({ updateStep, headerTxt, route, cId }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [companyListing, setCompanyListing] = useState([]);
    useEffect(() => {
        dispatch(companyWidgetsListingAgent())
            .then((res) => {
                setCompanyListing(res);
            })
            .catch((err) => {

            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="sec-title-btn">
                <h4>{headerTxt == "active" ? "Active Automations" : "Inactive Automation"}</h4>
                <DefaultButton
                    _class={"d-btn green icon-btn"}
                    title={"New Automation"}
                    disabled={Boolean(companyListing.length <= 0)}
                    tooltip={companyListing?.length <= 0 ? "You do not have access to any websites yet" : ""}
                    icon={<AiOutlinePlusCircle />}
                    onClick={() => {
                        // history.push(route)
                        history.push({
                            pathname: route,
                            state: {
                                pageUrl: companyListing.find(
                                    (item) => item.Id == cId
                                )?.WebsiteDomain,
                                websiteName: companyListing.find(
                                    (item) => item.Id == cId
                                )?.WebsiteName,
                                brandedFooter: companyListing.find(
                                    (item) => item.Id == cId
                                )?.BrandedFooter,
                            }
                        })
                    }}
                />
                {/* <a className="d-btn" onClick={()=> updateStep(true)} >Add Welcome Message</a> */}
            </div>
        </div>
    );
}

export default AutomationHeader;
