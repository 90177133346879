import React from "react";
import { connect } from "react-redux";

import { getFormattedNumber } from "../../../../../utils/commonUtil";
import { logout, changeAdminView, setWebchatSoundStatus } from "../../../../../store/actions";
import { LogoutIcon, NewSetting, LeftArrow, newSoundIcon } from "../../../../../assets/svg-icon";
import CustomModal from "../../../../modals/settings/Modal";
import AccountSettingModal from "./AccountSettingModal";
import { ApplicationTypes } from "../../../../../constants";
import { isSSOAuthenticated } from "../../../../../utils/ssoUtil";
import { Socket } from "../../../../../utils/socketUtils";
import Switch from 'react-input-switch';

class AccountSettingDropdown extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false
    };
  }

  handleModalState = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  };

  ShowWebChatTab = () => {
    const { user } = this.props;
    return (
      user &&
      user?.Type === 3 &&
      user?.Apps &&
      user?.Company &&
      Boolean(Object.keys(user.Apps.filter((item) => item.Id === 4)).length) &&
      user.Company.WebChatAccess === 1 &&
      user.ShowWebChatTab &&
      user.WelcomeAutomationCompleted
    );
  };

  isSettingsEnabled = () => {
    if (this.ShowWebChatTab()) {
      return true;
    }
    return false;
  };

  isClubChatMember = () => {
    let clubChat =
      this.props.user && this.props.user.Apps && this.props.user.Apps.find((app) => app.Id === ApplicationTypes.CLUB_CHAT);
    return clubChat ? true : false;
  };

  handleBackToAdminClick = () => {
    const { redirectUrl } = this.props;
    this.props.changeAdminView(false);
    window.location.replace(redirectUrl);
  };

  handleLogout = () => {
    this.props.logout();
    Socket.disconnect();
  };

  handleSoundStatus = () => {
    let SoundStatus = this.props.user.Company.SoundStatus ? 0 : 1;
    this.props.setWebchatSoundStatus(SoundStatus)
}
  
  render() {
    const { user } = this.props;
    return (
      <div className="setting-dropdown z-index-10 ">
        <ul>
          <li id="acc-info">
            {this.isClubChatMember() && (
              <p>Your Text # {getFormattedNumber(user?.Company?.PhoneNumber) || ""}</p>
            )}
            <h4>{user?.Company?.Name}</h4>
          </li>
          <li
            className={`${!this.isSettingsEnabled() ? "disabled" : ""}`}
            onClick={() => {
              this.handleModalState();
            }}
          >
            <img src={NewSetting} className="img-fluid" alt="img" />
            <h3>Settings</h3>
            {this.state.isModalOpen ? (
              <CustomModal isOpen={this.state.isModalOpen}>
                <AccountSettingModal closeModal={this.handleModalState} />
              </CustomModal>
            ) : null}
          </li>
          <li id="sound-toggle">
            <img src={newSoundIcon} className="img-fluid" alt="img" />
            <h3>Sounds {user?.Company?.SoundStatus ? "ON" : "OFF"}</h3>
            <div className="soundtoggle">
              <div className="toggleswitching">
                <Switch value={user?.Company?.SoundStatus} onChange={this.handleSoundStatus} />
              </div>
            </div>
          </li>
          <li onClick={() => this.handleLogout()}>
            <img src={LogoutIcon} className="img-fluid" alt="img" />
            <a href="javascript:void(0);">Log Out </a>
          </li>
          {this.props.isAdmin && !isSSOAuthenticated() && (
            <li onClick={() => this.handleBackToAdminClick()}>
              <img src={LeftArrow} className="img-fluid" alt="img" />
              <h3>Back To Admin </h3>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAdmin: state.auth.isAdmin,
    redirectUrl: state.auth.redirectUrl
  };
};

const mapDispatchToProps =(dispatch) => {
  return {
    logout: () => dispatch(logout()),
    changeAdminView: (isAdmin) => dispatch(changeAdminView(isAdmin)),
    setWebchatSoundStatus: (status) => dispatch(setWebchatSoundStatus(status)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingDropdown);
