import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtil";
import Config from "../../Config";
import {
    addChatsToEntity,
    updateChatMessageInEntity,
    updateChatMessageReadStatus
} from "../actions";
import { getIdsFromData } from "../../utils/commonUtil";
import { scrollToBottom } from "../../utils/domUtil";
import { updateChatUserInEntity, updateChatInEntity } from "./entityActions";

export const CHAT_LIST_REQUEST = "CHAT_LIST_REQUEST";
export const CHAT_LIST_SUCCESS = "CHAT_LIST_SUCCESS";
export const CHAT_LIST_FAILED = "CHAT_LIST_FAILED";

export const MESSAGE_LIST_REQUEST = "MESSAGE_LIST_REQUEST";
export const MESSAGE_LIST_SUCCESS = "MESSAGE_LIST_SUCCESS";
export const MESSAGE_LIST_FAILED = "MESSAGE_LIST_FAILED";

export const UPDATE_CHATLIST_DATA = "UPDATE_CHATLIST_DATA";
export const MESSAGE_UNREAD_COUNT = "MESSAGE_UNREAD_COUNT";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const CLEAR_CHAT_INITIAL_FETCH = "CLEAR_CHAT_INITIAL_FETCH";
export const CLEAR_CHAT_CLOSED_INITIAL_FETCH = "CLEAR_CHAT_CLOSED_INITIAL_FETCH";
export const CLEAR_CURRENT_CHAT = "CLEAR_CURRENT_CHAT";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const UPDATE_MESSAGE_ID = "UPDATE_MESSAGE_ID";
export const ADD_NEW_CHAT = "ADD_NEW_CHAT";
export const REMOVE_SELECTED_CHAT = "REMOVE_SELECTED_CHAT";
export const ENTITY_PREVIOUS_CHAT = "ENTITY_PREVIOUS_CHAT";
export const SET_UNREAD_WEBCHAT_LIST = "SET_UNREAD_WEBCHAT_LIST"

export const setUnreadWebChatList = (data) => {
    return {
        type: SET_UNREAD_WEBCHAT_LIST,
        payload: data
    };
};

export const getWebchatUnreadCount = () => (dispatch, getState) => {
    const token = getState().auth.token;
    return fetchUtil({
        url: `/User/Webchat/getUnReadChats`,
        token,
        showError:false
    })
        .then((res) => {
            dispatch(setUnreadWebChatList(res.data.Messages))
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getChatListing =
    (params = {}, tabname, abortSignal = null, shouldClear = null) =>
    async (dispatch, getState) => {
        let token = getState().auth.token;

        dispatch({
            type: CHAT_LIST_REQUEST,
            tabname,
            page: params.Page,
            q: params.q,
            websiteId: params.WebsiteId
        });

        return fetchUtil({
            url: appendQueryParams("/chats", {
                Limit: Config.LIMIT,
                ...params
            }),
            token,
            abortSignal
        })
            .then(async (res) => {
                if (res && res.data && res.data.List) {
                    await dispatch(addChatsToEntity(res.data.List));
                    await dispatch({
                        type: CHAT_LIST_SUCCESS,
                        payload: getIdsFromData(res.data.List),
                        tabname
                    });
                    res.data.List.map(async (item) => {
                        const { UnreadCount, Message } = item;
                        if(UnreadCount){
                            await dispatch(
                            updateChatMessageData(item.Id, { ...Message, unReadStatus : true })
                            )
                        }
                    })
                    return Promise.resolve({IDs: getIdsFromData(res.data.List), data: res.data.List });
                }
                return Promise.reject(false);
            })
            .catch((err) => {
                if (err.name === "AbortError") {
                    if (!shouldClear || shouldClear()) {
                        // dispatch(clearChatInitialFetch(tabname));
                    }
                    return;
                }
                dispatch({ type: CHAT_LIST_FAILED, tabname });
                return Promise.reject(err);
            });
    };

export const getMessageListing =
    (id, params = {}, abortSignal = null, canDispatch = true) =>
    (dispatch, getState) => {
        const token = getState().auth.token;
        dispatch({ type: MESSAGE_LIST_REQUEST, page: params.Page });

        return fetchUtil({
            url: appendQueryParams(`/agent/webchats/messages`, {
                Limit: Config.LIMIT,
                Column: "CreatedAt",
                Direction: "DESC",
                ChatId: id,
                ...params
            }),
            token,
            abortSignal
        })
            .then((res) => {
                if (res && res.data) {
                    if (canDispatch) {
                        //unread messages test  12 jun
                        let firstUnreadId = getState()?.entities?.chats[id]?.FirstUnreadMessageId;
                        if(firstUnreadId){
                            if(!params.Page){
                            let ifUnreadInFirstPage = res.data?.ChatMessages.find((item) => item?.Id == firstUnreadId)
                            if(ifUnreadInFirstPage){
                            let unreadMessages = res.data?.ChatMessages.filter((item) => item?.Id >= firstUnreadId)
                            unreadMessages.reverse();
                            let Message = getState()?.entities?.chats[id]?.Message;
                            dispatch(
                                updateChatMessageData(id, { ...Message, unReadMessages: unreadMessages })
                            );
                            }
                            }
                            dispatch(updateChatInEntity(id, { UnreadCount: 0, FirstUnreadMessageId: 0 }));
                        }

                        dispatch({
                            type: MESSAGE_LIST_SUCCESS,
                            payload: res.data.ChatMessages.reverse(),
                            totalMessages: res.data.TotalMessages,
                            chatDetails: res.data.ChatDetails[0],
                            agentDetails: res.data.AgentsDetails[0],
                            pageViews: res.data.PageViews,
                            openedMedia: res.data.MediaOpened
                        });
                    }

                    return Promise.resolve(res.data);
                }
                return Promise.reject(false);
            })
            .catch((err) => {
                if (err.name === "AbortError") {
                    return;
                }
                dispatch({ type: MESSAGE_LIST_FAILED });
                return Promise.reject(err);
            });
    };

export const updateChatListData = (key, value, tabname) => {
    return { type: UPDATE_CHATLIST_DATA, key, value, tabname };
};

export const getChatById = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/chat/get/${id}`,
        token
    })
        .then((res) => {
            if (res && res.data) {
                dispatch(addChatsToEntity(res.data));
                return Promise.resolve(res.data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateChatMessageData = (chatId, msgData) => async (dispatch, getState) => {
    const existingChatsData = getState().entities.chats;

    if (!existingChatsData[chatId]) {
        await dispatch(getChatById(chatId));
        //for new chat first message unread counter
        await dispatch(
            updateChatMessageData(chatId, {...msgData, unReadStatus: true, unReadMessages: [] })
        );
    } else {
        let updatedData = {
            Content: msgData.Content,
            CreatedAt: msgData.CreatedAt,
            unReadStatus: msgData.unReadStatus,
            unReadMessages: msgData?.unReadMessages ?? []
        };
        dispatch(updateChatMessageInEntity(chatId, updatedData));
    }
};

export const updateChatMessageStatus = (chatId, msgData) => async (dispatch, getState) => {
    const existingChatsData = getState().entities.chats;

    if (!existingChatsData[chatId]) {
        await dispatch(getChatById(chatId));
    } else {
        dispatch(updateChatMessageReadStatus(chatId, msgData));
    }
};

export const moveChatToTop = (chatId, tabname) => (dispatch, getState) => {
    const existingList = getState().chat.chatList[tabname].data;

    let newList = [...existingList];
    if (newList.includes(chatId)) {
        if (newList[0] !== chatId) {
            let currentIndex = newList.findIndex((id) => id === chatId);
            newList.splice(currentIndex, 1);
            newList.unshift(chatId);
            dispatch({ type: UPDATE_CHATLIST_DATA, value: newList, tabname, key: "data" });
        }
    } else {
        let updatedList = [...new Set([chatId, ...newList])];
        dispatch({ type: UPDATE_CHATLIST_DATA, value: updatedList, tabname, key: "data" });
    }
};

export const addNewChat = (data) => (dispatch, getState) => {
    const existingList = getState().chat?.chatList?.active?.data;
    if (data) {
        dispatch(addChatsToEntity([data]));
        let updatedList = [...new Set([data.Id, ...existingList])];
        dispatch({ type: ADD_NEW_CHAT, payload: updatedList });
    }
};

export const addMessage = (data) => (dispatch, getState) => {
    dispatch({
        type: ADD_NEW_MESSAGE,
        payload: data
    });
    setTimeout(() => {
        scrollToBottom();
    }, 0);
};

export const clearChatInitialFetch = () => {
    return { type: CLEAR_CHAT_INITIAL_FETCH };
};

export const clearChatClosedInitialFetch = () => {
    return { type: CLEAR_CHAT_CLOSED_INITIAL_FETCH };
};

export const clearCurrentChat = () => {
    return {
        type: CLEAR_CURRENT_CHAT
    };
};

export const setCurrentChat = (chatId) => {
    return {
        type: SET_CURRENT_CHAT,
        chatId
    };
};

export const removeSelectedChat = (chatId) => {
    return {
        type: REMOVE_SELECTED_CHAT,
        chatId,
        tabname: "active"
    };
};

export const updateChatMessageId = (messageUUID, messageId) => {
    return { type: UPDATE_MESSAGE_ID, messageUUID, messageId };
};

export const getWebsiteListingByAgent = () => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/webchats_listing_by_agents`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getGiyf = (params) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/gify/search`,
        token,
        method: "POST",
        body: JSON.stringify(params)
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const postChatMedia = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: "/media",
        token,
        method: "POST",
        body,
        image: true
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateGuestUser = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: "/guestuser/update",
        token,
        method: "PUT",
        body: JSON.stringify(body)
    })
        .then((res) => {
            dispatch(updateChatUserInEntity(body.chatId, body));
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getShortenUrl = (body) => (dispatch, getState) => {
    const token = getState().auth.token;
    return fetchUtil({
        url: "/url",
        token,
        method: "POST",
        body: JSON.stringify(body)
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const getPreviousChat = (ids) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: appendQueryParams(`/User/Webchat/MessagesFromId/`, {
            ...ids
        }),
        token
    })
        .then(async (res) => {
            if (res && res.data && res.data.Messages) {
                await dispatch({
                    type: ENTITY_PREVIOUS_CHAT,
                    payload: res.data.Messages,
                    id: ids.ChatId
                });
                return Promise.resolve(res.data.Messages);
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const lastSeenMessage = (chatId) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/agent/lastseen/${chatId}`,
        method: "PUT",
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

