import React from "react";

import MainContent from "../dashboard-screen/Main-content/Main-content";
import "../dashboard-screen/DashboardScreen.scss";

const Webchat = (i) => {
    return <MainContent />;
};

export default Webchat;
