import React, { useEffect, useState } from "react";

import "./WelcomeMessageScreen.scss";

import { useHistory, Redirect, useParams } from "react-router-dom";
import Whenthemessageshouldpop from "../../../assets/images/Whenthemessageshouldpop-up.svg";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import { APP_ROUTES, getRoute } from "../../../helpers/routesHelper";
import { companyWidget } from "../../../store/actions/automationActions";
import { connect } from "react-redux";
const WelcomeMessageScreen = ({ companyListing }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [completion, setCompletion] = useState(false);
    const welcomeMessageParams = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [welcomeMessageParams?.cId, welcomeMessageParams?.status]);
    useEffect(() => {
        setIsLoading(true);
        dispatch(companyWidget(welcomeMessageParams?.cId))
            .then((res) => {
                if (res.IsCompleted != 0) {
                    setCompletion(true);
                }

                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, []);

    if (completion) {
        return (
            <Redirect
                to={getRoute(APP_ROUTES.IN_ACTIVE_AUTOMATION, { cId: welcomeMessageParams?.cId })}
            />
        );
    }

    return !isLoading ? (
        <section className="welcome-screen">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-10 col-md-11 offset-lg-2 offset-md-1">
                        <div className="ws-box">
                            <img src={Whenthemessageshouldpop} alt="Aleart Icon" />
                            <p className="websiteTitle">
                                <strong>Website:</strong>

                                {companyListing.length > 0 &&
                                    companyListing.find(
                                        (item) => item.Id == welcomeMessageParams?.cId
                                    )?.WebsiteName}
                            </p>
                            <h1>Create Your Welcome Message</h1>
                            <p>
                                The first automation you create in Webchat is your Welcome Message,
                                it is like a “master message” that will always be on the page if you
                                don’t have a specific automation set up.
                            </p>
                            <p>
                                Choose a generic, warm message and make sure you add attachments
                                that are relevant to the general public.
                            </p>
                            <p>
                                Future automations can be set up to show up on specific pages and
                                will override the welcome message.
                            </p>
                            <button
                                className="d-btn"
                                onClick={() =>
                                    history.push({
                                        pathname: getRoute(APP_ROUTES.ADD_WEB_CHAT, {
                                            cId: welcomeMessageParams?.cId
                                        }),

                                        state: { inCompleted: true,
                                            pageUrl: companyListing.find(
                                                (item) => item.Id == welcomeMessageParams?.cId
                                            )?.WebsiteDomain,
                                            websiteName: companyListing.find(
                                                (item) => item.Id == welcomeMessageParams?.cId
                                            )?.WebsiteName,
                                            brandedFooter: companyListing.find(
                                                (item) => item.Id == welcomeMessageParams?.cId
                                            )?.BrandedFooter,
                                         }
                                    })
                                }
                            >
                                Add Welcome Message
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <div className="loading">
            <Spin />
        </div>
    );
};
const mapStateToProps = (state) => {
    return { companyListing: state.automation.companyListing };
};

export default connect(mapStateToProps)(WelcomeMessageScreen);
