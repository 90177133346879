import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import "moment-timezone";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

import "./inbox.scss";
import { Socket } from "../../utils/socketUtils";
import ResolveChatModal from "../../components/automation-modal/ResolveChatModal";
import { removeSelectedChat, updateChatInEntity } from "../../store/actions";

const ChatListItem = (props) => {
    const { currentChat, user } = props;
    const dispatch = useDispatch();
    const params = useParams();
    const currentTab = params?.tabname;
    const [chatResolveModal, setChatResolveModal] = useState(false);
    const unixToDate = (date) => {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        interval = seconds / 3600;
        if (interval > 1) {
            if (interval <= 12) {
                return Math.floor(interval) + " h";
            }

            if (Math.floor(interval) <= 24) {
                return " today";
            }

            if (Math.floor(interval) < 48) {
                return " Yesterday";
            }
            return moment(date).format("MM/DD/YYYY");
        }
        interval = seconds / 60;
        interval = interval > 0 ? interval : interval * -1;
        if (interval > 1) {
            if(Math.floor(interval) > 3){
            return Math.floor(interval) + " m";
            }
            return "just now"
        }
        // return Math.floor(seconds) < 0
        //     ? Math.floor(seconds) * -1 + " s"
        //     : Math.floor(seconds) + " s";
        return "just now"
    };

    const handleJoinChat = () => {
        //no need to join chat on click event
        // Socket.joinChat({
        //     RoomKey: currentChat?.ChatRoomKey,
        //     AgentId: user?.Id
        // });
    };

    const truncateString = (str, num) => {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    };

    const handleChatResolveModal = (evt) => {
        if(currentChat?.Status == "active"){
        let event = evt
        event.preventDefault()
        event.stopPropagation();
        setChatResolveModal(true);
        }
    };

    const handleChatResolve = () => {
        Socket.emitChatClosed({
            ChatRoomKey: currentChat.ChatRoomKey,
            UserType: 2,
            // UserId: currentChat.AgentId
            UserId: user?.Id
        });
        dispatch(removeSelectedChat(currentChat.Id));
        dispatch(updateChatInEntity(currentChat.Id, { Status: "closed" }));
    };

    return (
        <>
            {chatResolveModal ? (
                <ResolveChatModal
                    show={() => setChatResolveModal(true)}
                    hide={() => setChatResolveModal(false)}
                    classchat={"as-complete-modal"}
                    title={"Resolve Chat"}
                    text={"This will close the chat. Are you sure, you want to do this?"}
                    btntext1={"Confirm Resolve Chat"}
                    onClickPA={() => {
                        handleChatResolve();
                        setChatResolveModal(false);
                    }}
                />
            ) : null}
            <Link
                onClick={handleJoinChat}
                to={`/dashboard/inbox/${currentTab}/${currentChat?.Id}`}
                className={currentChat?.Id == params.id ? "active-chat" : ""}
            >
                <span>
                    <div className="inbar-msg-card" room_key={currentChat?.ChatRoomKey}>
                        <div className="inmsg-card-left">
                            {currentChat?.User.Name ? (
                                <h5 className="inmsg-title">{currentChat?.User.Name}</h5>
                            ) : (
                                <h5 className="inmsg-title">Visitor</h5>
                            )}
                            <p className="muli-regular">
                                {currentChat?.Message?.Content
                                    // ? truncateString(currentChat?.Message.Content, 15)
                                    ? currentChat?.Message?.Content
                                    : null}
                            </p>
                        </div>
                        <div className="inmsg-card-right">
                            <div className="display-inline" onClick={handleChatResolveModal}>
                                {currentChat?.Message?.unReadStatus && (
                                    <span className="unread-tag" />
                                )}
                                <time
                                    className={`${currentChat?.Status == "active" && "close-chat"} ${
                                        currentChat?.Message?.unReadStatus
                                            ? "unread-time"
                                            : "read-time"
                                    }`}
                                >
                                    <span>
                                        {unixToDate(
                                            new Date(
                                                currentChat?.Message?.CreatedAt ||
                                                    currentChat?.CreatedAt
                                            )
                                        )}
                                    </span>
                                </time>
                            </div>
                            <span className="inmsg-badge">{currentChat.WebsiteName}</span>
                        </div>
                    </div>
                </span>
            </Link>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const currentChat = state.entities.chats[ownProps.chatId];
    return {
        currentChat,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(ChatListItem);

