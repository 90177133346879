import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
// import { Redirect } from "react-router-dom";

import { CloseEyeLogo, OpenEyeLogo, LogoSvg } from "../../../assets/svg-icon/index";
// import { APP_ROUTES } from "../../../helpers/routesHelper";

import "../Form.css";

import WithBackground from "../WithBackground";
import { login } from "../../../store/actions";
import { Spinner } from "../../../components/Loader";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const dispatch = useDispatch();
    const { isAuthenticated } = props;

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if (email.trim().length === 0 || password.trim().length === 0) {
            return;
        }
        setIsLoading(true);
        dispatch(login(email, password)).catch((err) => {
            setIsLoading(false);
        });
    };

    // if (isAuthenticated) {
    //     return <Redirect to={APP_ROUTES.DASHBOARD} />;
    // }

    return (
        <form className="login-form" onSubmit={handleLoginSubmit}>
            <div className="login-logo">
                <img src={LogoSvg} className="img-fluid" alt="img" />
            </div>
            <div className="login-form">
                <div className="form-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        className="form-control"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <div className="p-relative">
                        <input
                            type={!isHidden ? "password" : "text"}
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                        />
                        <span className="eye" onClick={() => setIsHidden(!isHidden)}>
                            {!isHidden ? (
                                <img src={CloseEyeLogo} className="img-fluid" alt="img" />
                            ) : (
                                <img src={OpenEyeLogo} className="img-fluid" alt="img" />
                            )}
                        </span>
                    </div>
                </div>
                <div className="form-group">
                    <button
                        type="submit"
                        className={`${
                            email.trim().length !== 0 && password.trim().length !== 0 ? `valid` : ``
                        }`}
                        disabled={isLoading}
                    >
                        <span>login</span>
                        {isLoading ? <Spinner color="white" size="lg" /> : null}
                    </button>
                    <a href="/account/forget">Forgot Password</a>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

export default WithBackground(connect(mapStateToProps, null)(Login));
