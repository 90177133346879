import React from "react";
import { connect } from "react-redux";

import "./profile.scss";

import ChatProfile from "../../../../../assets/images/profile.PNG";
import ChatProfile1 from "../../../../../assets/images/Onboardlogo-white.svg";
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown";
import AccountSettingDropdown from "./AccountSettingDropdown";
import { Dropdown } from "../../../../dropdown";
import { NewDropDownIcon } from "../../../../../assets/svg-icon";
import Config from "../../../../../Config";

class Profile extends React.Component {
  constructor() {
    super();
    this.settingDropDownRef = React.createRef();
    this.innerReference = React.createRef();
  }

  changeSettingDropDownStatus = () => {
    if (
      this.settingDropDownRef &&
      this.settingDropDownRef.current &&
      this.settingDropDownRef.current.isDropDownInstance()
    ) {
      this.settingDropDownRef.current.openDropDown();
    }
  };

  render() {
    const { user } = this.props;
    return (
      <ul className="chat-right-menu">
        {/* <li><span>BraeBurn CC</span>  <a><img src={userprofile} /></a></li> */}
        <li
          style={{ display: "flex", alignItems: "center" }}
          onClick={this.changeSettingDropDownStatus}
        >
          <div style={{ display: "grid" }}>
            <span style={{ textAlign: "center" }}>{user?.Company?.Name}</span>{" "}
            <span>({user?.Name})</span>{" "}
          </div>
          <a>
            {user.Company?.Logo?.ThumbPath ? (
              <img
                src={`${Config.env().BASE_IMG_PATH}/${user.Company?.Logo?.ThumbPath}`}
                className="company-logo"
                alt="img"
              />
            ) : (
              <img src={ChatProfile1} className="company-logo" alt="default-img" />
            )}
          </a>
          <img src={NewDropDownIcon} className="img-fluid" ref={this.innerReference} alt="img" />
          {/* <FaAngleDown onClick={this.changeSettingDropDownStatus} ref={this.innerReference} /> */}
          <Dropdown ref={this.settingDropDownRef} innerReference={this.innerReference}>
            <AccountSettingDropdown />
          </Dropdown>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(Profile);

