import io from "socket.io-client";
import Config from "../Config";
import { store } from "../store";

export const SocketTypes = {
    //CHAT_START: localStorage.getItem("Room_Key"),
    // CHAT_JOIN: "chat_join",
    NEW_MESSAGE: "New_Message",
    // READ_MESSAGE: "read_message",
    NEW_CHAT_INITIATE: "__new__",
    CHAT_CLOSED: "chat_closed",
    CLOSE_CHAT: "close_chat",
    TYPING: "typing",
    USER_Typing: "user_typing",
    AGENT_JOIN_CHAT: "agent_join_chat",
    WEB_CHAT_MESSAGE_READ:"WebChatMessageRead",
    REMOVE_CHAT: "remove_chat",
    USER_MESSAGE_RECEIVED: "new_message_received",
    USER_INFO_UPDATED : "user_info_updated",
    USER_STATUS : "visitor_status",
    WEBCHAT_UNREAD_ROOMS:"webchat_unread_rooms"
};

export class Socket {
    static socket = null;
    static getSocket = () => {
        if (Socket.socket === null) {
            // const userId = 41;
            // const userId = store.getState().auth.user.Id || 6;
            // const userId = store.getState().auth.user.Id || 41;
            const userId = store.getState().auth.user.Id;
            const actorType = 2;
            // const authToken = store.getState().auth.token || "9e4bba72-976d-4dc3-96a2-87dc8f9461ce" "a9b9ea84-05bd-405c-b755-4e9d3daada4a";
            const authToken = store.getState().auth.token;
            Socket.socket = io(
                `${
                    Config.env().API_URL
                }/?actorType=${actorType}&actorId=${userId}&authorization=${authToken}`,
                {
                    transports: ["websocket"],
                    upgrade: false,
                    reconnection: true,
                    reconnectionAttempts: Infinity,
                    reconnectionDelay: 1000,
                    reconnectionDelayMax: 5000
                }
            );
        }
        return Socket.socket;
    };

    static init = () => {
        Socket.getSocket().on("connect", () => {
            console.log("Socket Connected");
            // logger("socket connected!");
            // store.dispatch(changeSocketConnectivityStatus(true));
        });

        Socket.getSocket().on("connect-error", (err) => {
            console.log("socket connection error", err);
        });

        Socket.getSocket().on("error", (error) => {
            console.log("socket error", error);
            // if (error.type === 401) {
            //     toaster({ title: i18n("SessionExpired"), type: "danger" });
            //     localStorage.clear();
            //     store.dispatch({ type: AUTH_FAIL });
            //     Socket.socket = null;
            // }
        });

        Socket.getSocket().on("disconnect", (reason) => {
            console.log("socket disconnected", reason);
            // store.dispatch(changeSocketConnectivityStatus(false));
        });
    };

    static listenReadUnreadWebchat = (cb) => {
        Socket.getSocket().on(SocketTypes.WEBCHAT_UNREAD_ROOMS, (data) => {
            cb(data);
        });
    };


    static onUserStatus = (cb) => {
        Socket.getSocket().on(SocketTypes.USER_STATUS, cb);
    }

    static onUserInfoUpdated = (cb) => {
        Socket.getSocket().on(SocketTypes.USER_INFO_UPDATED, cb);
    }

    static onMessageRecieved = (cb) => {
        Socket.getSocket().on(SocketTypes.USER_MESSAGE_RECEIVED, cb);
    };

    static onChatInitiate = (cb) => {
        // Socket.getSocket().on(SocketTypes.NEW_CHAT_INITIATE, cb);
    };

    static onChatStarted = (cb) => {
        //Socket.getSocket().on(SocketTypes.CHAT_START, cb);
    };

    static emitChatClosed = (data, cb) => {
        Socket.getSocket().emit(SocketTypes.CLOSE_CHAT, data, cb);
    };

    static emitReadUnreadChat = (data, cb) => {
        Socket.getSocket().emit(SocketTypes.WEB_CHAT_MESSAGE_READ, data, cb)
    }

    static onChatClosed = (cb) => {
        Socket.getSocket().on(SocketTypes.CHAT_CLOSED, cb);
    };

    static emitTyping = (data) => {
        Socket.getSocket().emit(SocketTypes.TYPING, data);
    };

    static onUserTyping = (cb) => {
        Socket.getSocket().on(SocketTypes.USER_Typing, cb);
    };

    static onChatRemove = (cb) => {
        Socket.getSocket().on(SocketTypes.REMOVE_CHAT, cb);
    };

    static joinChat = (data, cb) => {
        Socket.getSocket().emit(SocketTypes.AGENT_JOIN_CHAT, data, cb);
    };

    static sendMessage = (data, cb) => {
        Socket.getSocket().emit(SocketTypes.NEW_MESSAGE, data, cb);
    };

    static remove = (name, listener = null) => {
        if (Socket.socket) {
            if (listener) {
                Socket.getSocket().removeListener(name, listener);
            } else {
                Socket.getSocket().removeAllListeners(name);
            }
        }
    };

    static disconnect = () => {
        Socket.getSocket().disconnect();
        Socket.socket = null;
    };
}
