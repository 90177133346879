import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./modal.scss";

const AttachModal = ({ show, hide, classchat, title, text, btntext1, btnattach, onClickAtMdl }) => {
    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);

    return (
        <section className="as-complete-sec">
            <Modal
                show={show}
                onHide={hide}
                className={classchat}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton> </Modal.Header>
                <Modal.Body>
                    <h3>{title}</h3>
                    <p>{text}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleShow}>
                        {btntext1}
                    </Button>
                    <Button className="att-btn" variant="primary" onClick={onClickAtMdl}>
                        {btnattach}
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};
export default AttachModal;
