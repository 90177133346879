import { ApplicationTypes } from "../../constants";
import { fetchUtil } from "../../utils/fetchUtil";
import { errorToaster } from "../../utils/loggerUtils";
import { appendQueryParams } from "../../utils/urlUtil";

export const ME_SUCCESS = "ME_SUCCESS";
export const ME_ERROR = "ME_ERROR";
export const ME_REQUEST = "ME_REQUEST";
export const SIGN_OUT = "SIGN_OUT";
export const CHANGE_ADMIN_VIEW = "CHANGE_ADMIN_VIEW";
export const SET_SOUND_STATUS = "SET_SOUND_STATUS";
export const SET_HEADER_STATUS = "SET_HEADER_STATUS";
export const SET_WEBCHAT_SOUND_STATUS = "SET_WEBCHAT_SOUND_STATUS"

export const login = (email, password) => (dispatch, getState) => {
  let body = JSON.stringify({
    Email: email,
    Password: password,
    ApplicationId: ApplicationTypes.WEB_CHAT
  });

  return fetchUtil({
    url: "/login",
    method: "POST",
    body
  })
    .then((res) => {
      if (res.Token) {
        localStorage.WebChatToken = res.Token;
        dispatch(getMe(res.Token));
        return Promise.resolve(true);
      }
    })
    .catch((err) => {
      errorToaster(err.Message);
      return Promise.reject(err);
    });
};

export const changeAdminView = (isAdmin, redirectUrl) => {
  return { type: CHANGE_ADMIN_VIEW, isAdmin, redirectUrl };
};

export const getMe = (token) => (dispatch, getState) => {
  dispatch({ type: ME_REQUEST });
  return fetchUtil({
    url: appendQueryParams("/me", { ApplicationId: ApplicationTypes.WEB_CHAT }),
    token: token || getState().auth.token,
    showError: false,
  })
    .then((res) => {
      let authToken = token || getState().auth.token;
      dispatch({ type: ME_SUCCESS, payload: res, authToken });
      localStorage.isAuthenticated = true;
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: ME_ERROR });
      return Promise.reject(err);
    });
};

export const setSoundStatus = (soundStatus) => (dispatch, getState) => {
  dispatch({ type: SET_SOUND_STATUS, soundStatus });
};

export const setSoundStatusRequest = (status) => (dispatch, getState) => {
  dispatch({
    type: SET_WEBCHAT_SOUND_STATUS,
    payload: status
  })
};

export const setHeaderStatus = (headerStatus) => (dispatch, getState) => {
  dispatch({ type: SET_HEADER_STATUS, headerStatus });
}

export const resetPassword = (email) => (dispatch, getState) => {
  let body = JSON.stringify({
    Email: email
  });

  return fetchUtil({
    url: "/reset/password",
    method: "POST",
    body
  })
    .then((res) => {
      return Promise.resolve(true);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const changePassword = (password, code) => (dispatch, getState) => {
  let body = JSON.stringify({
    Password: password,
    Code: code
  });

  return fetchUtil({
    url: "/change/password",
    method: "POST",
    body
  })
    .then((res) => {
      return Promise.resolve(true);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const logout = () => {
  return {
    type: SIGN_OUT
  };
};
