import React from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import Masonry from "react-masonry-css";
import { errorToaster } from "../../utils/loggerUtils";
import { maximumMediaSize } from "../../config/constants/MaximumMediaSize";
import api from "../../Api/api";
import { getGiyf } from "../../store/actions";
import { store } from "../../store";
class Giyf extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFocus: false,
            textAreaRows: 1,
            giyfData: [],
            showGiyf: false,
            offset: 0,
            gifSearchValue: "",
            suggestions: [],
            loadingGiyf: false
        };
    }

    componentDidUpdate = () => {
        if (this.props.showGiyf !== this.state.showGiyf) {
            this.getGiff();
            this.setState({
                showGiyf: this.props.showGiyf
            });
        }
    };

    getGiff = () => {
        this.giyfBackendService(0).then((res) => {
            this.setState({
                giyfData: res.data,
                loadingGiyf: false,
                offset: res.offset
            });
        });
    };

    // getGiyf = (query, limit, offset) => {
    //     return new Promise((resolve, reject) => {
    //         api.post(
    //             "/gify/search",
    //             { q: query, limit: limit, offset: offset },
    //             { headers: { Authorization: "9e4bba72-976d-4dc3-96a2-87dc8f9461ce" } }
    //         )

    //             .then((res) => {
    //                 resolve(res);
    //             })
    //             .catch((err) => {
    //                 errorToaster(err.Message);
    //                 reject({ err });
    //             });
    //     });
    // };

    getSuggestionGiyf = (query) => {
        return new Promise((resolve, reject) => {
            api.post("/gify/search/tag", {
                headers: { Authorization: store.getState().auth.token }
            })

                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    // errorToaster(err.Message);
                    reject({ err });
                });
        });
    };

    giyfBackendService = (offset) => {
        let query = this.state.gifSearchValue === "" ? "golfing" : this.state.gifSearchValue;
        return new Promise((resolve, reject) => {
            // this.getGiyf(query, 50, offset).then((res) => {
            //     resolve(res);
            // });
            this.props.getGiyf({ q: query, limit: 50, offset: offset }).then((res) => {
                return resolve(res);
            });
        });
    };

    giyfValidator = (giyf) => {
        if (Number(giyf.original.size) / 1000000 < maximumMediaSize) {
            return {
                valid: true,
                name: giyf.name,
                url: giyf.original.url,
                type: "giyf"
            };
        } else if (Number(giyf.downsized_large.size) / 1000000 < maximumMediaSize) {
            return {
                valid: true,
                name: giyf.name,
                url: giyf.downsized_large.url,
                type: "giyf"
            };
        } else if (Number(giyf.downsized_medium.size) / 1000000 < maximumMediaSize) {
            return {
                valid: true,
                name: giyf.name,
                url: giyf.downsized_medium.url,
                type: "giyf"
            };
        } else if (Number(giyf.fixed_width.size) / 1000000 < maximumMediaSize) {
            return {
                valid: true,
                name: giyf.name,
                url: giyf.fixed_width.url,
                type: "giyf"
            };
        } else if (Number(giyf.fixed_height.size) / 1000000 < maximumMediaSize) {
            return {
                valid: true,
                name: giyf.name,
                url: giyf.fixed_height.url,
                type: "giyf"
            };
        } else if (Number(giyf.fixed_width_small.size) / 1000000 < maximumMediaSize) {
            return {
                valid: true,
                name: giyf.name,
                url: giyf.fixed_width_small.url,
                type: "giyf"
            };
        } else if (Number(giyf.fixed_width_downsampled.size) / 1000000 < maximumMediaSize) {
            return {
                valid: true,
                name: giyf.name,
                url: giyf.fixed_width_downsampled.url,
                type: "giyf"
            };
        } else if (Number(giyf.preview_gif.size) / 1000000 < maximumMediaSize) {
            return {
                valid: true,
                name: giyf.name,
                url: giyf.preview_gif.url,
                type: "giyf"
            };
        } else {
            return {
                valid: false,
                name: giyf.name,
                url: "",
                type: "giyf"
            };
        }
    };
    selectGiff(selectedGiyf) {
        const { updateMediaObj, updateMediaBase64Url } = this.props;
        const validateGiyf = this.giyfValidator(selectedGiyf);
        if (!validateGiyf.valid) {
            errorToaster("Please select any other gif");
            return;
        }
        const Obj = validateGiyf;
        updateMediaObj(Obj);
        if (typeof updateMediaBase64Url === "function") {
            updateMediaBase64Url(validateGiyf.url);
        }

        this.props.inputFileReference.value = null;
        this.props.openGiffDialog();
    }
    onScroll = (e) => {
        e.stopPropagation();
        if (this.giffBody.scrollHeight - this.giffBody.scrollTop <= this.giffBody.clientHeight) {
            if (!this.state.loadingGiyf) {
                this.setState({
                    loadingGiyf: true
                });
                this.giyfBackendService(this.state.offset)
                    .then((res) => {
                        let giyfData = this.state.giyfData.concat(res.data);
                        this.setState({
                            giyfData: giyfData,
                            offset: res.offset
                        });
                        setTimeout(() => {
                            this.setState({
                                loadingGiyf: false
                            });
                        }, 7000);
                    })
                    .catch((e) => {
                        this.setState({
                            loadingGiyf: false
                        });
                    });
            }
        }
    };
    stopPropagation = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };
    onTextChange = (event, { newValue }) => {
        this.setState(
            {
                gifSearchValue: newValue
            },
            () => {
                setTimeout(() => {
                    if (this.state.gifSearchValue === newValue) {
                        this.getGiff();
                    }
                }, 1000);
            }
        );
    };
    renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            giyfData: [] //api response
        });
        setTimeout(() => {
            if (this.state.gifSearchValue === value) {
                this.getSuggestionGiyf(value).then((res) => {
                    this.setState({
                        suggestions: res.data //api response
                    });
                });
            }
        }, 1000);
    };
    getSuggestionValue = (suggestion) => {
        return suggestion.name;
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const inputProps = {
            placeholder: "Search GIPHY",
            value: this.state.gifSearchValue,
            onChange: this.onTextChange,
            className: "gif-model-input"
        };
        const showGiyfModal = this.props.showGiyf;
        const myClass = this.props.class;
        return (
            <div className={myClass} onClick={(e) => this.stopPropagation(e)}>
                <div
                    className="gifbox"
                    // ref={(ref) => (this.giffBody = ref)}
                    style={{
                        display: showGiyfModal ? "block" : "none"
                    }}
                >
                    <div className="gifsearchbox">
                        <Autosuggest
                            suggestions={this.state.suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={inputProps}
                        />
                    </div>

                    <div
                        className="gifemojis"
                        onScroll={this.onScroll}
                        ref={(ref) => (this.giffBody = ref)}
                    >
                        <Masonry
                            breakpointCols={3}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {this.state.giyfData?.map((each, index) => (
                                <img
                                    className="gifImage cursor-pointer"
                                    alt="gifff"
                                    key={`${each.id}`}
                                    height={each.fixed_width_still.height}
                                    src={each.fixed_width.url} // use normal <img> attributes as props
                                    width={each.fixed_width_still.width}
                                    onClick={() => this.selectGiff(each)}
                                />
                            ))}
                        </Masonry>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGiyf: (params) => dispatch(getGiyf(params))
        // getSuggestionGiyf: (query) => dispatch(getSuggestionGiyf(query)),
        // createShortenUrl: (url) => dispatch(createShortenUrl(url)),
        // getMe: () => dispatch(getMe())
    };
};
export default connect(null, mapDispatchToProps)(Giyf);
