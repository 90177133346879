import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import "./Account.css";

import Login from "./login/Login";
import ForgetLogin from "./login/ForgetLogin";
import ChangePassword from "./login/ChangePassword";
import { APP_ROUTES } from "../../helpers/routesHelper";

const Account = (props) => {
    const { isAuthenticated } = props;

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <div className="account">
            <div className="account__form flex-100 inline-flex-center">
                <Switch>
                    <Route
                        path="/account/login"
                        name="login"
                        render={(props) => <Login {...props} />}
                    />
                    <Route
                        path="/account/forget"
                        name="login"
                        render={(props) => <ForgetLogin {...props} />}
                    />
                    <Route
                        path="/account/change/password"
                        name="login"
                        render={(props) => <ChangePassword {...props} />}
                    />
                    <Redirect to="/account/login" />
                </Switch>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

export default connect(mapStateToProps, null)(Account);
