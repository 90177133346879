import React from "react";
import WelcomeMessageScreen from "../../automation-screens/welcome-message-screen/WelcomeMessageScreen";
import LeftSidebar from "../left-sidebar/Leftsidebar";

import "./Main-content.scss";

const MainContent = () => {
    return (
        <section className="main-content-sec">
            <WelcomeMessageScreen />
        </section>
    );
};

export default MainContent;
