/* eslint-disable import/no-anonymous-default-export */
import { SET_CURRENT, SET_POST_DATA, SET_COMPANY_LISTING, SET_ACTIVE_COMPANY, SET_WIDGET_COMPANY_LISTING } from "../actions/automationActions";

const initialState = { current: 0, postData: {}, companyListing: [], activeCompany: null, widgetCompanyListing: [] };

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT:
            return {
                ...state,
                current: action.value
            };
        case SET_ACTIVE_COMPANY:
            return {
                ...state,
                activeCompany: action.value
            };
        case SET_POST_DATA:
            return {
                ...state,
                postData: action.value
            };
        case SET_COMPANY_LISTING:
            return {
                ...state,
                companyListing: action.value
            };
        case SET_WIDGET_COMPANY_LISTING:
            return {
                ...state,
                widgetCompanyListing: action.value
            }
        default:
            return state;
    }
};
