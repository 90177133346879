import { useState, useEffect } from "react";
import api from "../Api/api";
import { useParams, useHistory } from "react-router-dom";
import { store } from "../store";

export const useChatMessagesHook = () => {
    const [allChatMessages, setChatMessages] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [chatMsgId, setChatMsgId] = useState()
    const [chatMsgPage, setChatMsgPage] = useState()
    const [inboxMessages, setInboxMessages] = useState([])
    const [isDataAvailable, setIsDataAvailable] = useState(true)
    const [selectedValue, setSelectedValue] = useState("");
    const [search, setSearch] = useState("");
    const history = useHistory();
    useEffect(() => {
        setChatMsgPage(1)
    }, [chatMsgId])

    useEffect(() => {
        //fetchNewApi();
    }, [chatMsgId, chatMsgPage]);

    const scrollToBottom = () => {
        let scrollContainer = document.getElementById("chatbox-scroll-div");
        if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;
        }
    }


    const fetchNewApi = async () => {
        if (chatMsgId != undefined && chatMsgPage != undefined) {
            try {
                setIsLoading(true)
                let ele = document.getElementById('chatbox-scroll-div')
                let prevScrollTop = ele?.scrollTop;
                let prevScrollHeight = ele?.scrollHeight;

                const apiChatMessagesData = await api.get(`/agent/webchats/messages/?ChatId=${chatMsgId}&Page=${chatMsgPage}&Limit=10`,
                    { headers: { Authorization: store.getState().auth.token } }
                );
                // const apiChatMessagesData = await api.get(`/agent/webchats/messages/?ChatId=3&Page=${chatMsgPage}&Limit=10`,
                //     { headers: { Authorization: "9e4bba72-976d-4dc3-96a2-87dc8f9461ce" } }
                // );
                if (chatMsgPage === 1) {
                    setTimeout(() => {
                        scrollToBottom();
                    }, 60)
                } else {
                    if (ele) {
                        setTimeout(() => {
                            ele.scrollTop = ele.scrollHeight - (prevScrollHeight - prevScrollTop)
                        }, 0)
                    }
                }
                setIsLoading(false)

                setChatMessages(apiChatMessagesData.data.data);
                setInboxMessages(prev => {
                    if (!chatMsgPage || chatMsgPage === 1) {
                        return apiChatMessagesData.data.data.ChatMessages
                    }
                    return [...prev, ...apiChatMessagesData.data.data.ChatMessages]
                })

                setIsDataAvailable(apiChatMessagesData.data.data.ChatMessages.length == 10)


            } catch (arror) {
                setIsLoading(false)
            }

        };
    }



    return { allChatMessages, setChatMessages, setChatMsgId, isLoading, setIsLoading, chatMsgId, chatMsgPage, setChatMsgPage, inboxMessages, isDataAvailable, setInboxMessages };
};