import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { changeAdminView, getMe } from "../../store/actions";
import { LoadingPage } from "../../components/LodingPage";

class VerifyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      token: props.match.params.token
    };
  }
  componentDidMount() {
    const token = this.props.match.params.token;
    let params = new URLSearchParams(this.props.location.search);
    let isAdmin = params.get("isAdmin");
    let url = params.get("url");
    if (isAdmin) {
      this.props.changeAdminView(Boolean(isAdmin), url);
    }

    localStorage.RCToken = token;
    localStorage.WebChatToken = token
    this.props.getMe(token).catch((err) => {
      this.setState({ isError: true });
    });
  }


  render() {
    if (this.props.isAuthenticated && this.props.userToken === this.props.match.params.token) {
      return <Redirect replce to={`/dashboard?token=${this.state.token}`} />;
    }
    return <LoadingPage loading={this.props.fetching} error={this.state.isError} />;
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
    isAuthenticated: state.auth.isAuthenticated,
    userToken: state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMe: (token) => dispatch(getMe(token)),
    changeAdminView: (isAdmin, redirectUrl) => dispatch(changeAdminView(isAdmin, redirectUrl))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPage);
