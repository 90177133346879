import React from "react";
import ReactTooltip from "react-tooltip";
/* eslint-disable */
const CustomToolTip = ({ children, text }) => {
    return (
        <>
            <a data-for="custom-class" data-tip={text}>
                {children}
            </a>
            <ReactTooltip
                className="custom-tooltip-theme"
                id="custom-class"
                background-color="#f6f7f8"
                effect="solid"
            />
        </>
    );
};
export default CustomToolTip;
