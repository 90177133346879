import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "moment-timezone";
import moment from "moment";
import { Row, Col } from "antd";
import Edit from "../../assets/svg-icon/edit.svg";
import { connect, useDispatch } from "react-redux";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import ArrowSeeMore from "../../assets/images/ArrowSeeMore.svg";
import { getPreviousChat } from "../../store/actions";
import { useChatMessagesHook } from "../../../src/ApiHook/ChatMessagesHook";
import { isEmailValid } from "../../utils/commonUtil";
import PhoneInput from "react-phone-input-2";
import { updateChatUserInEntity, updateGuestUser } from "../../store/actions";

const FIELD_CONSTANT = {
    NAME: "Name",
    PHONE_NUMBER: "PhoneNumber",
    EMAIL: "Email"
};

const Details = (props) => {
    const { setValue, currentUser } = props;
    const { details, pageViews, openedMedia, id } = props.currentChat;
    const { allChatMessages, setChatMsgId } = useChatMessagesHook();
    const [currentAction, setCurrentAction] = useState();
    const history = props.conversationHistory[details.Id];
    const dispatch = useDispatch();
    const [show, setShow] = useState();
    const phoneRef = useRef();
    const emailRef = useRef();
    const nameRef = useRef();
    const [emailValidation, setEmailValidation] = useState(false);
    const [selectedField, setSelectedField] = useState("");
    const [fieldBlur, setFieldBlur] = useState({
        PhoneNumber: "",
        Name: "",
        Email: ""
    });
    const [state, setState] = useState({
        PhoneNumber: "",
        Name: "",
        Email: ""
    });
    const showRef = useRef('');

    const msgParams = useParams();

    useEffect(() => {
        setChatMsgId(msgParams.id);
        setShow(showRef.current);
        setState({
            ...state,
            PhoneNumber: currentUser?.PhoneNumber || "",
            Email: currentUser?.Email || "",
            Name: currentUser?.Name || ""
        });
        setEmailValidation(false);
    }, [msgParams.id, currentUser]);
    const UserCity = details?.user?.City;
    const UserTimeZone = details?.user?.Timezone;
    const UserPageViewsCount = pageViews?.PageUrls?.length;
    const UserPageVisited = pageViews?.PageUrls;
    const UserTimeSpent = pageViews?.TimeSpent;
    const UserConversation = details?.conversations;

    const getDurationFromMilliseconds = (millisec) => {
        var seconds = (millisec / 1000).toFixed(0);
        var minutes = Math.floor(Number(seconds) / 60).toString();
        let hours = 0;
        if (Number(minutes) > 59) {
            hours = Math.floor(Number(minutes) / 60);
            // hours = hours >= 10 ? hours : "0" + hours;
            minutes = (Number(minutes) - hours * 60).toString();
            // minutes = Number(minutes) >= 10 ? minutes : "0" + minutes;
        }

        seconds = Math.floor(Number(seconds) % 60).toString();
        // seconds = Number(seconds) >= 10 ? seconds : "0" + seconds;
        return hours + "h " + minutes + "m " + seconds + "s ";
    };

    const updateProfile = async (name) => {
        setFieldBlur({ ...fieldBlur, [name]: false });
        let payload = {
            ...(state?.Name) && {Name: state.Name},
            ...(state?.Email) && {Email: state.Email},
            ...(state?.PhoneNumber) && {Phone: state.PhoneNumber},
            ...(state?.PhoneNumber) && {PhoneNumber: state.PhoneNumber},
            GuestUserId: details?.user?.Id,
            chatId: id
        };
        try{
            await dispatch(updateGuestUser(payload));
            showRef.current = true;
        }catch(err){
            console.log("err upadting info ", err)
        }finally{
            document.activeElement.blur();
        }
    };

    const truncateString = (str, num) => {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    };

    const unixToDate = (date) => {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        interval = seconds / 3600;
        if (interval > 1) {
            // if (interval <= 12) {
            //     return Math.floor(interval) + " hours";
            // }

            // if (Math.floor(interval) <= 24) {
            //     return " Today " + moment(date).format("h:mm A");
            // }

            // if (Math.floor(interval) < 48) {
            //     return " Yesterday " + moment(date).format("h:mm A");
            // }
            return moment(date).format("MMMM DD, YYYY h:mm A");
            // return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }
        return Math.floor(seconds) + " seconds ago";
    };

    const onConversationClick = (id, chatId) => {
        setValue(true);
        dispatch(
            getPreviousChat({
                MessageId: id,
                ChatId: chatId
            })
        );
    };
    const onChange = (name, value) => {
        setState({ ...state, [name]: value });
    };

    const onChangeEmail = (value) => {
        onChange("Email", value);
        const check = isEmailValid(value);
        setEmailValidation(check);
    };

    const onFieldBlur = (name, action) => {
        action ? setSelectedField(name) : setSelectedField(null);
        action ? setCurrentAction(name) : setCurrentAction(null);
        setFieldBlur({ ...fieldBlur, [name]: action });
        if(!action){
            resetField(name)
        }
    };

    const onEditClick = (name, action) => {
        FIELD_CONSTANT.NAME == name && nameRef.current.focus();
        FIELD_CONSTANT.PHONE_NUMBER == name && phoneRef.current.focus();
        FIELD_CONSTANT.EMAIL == name && emailRef.current.focus();
        setCurrentAction(name);
        onFieldBlur(name, action);
    };

    const resetField = (name) => {
        let data = currentUser?.[name];
        setState({ ...state, [name]: data ?? "" });
        // onFieldBlur(name, false);
        document.activeElement.blur();
        setEmailValidation(false);
    };

    return (
        <div className={`inbox-right-panel ${show ? "show" : ""}`}>
            <div className="inner-right-panel">
                <div className="ih-right toggle" onClick={() => {showRef.current = !show; setShow(!show)}}>
                    <img src={ArrowSeeMore} alt="ArrowSeeMore" />
                </div>
                <div className="inp-box inp-header-box">
                    <div className="field-container">
                        <input
                            onFocus={() => onFieldBlur("Name", true)}
                            onBlur={() => onFieldBlur("Name", false)}
                            ref={nameRef}
                            className="name width-100"
                            value={state?.Name}
                            onChange={(e) => {
                                if(e.target.value.length <=20) {
                                    onChange("Name", e.target.value)
                                }
                            }}
                            style={{ fontSize: "18px" }}
                        />
                        {/* {selectedField != FIELD_CONSTANT.NAME && ( */}
                        <div onClick={() => onEditClick("Name", true)}>
                            <img
                                src={Edit}
                                height="14"
                                width="14"
                                className={`cursor ${
                                    currentAction == FIELD_CONSTANT.NAME
                                        ? "pencil-rotate"
                                        : "pencil-rotate"
                                        // : "cancel-rotate"
                                }`}
                            />
                        </div>
                        {/* )} */}
                    </div>
                    {fieldBlur?.Name && (
                        <div className="flexEnd">
                            <buttons
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => updateProfile("Name")}
                                className="action"
                            >
                                <AiOutlineCheck />
                            </buttons>
                            <buttons
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => resetField("Name")}
                                className="action"
                            >
                                <AiOutlineClose />
                            </buttons>
                        </div>
                    )}
                    {/* {currentUser?.PhoneNumber && ( */}
                        <div className="inp-box-cont">
                            <label className="mt10">Phone #</label>
                            {/* <a href={`tel:${UserPhoneNo}`}>{UserPhoneNo}</a> */}
                            <div className="field-container">
                                <div className={`country-code-prefix ${currentAction == FIELD_CONSTANT.PHONE_NUMBER && "country-code-prefix-selected"}`}>+</div>
                                <PhoneInput
                                    inputProps={{
                                        name: "phone",
                                        required: true,
                                        autoFocus: false,
                                        ref: phoneRef
                                    }}
                                    prefix=""
                                    containerClass="width-100 custom-input"
                                    onBlur={() => onFieldBlur("PhoneNumber", false)}
                                    value={state?.PhoneNumber}
                                    placeholder=""
                                    inputClass="inputField"
                                    onFocus={() => onFieldBlur("PhoneNumber", true)}
                                    onChange={(phone) => onChange("PhoneNumber", phone)}
                                    country={'us'}
                                    onlyCountries= {['us']}
                                    countryCodeEditable={false}
                                />
                                {/* {selectedField != FIELD_CONSTANT.PHONE_NUMBER && ( */}
                                <div onClick={() => onEditClick("PhoneNumber", true)}>
                                    <img
                                        src={Edit}
                                        height="14"
                                        width="14"
                                        className={`cursor margin-left-5 ${
                                            currentAction == FIELD_CONSTANT.PHONE_NUMBER
                                                ? "pencil-rotate"
                                                : "pencil-rotate"
                                                // : "cancel-rotate"
                                        }`}
                                    />
                                </div>
                                {/* )} */}
                            </div>
                            {fieldBlur?.PhoneNumber && (
                                <div className="flexEnd">
                                    <buttons
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() => updateProfile("PhoneNumber")}
                                        className="action"
                                    >
                                        <AiOutlineCheck />
                                    </buttons>
                                    <buttons
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() => resetField("PhoneNumber")}
                                        className="action"
                                    >
                                        <AiOutlineClose />
                                    </buttons>
                                </div>
                            )}
                        </div>
                    {/* )} */}
                    {/* {currentUser?.Email && (
                        
                    )} */}
                    <div className="inp-box-cont">
                            <label>Email</label>
                            {/* <a href={`mailto:${UserEmail}`}>{UserEmail}</a> */}
                            <div className="field-container">
                                <input
                                    onFocus={() => onFieldBlur("Email", true)}
                                    className={`inputField width-100 custom-icon ${
                                        emailValidation ? "inputFieldError" : "inputField"
                                    } `}
                                    ref={emailRef}
                                    value={state?.Email}
                                    onBlur={() => onFieldBlur("Email", false)}
                                    onChange={(e) => onChangeEmail(e.target.value)}
                                />
                                {/* {selectedField != FIELD_CONSTANT.EMAIL && ( */}
                                <div onClick={() => onEditClick("Email", true)}>
                                    <img
                                        src={Edit}
                                        height="14"
                                        width="14"
                                        className={`cursor margin-left-5 ${
                                            currentAction == FIELD_CONSTANT.EMAIL
                                                ? "pencil-rotate"
                                                : "pencil-rotate"
                                                // : "cancel-rotate"
                                        }`}
                                    />
                                </div>
                                {/* )} */}
                            </div>
                            {emailValidation && <p className="error">Email is not valid</p>}
                            {fieldBlur?.Email && (
                                <div className="flexEnd">
                                    <buttons
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() => updateProfile("Email")}
                                        className="action"
                                    >
                                        <AiOutlineCheck />
                                    </buttons>
                                    <buttons
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() => resetField("Email")}
                                        className="action"
                                    >
                                        <AiOutlineClose />
                                    </buttons>
                                </div>
                            )} 
                        </div>
                </div>
                <div className="inp-box">
                    <h3>Attachments Opened</h3>
                    <div className="inp-box-cont">
                        {!openedMedia?.length ? (
                            <p>No Attachments</p>
                        ) : (
                            openedMedia?.map((mediaOpen, index) => {
                                return (
                                    <a key={index} href={`${mediaOpen.Path}`}>
                                        {mediaOpen.Name}
                                    </a>
                                );
                            })
                        )}
                    </div>
                </div>
                <div className="inp-box">
                    <h3>Location</h3>
                    <div className="inp-box-cont">
                        <label>City</label>
                        <p>{UserCity}</p>
                    </div>
                    <div className="inp-box-cont">
                        <label>Time/Zone</label>
                        <p>{UserTimeZone}</p>
                    </div>
                </div>
                <div className="inp-box views-box">
                    <h3>
                        Page Views <span>({UserPageViewsCount})</span>
                    </h3>
                    <div className="inp-box-cont">
                        <label>Time spent on website</label>
                        {/* <p>{UserTimeSpent}</p> */}
                        <p>{getDurationFromMilliseconds(UserTimeSpent)}</p>
                    </div>
                    <div className="inp-box-cont">
                        <label>Pages Visited</label>
                        {UserPageVisited?.map((pageVisited, index) => {
                            return (
                                <p className="pageVisited">
                                    <a target="_blank" key={index} href={`http://${pageVisited}`}>
                                        {/* {pageVisited} */}
                                        {truncateString(pageVisited, 25)}
                                    </a>
                                </p>
                            );
                        })}
                    </div>
                </div>
                <div className="inp-box conversations-box">
                    <h3>Conversations</h3>
                    <div className="inp-box-cont">
                        {history?.WebChatHistories?.length === 0 ? (
                            <p>No Conversations</p>
                        ) : (
                            history?.WebChatHistories?.map((conversationDate, index) => {
                                var conversationTime = unixToDate(
                                    new Date(conversationDate.CreatedAt)
                                );
                                return (
                                    <span
                                        className="cursor"
                                        key={index}
                                        onClick={() =>
                                            onConversationClick(
                                                conversationDate.FirstMessageId,
                                                conversationDate.WebChatId
                                            )
                                        }
                                    >
                                        {conversationTime}
                                    </span>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const currentUser = state.entities.chats[state.chat?.currentChat?.id]?.User;
    return {
        currentChat: state.chat.currentChat,
        conversationHistory: state.entities.chats,
        currentUser: currentUser
    };
};

export default connect(mapStateToProps)(Details);

