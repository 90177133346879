import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./modal.scss";
import { useHistory } from "react-router";
function SetLiveModal({ show, hide, classcomp, icon, text, btntext1, route }) {
    const history = useHistory();

    const redirectTo = () => {
        history.push(route);
    };

    return (
        <section className="as-complete-sec auto-comp-sec">
            <Modal
                backdrop="static"
                show={show}
                onHide={hide}
                className={classcomp}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="checkicon-sec">
                        <span></span>
                        {icon}
                    </div>
                    <p>{text}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            hide();
                            redirectTo();
                        }}
                    >
                        {btntext1}
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}
export default SetLiveModal;
