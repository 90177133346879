import React, { useEffect, useContext, useState, useRef } from "react";
import "./webchat-inbox.scss";
import { Menu, Dropdown, message, Tabs, Select, Spin } from "antd";
import { FiChevronDown } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import { BsCheck } from "react-icons/bs";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { GlobalContext } from "../../../context/global-context";
import Moment from "react-moment";
import "moment-timezone";
import { AllInboxCHatHook } from "../../../ApiHook/AllInboxCHatHook";
import { WebchatListAgentHook } from "../../../ApiHook/WebChatsListingByAgentsHook";
import { Link, useParams } from "react-router-dom";
import { useChatMessagesHook } from "../../../ApiHook/ChatMessagesHook";
import "moment-timezone";
import moment from "moment";
import { Socket, SocketTypes } from "../../../utils/socketUtils";
import { APP_ROUTES, getRoute } from "../../../helpers/routesHelper";
const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
};

const { TabPane } = Tabs;

const { Option } = Select;

const InboxSidebar = () => {
    const glblContext = useContext(GlobalContext);
    const { loginUserData } = glblContext;
    const [show, setShow] = React.useState();

    const {
        allInboxData,
        setAllInboxData,
        setSelectedValue,
        search,
        setSearch,
        isLoading,
        setStatusTab,
        currentPage,
        setCurrentPage,
        isDataAvailable
    } = AllInboxCHatHook();
    const {
        allChatMessages,
        setChatMessages,
        chatMsgId,
        setChatMsgId,
        chatMsgPage,
        setChatMsgPage,
        roomKey,
        setRoomKey
    } = useChatMessagesHook();
    const { webchatListAgentData } = WebchatListAgentHook();

    const [totalPages, setTotalPages] = useState(0);

    const msgParams = useParams();
    // const [message, setMessage] = useState("")

    // const inboxDataRes = allInboxData?.data?.List;

    const handleChange = (value) => {
        setSelectedValue(value);
    };
    function callback(key) {
        setCurrentPage(1);
        setStatusTab(key);
    }

    const webChatListDataRes = webchatListAgentData?.data;

    const handleRoomKey = (roomKey) => {
        //localStorage.setItem("Room_Key", roomKey);
    };

    useEffect(() => {
        Socket.onChatStarted(handleMessageRecevied);
        Socket.onChatInitiate(handleNeWChat);
        // handleChatClosed()
    }, []);

    const handleNeWChat = (data) => {
        setAllInboxData((prev) => {
            return [
                {
                    ChatRoomKey: data.ChatRoomKey,
                    CompanyId: data.CompanyId,
                    CreatedAt: data.CreatedAt,
                    Id: data.Id,
                    Message: {
                        ChatId: data.Message.ChatId,
                        Content: data.Message.Content,
                        CreatedAt: data.Message.CreatedAt,
                        Id: data.Message.Id,
                        MediaUrl: data.Message.MediaUrl,
                        SenderType: data.Message.SenderType,
                        UpdatedAt: data.Message.UpdatedAt
                    },
                    ReadStatus: data.ReadStatus,
                    Status: data.Status,
                    UpdatedAt: data.UpdatedAt,
                    User: {
                        City: data.User.City,
                        CreatedAt: data.User.CreatedAt,
                        DeletedAt: data.User.DeletedAt,
                        Id: data.User.Id,
                        IpAddress: data.User.IpAddress,
                        Timezone: data.User.Timezone,
                        UpdatedAt: data.User.UpdatedAt
                    },
                    UserSocketId: data.UserSocketId,
                    WebsiteId: data.WebsiteId,
                    WebsiteName: data.WebsiteName
                },
                ...prev
            ];
        });
    };

    const handleJoinChat = () => {
        // Socket.joinChat({
        //     RoomKey: localStorage.getItem("Room_Key"),
        //     AgentId: loginUserData.User?.Id
        // });
    };
    // const handleChatClosed = () => {
    //   Socket.onChatClosed({
    //     ChatRoomKey: (localStorage.getItem('Room_Key')),
    //   });
    // }
    // window.addEventListener("beforeunload", (ev) => {
    //   ev.preventDefault();
    //   return ev.returnValue = handleChatClosed();
    // });
    //   const handleNeWChat = (data) => {
    //     // if (msgParams.id == data.ChatId) {
    //     //     let msgRecTime = unixToDate(new Date(data.CreatedAt))
    //     //     setInboxMessages(prev => {
    //     //         return [{
    //     //             "Content": data.Content,
    //     //             SenderType: 1,
    //     //             CreatedAt: data.CreatedAt
    //     //         },...prev]
    //     //     })

    //     // }
    // }
    const handleMessageRecevied = (data) => {
        // if (msgParams.id == data.ChatId) {
        //   let msgRecTime = unixToDate(new Date(data.CreatedAt))
        //   setAllInboxData(prev => {
        //       return [{
        //           Content: data.Content,
        //           CreatedAt: data.CreatedAt
        //       },...prev]
        //   })
        // }
    };

    const msgBox = useRef();

    const activeScroll = () => {
        const scrollTop = msgBox.current.scrollTop;
        const clientHeight = msgBox.current.clientHeight;
        const scrollHeight = msgBox.current.scrollHeight - 10;
        const scroll = scrollTop + clientHeight;

        if (scroll >= scrollHeight) {
            // if(currentPage <= totalPages){
            // getMessages({ page: currentPage + 1 })
            if (isDataAvailable && !isLoading) {
                setCurrentPage((prev) => prev + 1);
            }
            // setCurrentPage(currentPage + 1)
            // }
        }
    };
    const unixToDate = (date) => {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        interval = seconds / 3600;
        if (interval > 1) {
            if (interval <= 12) {
                return Math.floor(interval) + " h";
            }

            if (Math.floor(interval) <= 24) {
                return " today";
            }

            if (Math.floor(interval) < 48) {
                return " Yesterday";
            }
            return moment(date).format("MM/DD/YYYY");
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " m";
        }
        return Math.floor(seconds) + " s";
    };

    return (
        <div className={`inbox-sidebar-wrapper ${show ? "show" : ""}`}>
            <button className="inbox-sidebar-hamburger toggle" onClick={() => setShow(!show)}>
                {show ? <AiOutlineClose /> : <GiHamburgerMenu />}
            </button>
            <div className="inbar-header">
                <a href={APP_ROUTES.WEB_CHAT} className="automations-link">
                    AUTOMATIONS
                </a>
                <Select defaultValue="" onChange={handleChange}>
                    <Option value="">All Inboxes</Option>
                    {webChatListDataRes?.map((data, index) => {
                        return (
                            <Option key={index} value={data.webchat_id}>
                                {data.website_name}
                            </Option>
                        );
                    })}
                </Select>
            </div>
            <div className="inbar-tabs">
                <Tabs defaultActiveKey="active" onChange={callback}>
                    <TabPane tab="Active" key="active">
                        <div className="tabs-bottom-color"></div>
                        <div className="inbar-search">
                            <BiSearch />
                            <input
                                type="text"
                                value={search}
                                onChange={(evt) => setSearch(evt.target.value)}
                                placeholder="search messages"
                            />
                        </div>
                        <div
                            id="chat-sidebar"
                            className="chat-listing"
                            style={{ overflowY: "auto", height: "calc(100vh - 380px)" }}
                            ref={msgBox}
                            onScroll={activeScroll}
                        >
                            {/* <div id="chat-sidebar" className="chat-listing" style={{ overflowY:'auto', height: 'calc(100vh - 380px)' }} > */}
                            {allInboxData.length
                                ? allInboxData?.map((userMsg, index) => {
                                      // [...allInboxData].reverse()?.map((userMsg, index) => {
                                      var lastMsgDateTime = unixToDate(
                                          new Date(userMsg.Message.CreatedAt)
                                      );
                                      return (
                                          <>
                                              <Link
                                                  onClick={!userMsg.AgentId ? handleJoinChat : null}
                                                  id={`${userMsg.Id}`}
                                                  key={index}
                                                  to={getRoute(APP_ROUTES.CHAT, { id: userMsg.Id })}
                                                  className={
                                                      userMsg.Id == msgParams.id
                                                          ? "active-chat"
                                                          : ""
                                                  }
                                              >
                                                  <span
                                                      onClick={() => {
                                                          setChatMsgPage(1);
                                                          handleRoomKey(userMsg.ChatRoomKey);
                                                      }}
                                                  >
                                                      <div
                                                          className="inbar-msg-card"
                                                          room_key={userMsg.ChatRoomKey}
                                                      >
                                                          <div className="inmsg-card-left">
                                                              {userMsg.User.Name ? (
                                                                  <h5 className="inmsg-title">
                                                                      {userMsg.User.Name}
                                                                  </h5>
                                                              ) : (
                                                                  <h5 className="inmsg-title">
                                                                      Visitor
                                                                  </h5>
                                                              )}
                                                              <p>{userMsg.Message.Content}</p>
                                                          </div>
                                                          <div className="inmsg-card-right">
                                                              <span className="inmsg-badge">
                                                                  {userMsg.WebsiteName}
                                                              </span>
                                                              {/* <time>{userMsg.Message.CreatedAt}</time> */}
                                                              <time>{lastMsgDateTime}</time>
                                                          </div>
                                                      </div>
                                                  </span>
                                              </Link>
                                          </>
                                      );
                                  })
                                : null}
                            {/* {Boolean(isLoading && !allInboxData.length) && <p><Spin /></p>} */}
                            {Boolean(isLoading && allInboxData.length) && (
                                <p>
                                    <Spin />
                                </p>
                            )}
                            {isLoading && !allInboxData.length && (
                                <p>
                                    <Spin />
                                </p>
                            )}
                            {!isLoading && !allInboxData.length && (
                                <div className="no-data">
                                    <p>No Chat Found</p>
                                </div>
                            )}
                        </div>
                    </TabPane>
                    <TabPane tab="Closed" key="closed">
                        <div className="tabs-bottom-color"></div>
                        <div
                            id="chat-sidebar"
                            className="chat-listing"
                            style={{ overflowY: "auto", height: "calc(100vh - 334px)" }}
                            ref={msgBox}
                            onScroll={activeScroll}
                        >
                            {/* <div id="chat-sidebar" className="chat-listing" style={{ overflowY:'auto', height: 'calc(100vh - 380px)' }} > */}
                            {
                                // Boolean(isLoading && allInboxData.length) && <p><Spin /></p>,
                                allInboxData.length
                                    ? allInboxData?.map((userMsg, index) => {
                                          // [...allInboxData].reverse()?.map((userMsg, index) => {
                                          var lastMsgDateTime = unixToDate(
                                              new Date(userMsg.Message.CreatedAt)
                                          );
                                          return (
                                              <>
                                                  <Link
                                                      onClick={
                                                          !userMsg.AgentId ? handleJoinChat : null
                                                      }
                                                      id={`${userMsg.Id}`}
                                                      key={index}
                                                      to={getRoute(APP_ROUTES.CHAT, {
                                                          id: userMsg.Id
                                                      })}
                                                      className={
                                                          userMsg.Id == msgParams.id
                                                              ? "active-chat"
                                                              : ""
                                                      }
                                                  >
                                                      <span
                                                          onClick={() => {
                                                              setChatMsgPage(1);
                                                              handleRoomKey(userMsg.ChatRoomKey);
                                                          }}
                                                      >
                                                          <div
                                                              className="inbar-msg-card"
                                                              room_key={userMsg.ChatRoomKey}
                                                          >
                                                              <div className="inmsg-card-left">
                                                                  {userMsg.User.Name ? (
                                                                      <h5 className="inmsg-title">
                                                                          {userMsg.User.Name}
                                                                      </h5>
                                                                  ) : (
                                                                      <h5 className="inmsg-title">
                                                                          Visitor
                                                                      </h5>
                                                                  )}
                                                                  <p>{userMsg.Message.Content}</p>
                                                              </div>
                                                              <div className="inmsg-card-right">
                                                                  <span className="inmsg-badge">
                                                                      {userMsg.WebsiteName}
                                                                  </span>
                                                                  {/* <time>{userMsg.Message.CreatedAt}</time> */}
                                                                  <time>{lastMsgDateTime}</time>
                                                              </div>
                                                          </div>
                                                      </span>
                                                  </Link>
                                              </>
                                          );
                                      })
                                    : null
                            }
                            {/* {Boolean(isLoading && !allInboxData.length) && <p><Spin /></p>} */}
                            {Boolean(isLoading && allInboxData.length) && (
                                <p>
                                    <Spin />
                                </p>
                            )}
                            {isLoading && !allInboxData.length && (
                                <p>
                                    <Spin />
                                </p>
                            )}
                            {!isLoading && !allInboxData.length && (
                                <div className="no-data">
                                    <p>No Chat Found</p>
                                </div>
                            )}
                        </div>

                        {/* {

              isLoading ? (
                <p><Spin /></p>)
                :
                !isLoading && allInboxData ? (
                  allInboxData?.map((userMsg, index) => {
                    var lastMsgDateTime = unixToDate(new Date(userMsg.Message.CreatedAt))
                    return (
                      <Link key={index}  to={getRoute(APP_ROUTES.CHAT, { id: userMsg.Id })}}>
                        <span
                          onClick={() => {

                            setChatMsgPage(1)
                            handleRoomKey(userMsg.ChatRoomKey)

                          }}
                        >
                          <div className="inbar-msg-card" room_key = {userMsg.ChatRoomKey}>
                            <div className="inmsg-card-left">
                              {
                              userMsg.User.Name ? 
                              <h5 className="inmsg-title">{userMsg.User.Name}</h5>
                              :
                              <h5 className="inmsg-title">Visitor</h5>
                              }
                              <p>{userMsg.Message.Content}</p>
                            </div>
                            <div className="inmsg-card-right">
                              <span className="inmsg-badge">
                                {userMsg.WebsiteName}
                              </span>
                              <time>{lastMsgDateTime}</time>
                            </div>
                          </div>
                        </span>
                      </Link>
                    )
                  }
                  )
                ) : (
                  <div className="no-data">
                    <p>No Chat Found</p>

                  </div>
                )} */}
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default InboxSidebar;
