import React, { useState, useEffect } from "react";
import "../dashboard-screen/DashboardScreen.scss";
import { Switch, Route, useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ActiveList from "../../views/automation-screens/active-screen/list";
import InActiveList from "../../views/automation-screens/inactive-screen/list";
import { APP_ROUTES, getRoute } from "../../helpers/routesHelper";
import Sidebar from "../../components/common/layouts/sidebar";
import AutomaionChatForm from "../../views/automation-screens/automaion-chat-form/AutomaionChatForm";
import Webchat from "../../views/web-chat/Webchat";
import { companyWidgetsListingAgent } from "../../store/actions/automationActions";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import { addMessage, updateChatMessageData, moveChatToTop, addNewChat, updateChatUserInEntity } from "../../store/actions";
import { playAudioOnNewMessage } from "../../utils/newMessageSoundUtil";
import { store } from "../../store";
const Automation = () => {
    const [statusChanged, setStatusChanged] = useState({ id: null, status: false });
    const isHeaderStatus = useSelector((state) => state.auth.isHeaderStatus)
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const reg = new RegExp("^[0-9]+$");
    useEffect(
        () => {
        async function companyListChecking() {
                try {
                    const res = await dispatch(companyWidgetsListingAgent());
                    if (res[0]?.Id) {
                        if (
                            !res.map((item) => parseInt(item.IsCompleted)).includes(parseInt(0)) ||
                            location?.state?.selectFirst
                        ) {
                            history.push(
                                getRoute(APP_ROUTES.ACTIVE_AUTOMATION, { cId: res[0].Id })
                            );
                        }
                    }
                } catch { }
            }

            if (!reg.test(params?.cId)) {
                companyListChecking();
            }
            //for also listening messages in automation section
            async function handleMessageRecieved(data) {
                let shouldSoundPlay = store.getState().auth?.user?.Company?.SoundStatus;
                if (shouldSoundPlay) {
                    try {
                        playAudioOnNewMessage();
                    } catch (err) {
                        console.log("err playing audio", err)
                    }
                }
            }

            function handleChatStarted(data) {
                playAudioOnNewMessage();
            }

            function handleUserInfoUpdated(data) {
                let chatId = data.ChatId
                let User = store.getState().entities.chats[chatId]?.User;
                if (User) {
                    store.dispatch(updateChatUserInEntity(chatId, { Name: data.User.Name }));
                }
            }

            Socket.onMessageRecieved(handleMessageRecieved);
            // Socket.onChatInitiate(handleChatStarted);
            Socket.onUserInfoUpdated(handleUserInfoUpdated);
            return () => {
                // Socket.remove(SocketTypes.NEW_CHAT_INITIATE, handleChatStarted);
                Socket.remove(SocketTypes.USER_MESSAGE_RECEIVED, handleMessageRecieved);
                Socket.remove(SocketTypes.USER_INFO_UPDATED, handleUserInfoUpdated);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params?.cId]
    );

    return (
        <>
            <div className="auto-sec" style={{ height: !isHeaderStatus ? '100vh' : 'calc(100vh - 75px)' }}>
                <Sidebar statusChanged={statusChanged} />

                <Switch>
                    <Route exact={true} path={APP_ROUTES.WEB_CHAT}>
                        {(props) => <Webchat {...props} />}
                    </Route>
                    <Route exact={true} path={APP_ROUTES.ADD_WEB_CHAT}>
                        {(props) => (
                            <AutomaionChatForm
                                {...props}
                                setStatusChanged={setStatusChanged}
                                statusChanged={statusChanged}
                            />
                        )}
                    </Route>
                    <Route exact={true} path={APP_ROUTES.ACTIVE_AUTOMATION}>
                        {(props) => <ActiveList {...props} />}
                    </Route>
                    <Route exact={true} path={APP_ROUTES.IN_ACTIVE_AUTOMATION}>
                        {(props) => <InActiveList {...props} />}
                    </Route>
                    <Route exact={true} path={APP_ROUTES.UPDATE_WEB_CAHT}>
                        {(props) => (
                            <AutomaionChatForm
                                {...props}
                                setStatusChanged={setStatusChanged}
                                statusChanged={statusChanged}
                            />
                        )}
                    </Route>
                </Switch>
            </div>
        </>
    );
};

export default Automation;
