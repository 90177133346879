import React, { useState } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import "antd/dist/antd.css";

import GlobalContext from "./context/global-context";
// import Home from "./views/Home";
// import SetupWebChat from "./views/setup-webchat/setup-webchat";
// import Webchat from "./views/web-chat/Webchat";
// import AutomaionChatForm from "./views/automation-screens/automaion-chat-form/AutomaionChatForm";
// import ActiveList from "./views/automation-screens/active-screen/list";
// import InactiveList from "./views/automation-screens/inactive-screen/list";
// import inbox from "./views/inbox/inbox";
// import ApiForm from "./ApiForm";
import { APP_ROUTES } from "./helpers/routesHelper";
import DefaultLayout from "./views/default-layout/DefaultLayout";
import Account from "./views/account/Account";
import Signin from "./views/signin/Signin";
import VerifyPage from "./views/VerifyPage/VerifyPage";

const toastProps = {
  autoClose: 3000,
  enableMultiContainer: true,
  closeButton: false,
  hideProgressBar: true,
  style: { marginTop: "1.125em", minWidth: "36.625em", right: "0em" },
  className: "custom-toast-container"
};

function App(props) {

  console.log = () => {}

  const [isBooted, setIsBooted] = useState(false);

  const runScript = () => {
    if (window.Intercom && !isBooted && props.isHeaderStatus) {
      window.Intercom('boot', {
        app_id: "ri83jky5",
        email: props.user.Email,
        name: props.user.Name,
        user_id: props.user.Id,
        alignment: 'right',
        user_hash: props.user.intercomHash
      });
      setIsBooted(true)
    }
  }


  const unbootScript = () => {
    if (window.Intercom && isBooted) {
      window.Intercom('shutdown', {});
      setIsBooted(false)
    }
  }


  return (
    <GlobalContext>
      {props.isAuthenticated && props.isHeaderStatus ?
        runScript() : unbootScript()
      }
      <ToastContainer
        containerId="success"
        toastClassName="custom-toast-message success-message"
        {...toastProps}
      />
      <ToastContainer
        containerId="error"
        toastClassName={"custom-toast-message error-message"}
        {...toastProps}
      />
      <Switch>
        {/* <Route exact path="/" component={SetupWebChat} />
                    <Route exact path="/webchat" component={Webchat} />
                    <Route exact path="/webchat/add" component={AutomaionChatForm} />
                    <Route exact path="/webchat/:id" component={AutomaionChatForm} />
                    <Route exact path="/active" component={ActiveList} />
                    <Route exact path="/inactive" component={InactiveList} />
                    <Route exact path="/inbox" component={inbox} />
                    <Route exact path="/apiform" component={ApiForm} />
                    <Route exact path="/inbox/:id" component={inbox} /> */}
        <Route path={APP_ROUTES.ACCOUNT} component={Account} />
        <Route path="/verify/:token" component={VerifyPage} />
        <Route path="/SSO/:apiKey" component={Signin} />
        <Route path={APP_ROUTES.DASHBOARD} name="DefaultLayout" component={DefaultLayout} />
        <Redirect to={APP_ROUTES.DASHBOARD} />
      </Switch>
    </GlobalContext>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    isHeaderStatus: state.auth.isHeaderStatus
  };
};

export default connect(mapStateToProps)(App);
