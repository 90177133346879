import _ from "lodash";
import { fetchUtil } from "../../utils/fetchUtil";
import { normalizeChatData } from "../../utils/normalizeUtils";
import { appendQueryParams } from "../../utils/urlUtil";

export const ENTITY_ADD_CHAT = "ENTITY_ADD_CHAT";
export const ENTITY_UPDATE_CHAT = "ENTITY_UPDATE_CHAT";
export const ENTITY_UPDATE_CHAT_MESSAGE = "ENTITY_UPDATE_CHAT_MESSAGE";
export const ENTITY_UPDATE_CHAT_MESSAGE_READ_STATUS = "ENTITY_UPDATE_CHAT_MESSAGE_READ_STATUS";
export const ENTITY_UPDATE_CHAT_USER = "ENTITY_UPDATE_CHAT_USER";
export const ENTITY_PREVIOUS_CHAT = "ENTITY_PREVIOUS_CHAT";

const removeExistingAndSameData = (existingData, newData, shouldRemoveData = false) => {
    Object.keys(newData).forEach((id) => {
        const shouldRemove = typeof shouldRemoveData === "function" ? shouldRemoveData(id) : false;
        if (existingData[id] && (_.isEqual(existingData[id], newData[id]) || shouldRemove)) {
            delete newData[id];
        }
    });
};

export const addChatsToEntity = (data) => (dispatch, getState) => {
    const { chatData } = normalizeChatData(data);
    const existingData = getState().entities.chats;
    removeExistingAndSameData(existingData, chatData);
    dispatch({ type: ENTITY_ADD_CHAT, payload: chatData });
};

export const updateChatMessageInEntity = (id, data) => (dispatch, getState) => {
    const existingData = getState().entities.chats;

    if (existingData[id]) {
        dispatch({ type: ENTITY_UPDATE_CHAT_MESSAGE, id, data });
    }
};

export const updateChatMessageReadStatus = (id, data) => (dispatch, getState) => {
    const existingData = getState().entities.chats;
    console.log("fetching new data", data);
    if (existingData[id]) {
        dispatch({ type: ENTITY_UPDATE_CHAT_MESSAGE_READ_STATUS, id, data });
    }
};

export const updateChatInEntity = (id, data) => (dispatch, getState) => {
    const existingData = getState().entities.chats;

    if (existingData[id]) {
        dispatch({ type: ENTITY_UPDATE_CHAT, id, data });
    }
};

export const updateChatUserInEntity = (id, data) => (dispatch, getState) => {
    const existingData = getState().entities.chats;
    if (existingData[id]) {
        dispatch({ type: ENTITY_UPDATE_CHAT_USER, id, data });
    }
};

