import { injectBaseConstantMethods } from "./BaseConstant";

const HalalPreference = {
    STRICT: 1,
    MODERATE: 2,
    LIBERAL: 3
};

const displayTextKeys = {
    [HalalPreference.STRICT]: "Strict",
    [HalalPreference.MODERATE]: "Moderate",
    [HalalPreference.LIBERAL]: "Liberal"
};

const labelClass = {
    [HalalPreference.STRICT]: "",
    [HalalPreference.MODERATE]: "",
    [HalalPreference.LIBERAL]: ""
};

export default injectBaseConstantMethods(HalalPreference, displayTextKeys, labelClass);
