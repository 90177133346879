import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Button, message, Spin } from "antd";
import { connect } from "react-redux";

import { setCurrent, setPostData, companyWidget } from "../../store/actions/automationActions";
import { createWebChat } from "../../store/actions/index";

const WebSetupSchema1 = Yup.object().shape({
    WebsiteDomain: Yup.string().url("https://www.example.com/").required("Required"),
    WebsiteName: Yup.string().required("Required"),
    CompanyName: Yup.string().required("Required")
});
const WebSetupSchema2 = Yup.object().shape({
    WebsiteDomain: Yup.string().required("Required"),
    WebsiteName: Yup.string().required("Required"),
    CompanyName: Yup.string().required("Required")
});

const WebSetup = ({ current, setCurrent, setPostData }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setLoading(true);
            dispatch(companyWidget(id))
                .then((res) => {
                    setCurrentAutomation(res);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    }, [id]);

    const [currentAutomation, setCurrentAutomation] = useState({});

    return (
        <>
            {!loading ? (
                <Formik
                    initialValues={{
                        WebsiteDomain: currentAutomation?.WebsiteDomain || "",
                        WebsiteName: currentAutomation?.WebsiteName || "",
                        CompanyName: currentAutomation?.Company?.Name || ""
                    }}
                    validationSchema={id ? WebSetupSchema2 : WebSetupSchema1}
                    onSubmit={async (values) => {
                        if (!id) {
                            dispatch(createWebChat({ ...values }))
                                .then((res) => {
                                    setPostData(res);
                                    if (res?.ApiKey) {
                                        setCurrent(1);
                                    }
                                })
                                .catch((err) => {
                                    message.error(err?.Message);
                                });
                        } else {
                            setPostData(currentAutomation);
                            setCurrent(1);
                        }
                    }}
                >
                    {(formikProps) => {
                        const { handleChange, handleSubmit, errors, touched, values } = formikProps;

                        return (
                            <Form onSubmit={handleSubmit}>
                                <div className="form-field">
                                    <label htmlFor="compname">Company Name</label>
                                    <input
                                        id="comp_name"
                                        disabled={id}
                                        name="compname"
                                        placeholder="BraeBurn Country Club"
                                        type="text"
                                        value={values.CompanyName}
                                        onChange={handleChange("CompanyName")}
                                    />
                                </div>

                                <div className="form-field">
                                    <label htmlFor="WebsiteDomain">Website Domain</label>
                                    <input
                                        className={
                                            errors.WebsiteDomain && touched.WebsiteDomain
                                                ? "invalid"
                                                : ""
                                        }
                                        id="web_domain"
                                        name="WebsiteDomain"
                                        placeholder="braeburncc.com"
                                        type="url"
                                        disabled={id}
                                        value={values.WebsiteDomain}
                                        onChange={handleChange("WebsiteDomain")}
                                    />
                                    {errors.WebsiteDomain && touched.WebsiteDomain ? (
                                        <div className="required-error">{errors.WebsiteDomain}</div>
                                    ) : null}
                                </div>

                                <div className="form-field">
                                    <label htmlFor="WebsiteName">Website Name</label>
                                    <input
                                        className={
                                            errors.WebsiteName && touched.WebsiteName
                                                ? "invalid"
                                                : ""
                                        }
                                        id="web_name"
                                        disabled={id}
                                        name="WebsiteName"
                                        placeholder="BraeBurn Public"
                                        type="text"
                                        value={values.WebsiteName}
                                        onChange={handleChange("WebsiteName")}
                                    />
                                    {errors.WebsiteName && touched.WebsiteName ? (
                                        <div className="required-error">{errors.WebsiteName}</div>
                                    ) : null}
                                </div>

                                <div className="steps-action">
                                    <Button
                                        className="LDA-fontFamily"
                                        type="primary"
                                        onClick={() => handleSubmit(values)}
                                    >
                                        Continue to Widget Installation
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                <div className="loading">
                    <Spin />
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    const current = state.automation.current;

    return { current };
};
const mapDispatchToProps = {
    setCurrent,
    setPostData
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSetup);
