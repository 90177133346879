import { useState, useEffect } from "react";
import api from "../Api/api";
import { store } from "../store";

export const WebchatListAgentHook = () => {
    const [webchatListAgentData, setWebchatListAgentData] = useState({});
    const [webchatListCache, setwebchatListCache] = useState(false);
    const fetchNewApi = async () => {
        try {
            const getwebchatListAgentData = await api.get("/webchats_listing_by_agents/", {
                headers: { Authorization: store.getState().auth.token }
            });

            setWebchatListAgentData(getwebchatListAgentData.data);
        } catch (arror) {}
    };

    useEffect(() => {
        fetchNewApi();
    }, []);

    return { webchatListAgentData };
};
