import React, { useEffect, useRef, useState } from "react";
import { Tabs, Select, Spin } from "antd";
import { BiSearch } from "react-icons/bi";
import "moment-timezone";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import DropDown from "../../assets/svg-icon/dropdown.svg";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { useDispatch, connect, useSelector } from "react-redux";
import { DebounceInput } from "react-debounce-input";

import "./inbox.scss";

import { Socket, SocketTypes } from "../../utils/socketUtils";
import { APP_ROUTES, getRoute } from "../../helpers/routesHelper";
import {
    addMessage,
    addNewChat,
    clearChatClosedInitialFetch,
    getChatListing,
    getWebsiteListingByAgent,
    moveChatToTop,
    removeSelectedChat,
    updateChatListData,
    updateChatMessageData,
    updateChatUserInEntity,
    updateChatInEntity,
    lastSeenMessage,
} from "../../store/actions";
import ChatListItem from "./ChatListItem";
import { store } from "../../store";
import { playAudioOnNewMessage } from "../../utils/newMessageSoundUtil";

const { TabPane } = Tabs;
const { Option } = Select;

const ChatUserList = (props) => {
    const [show, setShow] = useState(false);
    const [agentWebsites, setAgentWebsites] = useState([]);
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { activeCompany, companyListing } = useSelector((state) => state.automation)
    const activeCompanyId = activeCompany || companyListing[0]?.Id
    const history = useHistory();
    const currentTab = params?.tabname;
    const id = params?.id;
    const scrollDiv = useRef(null);
    const chatListAbortController = useRef(null);
    const { chatList, user, currentUserMessages, closedChatList, entities } = props;
    const selectedChatRef = useRef(null);

    const checkScrollPositionAndLoadMoreData = () => {
        let container = scrollDiv.current;
        if (container) {
            const isScrolable = container.scrollHeight > container.clientHeight;
            if (!isScrolable) {
                handleScroll(null, true);
            }
        }
    };

    useEffect(() => {
        dispatch(getWebsiteListingByAgent())
            .then((res) => {
                setAgentWebsites(res);
            })
            .catch((err) => { });
    }, []);

    useEffect(() => {
        async function handleMessageRecieved(data) {
            const { User, ...rest } = data.data;
            const selectedChatId = parseInt(id);
            if (data && data.ChatId && data.ChatId === selectedChatId && rest) {
                dispatch(addMessage(rest));
                if (document.visibilityState === "hidden") {
                    let shouldSoundPlay = user?.Company?.SoundStatus;
                    if (shouldSoundPlay) {
                        try {
                            playAudioOnNewMessage();
                        } catch (err) {
                            console.log("err playing audio", err)
                        }
                    }
                }
                //call for last seen message for resetting unread messages
                try {
                    dispatch(lastSeenMessage(selectedChatId))
                } catch (err) {
                    console.log("err last seen", err)
                }
            } else {
                let shouldSoundPlay = user?.Company?.SoundStatus;
                if (shouldSoundPlay) {
                    try {
                        playAudioOnNewMessage();
                    } catch (err) {
                        console.log("err playing audio", err)
                    }
                }
            }
            let unReadStatus = false;
            let unReadMessages = [];
            if (id != data?.ChatId) {
                unReadStatus = true;
                let prevMsg = "";
                prevMsg = currentUserMessages[data?.ChatId]?.Message?.unReadMessages;
                if (prevMsg != undefined) {
                    unReadMessages = prevMsg;
                }
                unReadMessages.push(rest);
            }
            await dispatch(
                updateChatMessageData(data.ChatId, { ...rest, unReadStatus, unReadMessages })
            );
            dispatch(moveChatToTop(data.ChatId, "active"));
            // if (!selectedChatId || selectedChatId !== data.ChatId) {
            //     dispatch(updateChatUnreadCount(data.ChatId));
            // }

            // //for chat closed to active when visitor messages again
            if (data && closedChatList?.data.find((chatId) => chatId == data.ChatId)) {
                let closedList = closedChatList.data.filter((chatId) => chatId != data.ChatId);
                dispatch(updateChatListData("data", closedList, "closed"));
                dispatch(updateChatInEntity(data.ChatId, { Status: "active" }));
            } else {
                if (data && data.ChatId) {
                    let chatFound = false;
                    chatFound = entities?.chats[data.ChatId];
                    if (chatFound) {
                        dispatch(updateChatInEntity(data.ChatId, { Status: "active" }));
                    }
                }
            }
        }

        function handleChatStarted(data) {
            let { Message } = data;
            Message = { ...Message, unReadStatus: true, unReadMessages: [] };
            data = { ...data, Message };
            dispatch(addNewChat(data));
            playAudioOnNewMessage();
        }

        function handleChatRemove(data) {
            dispatch(removeSelectedChat(data.ChatId));
        }

        function handleChatClosed(data) {
            //console.log("data", data);
            // dispatch(removeSelectedChat(data?.ChatDetails?.Id));
            // // alert("Chat has been closed by user");
            // history.replace(`/dashboard/inbox/closed`);
            console.log("closed by another agent")
            dispatch(removeSelectedChat(data?.ChatDetails?.Id));
            dispatch(updateChatInEntity(data?.ChatDetails?.Id, { Status: "closed" }));
        }

        function handleUserInfoUpdated(data) {
            let chatId = data.ChatId;
            let User = store.getState().entities.chats[chatId]?.User;
            if (User) {
                store.dispatch(
                    updateChatUserInEntity(chatId, {
                        Name: data.User?.Name,
                        Email: data.User?.Email,
                        PhoneNumber: data.User?.PhoneNumber
                    })
                );
            }
        }

        Socket.onMessageRecieved(handleMessageRecieved);
        Socket.onChatRemove(handleChatRemove);
        // Socket.onChatInitiate(handleChatStarted);
        Socket.onChatClosed(handleChatClosed);
        Socket.onUserInfoUpdated(handleUserInfoUpdated);

        return () => {
            // Socket.remove(SocketTypes.NEW_CHAT_INITIATE, handleChatStarted);
            Socket.remove(SocketTypes.USER_MESSAGE_RECEIVED, handleMessageRecieved);
            Socket.remove(SocketTypes.REMOVE_CHAT, handleChatRemove);
            Socket.remove(SocketTypes.USER_INFO_UPDATED, handleUserInfoUpdated);
        };
    }, [id, dispatch, currentUserMessages, user]);

    useEffect(() => {
        chatListAbortController.current = new AbortController();
        if (!chatList.initialFetch && !chatList.fetching) {
            dispatch(
                getChatListing(
                    {
                        Page: chatList.page,
                        Status: currentTab,
                        ...(chatList.searchText && { q: chatList.searchText }),
                        ...(chatList.selectedWebsite && { WebsiteId: chatList.selectedWebsite })
                    },
                    currentTab,
                    chatListAbortController.current.signal
                )
            )
                .then((res) => {
                    if (!!res?.IDs?.length) {
                        let firstChatId = res.IDs[0];
                        if (!id) {
                            history.replace(`/dashboard/inbox/${currentTab}/${firstChatId}`);
                            //no need to join first chat now
                            // Socket.joinChat({
                            //     RoomKey: res.data[0].ChatRoomKey,
                            //     AgentId: user?.Id
                            // });
                        }
                        checkScrollPositionAndLoadMoreData();
                    }
                })
                .catch((err) => { });
        } else {
            const { data } = chatList;
            if (!!data.length && !id) {
                let dataId = selectedChatRef.current || data[0];
                history.replace(`/dashboard/inbox/${currentTab}/${dataId}`);
            }
        }
        return () => {
            if (chatListAbortController.current) {
                chatListAbortController.current.abort();
                chatListAbortController.current = null;
            }
        };
    }, [currentTab, dispatch, location.pathname]);

    const getUserChats = (page, searchText, websiteId = null) => {
        dispatch(
            getChatListing(
                {
                    Page: page,
                    Status: currentTab,
                    ...(searchText && { q: searchText }),
                    ...(websiteId && { WebsiteId: websiteId })
                },
                currentTab
            )
        );
    };

    const handleSearchChange = (evt) => {
        let page = 1;
        getUserChats(page, evt.target.value, chatList.selectedWebsite);
        dispatch(updateChatListData("page", 1, currentTab));
        dispatch(updateChatListData("searchText", evt.target.value, currentTab));
    };

    const handleWebsiteSelect = (websiteId) => {
        let page = 1;
        getUserChats(page, chatList.searchText, websiteId);
        dispatch(updateChatListData("page", 1, currentTab));
        dispatch(updateChatListData("selectedWebsite", websiteId, currentTab));
    };

    function handleTabPress(key) {
        if (key === "active") {
            history.push("/dashboard/inbox/active");
        } else {
            selectedChatRef.current = id;
            dispatch(clearChatClosedInitialFetch());
            history.push("/dashboard/inbox/closed");
        }
    }

    const handleScroll = (e, noScroll = false) => {
        const currentTabData = store.getState().chat.chatList[currentTab];
        const bottom =
            noScroll ||
            (e && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50);

        if (bottom && currentTabData.dataAvailable && !currentTabData.fetching) {
            let page = currentTabData.page + 1;
            getUserChats(page, currentTabData.searchText, currentTabData.selectedWebsite);
            dispatch(updateChatListData("page", page, currentTab));
        }
    };

    return (
        <div className={`inbox-sidebar-wrapper ${show ? "show" : ""}`}>
            <button className="inbox-sidebar-hamburger toggle" onClick={() => setShow(!show)}>
                {show ? <AiOutlineClose /> : <GiHamburgerMenu />}
            </button>
            <div className="inbar-header">
                <div className="automation-inbox-switch-wrapper">
                    {/* <Link
                        to={{ pathname: APP_ROUTES.WEB_CHAT, state: { selectFirst: true } }}
                        className="automations-link"
                    >
                        AUTOMATIONS
                    </Link> */}
                    <div className="ls-breadcrumbs automation-inbox-switch mb-0">
                        {/* Modified by Shehzad */}
                        {/* <Link exact activeClassName="active" to={{ pathname: APP_ROUTES.WEB_CHAT, state: { selectFirst: true } }}>
                            Automations
                        </Link> */}
                        <Link exact activeClassName="active" to={getRoute(APP_ROUTES.WEB_CHAT, {
                            cId: activeCompanyId
                        })}>
                            Automations
                        </Link>
                        <Link exact activeClassName="active" to="#" className="active">
                            Inbox
                        </Link>
                    </div>

                </div>
                <div className="filter-inbox-wrapper">
                    <Select
                        value={chatList.selectedWebsite || ""}
                        onChange={handleWebsiteSelect}
                        suffixIcon={<img src={DropDown} className="custom-icon-styling" />}
                    >
                        <Option value="">All Inboxes</Option>
                        {agentWebsites?.map((data, index) => {
                            return (
                                <Option key={index} value={data.webchat_id}>
                                    {data.website_name}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
            </div>
            <div className="inbar-tabs">
                <Tabs
                    activeKey={currentTab}
                    defaultActiveKey={currentTab || "active"}
                    onChange={handleTabPress}
                >
                    <TabPane className="montserrat-medium" tab="Active" key="active">
                        <div className="tabs-bottom-color"></div>
                    </TabPane>
                    <TabPane className="montserrat-medium" tab="Closed" key="closed">
                        <div className="tabs-bottom-color"></div>
                    </TabPane>
                </Tabs>
                <div className="chat-container">
                    <div className="inbar-search">
                        <BiSearch />
                        <DebounceInput
                            debounceTimeout={500}
                            placeholder="Search"
                            value={chatList.searchText}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div
                        ref={scrollDiv}
                        id="chat-sidebar"
                        className="chat-listing"
                        style={{ overflowY: "auto", height: "calc(100vh - 270px)" }}
                        onScroll={handleScroll}
                    >
                        {!!chatList.data.length &&
                            chatList.data.map((chatId, index) => (
                                <div style={{ paddingRight: "10px" }}>
                                    <ChatListItem key={index} chatId={chatId} />
                                </div>
                            ))}
                        {!chatList.data.length && !chatList.fetching && (
                            <div className="no-data">
                                <p>No Chat Found</p>
                            </div>
                        )}
                        {chatList.fetching && (
                            <p>
                                <Spin />
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    let tabname = ownProps.match.params.tabname;
    return {
        currentUserMessages: state.entities.chats,
        chatList: state.chat.chatList[tabname],
        closedChatList: state.chat.chatList["closed"],
        entities: state.entities,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(ChatUserList);

