import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { LoadingPage } from "../../components/LodingPage";
import { getSSOAuthToken, clearSSOSession } from "../../store/actions";
import { isSSOAuthenticated } from "../../utils/ssoUtil";

class Signin extends Component {
    constructor() {
        super();
        this.state = {};
    }

    static getDerivedStateFromProps(props) {
        if (props.apiKey !== props.match.params.apiKey && props.userAuthenticated) {
            props.clearSSOSession();
        }
        return {};
    }

    componentDidMount() {
        const apiKey = this.props.match.params.apiKey;

        if (!isSSOAuthenticated()) {
            this.props
                .getSSOAuthToken(apiKey)
                .then((res) => {
                    sessionStorage.apiKey = apiKey;
                })
                .catch((err) => { });
        }
    }

    render() {
        if (isSSOAuthenticated()) {
            return <Redirect to="/dashboard" />;
        }
        return <LoadingPage loading={this.props.fetching} error={this.props.error} />;
    }
}

const mapStateToProps = (state) => {
    return {
        fetching: state.ssoAuth.fetching,
        error: state.ssoAuth.error,
        userAuthenticated: state.ssoAuth.userAuthenticated,
        apiKey: state.ssoAuth.apiKey
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSSOAuthToken: (apiKey) => dispatch(getSSOAuthToken(apiKey)),
        clearSSOSession: () => dispatch(clearSSOSession())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Signin);
