import React, { useEffect } from "react";
import moment from "moment";
import anchorme from "anchorme";
import _ from "underscore";
import { connect } from "react-redux";

import { convertNameIntoInitials } from "../../utils/commonUtil";
import { scrollToBottom } from "../../utils/domUtil";
import AllowedFileTypes from "../../utils/AllowedFileTypes";

import pdfSvg from "../../assets/file-types/pdf.svg";
import mswordSvg from "../../assets/file-types/doc.svg";
import pptSvg from "../../assets/file-types/ppt.svg";
import xlsSvg from "../../assets/file-types/xls.svg";
import txtSvg from "../../assets/file-types/txt.svg";
import filePng from "../../assets/file-types/file-png.png";
import aiSvg from "../../assets/file-types/ai.svg";
import jsSvg from "../../assets/file-types/js.svg";
import jsonSvg from "../../assets/file-types/js.svg";
import cssSvg from "../../assets/file-types/css.svg";
import csvSvg from "../../assets/file-types/csv.svg";
import epsSvg from "../../assets/file-types/eps.svg";
import exeSvg from "../../assets/file-types/exe.svg";
import htmlSvg from "../../assets/file-types/html.svg";
import movSvg from "../../assets/file-types/mov.svg";
import mp3Svg from "../../assets/file-types/mp3.svg";
import mp4Svg from "../../assets/file-types/mp4.svg";
import phpSvg from "../../assets/file-types/php.svg";
import psdSvg from "../../assets/file-types/psd.svg";
import rarSvg from "../../assets/file-types/rar.svg";
import svgSvg from "../../assets/file-types/svg.svg";
import wavSvg from "../../assets/file-types/wav.svg";
import zipSvg from "../../assets/file-types/zip.svg";

const ChatMessageList = (props) => {
    const { messages, chatDetails, agentDetails, unReadStatus, user } = props;

    useEffect(() => {}, [messages]);

    const getUserName = (msgObj) => {
        let userName = "Visitor";
        if (msgObj?.SenderType === 1) {
            userName = chatDetails?.user?.Name || "Visitor";
        } else {
            if(msgObj?.Agent?.Name){
                userName = msgObj?.Agent?.Name;
            }else{
                if(msgObj?.AutomationId){
                    userName = user?.Company?.Name;
                }else{
                    userName = user?.Name;
                }
            }
        }
        return userName ?? false;
    };

    const unixToDate = (date) => {
        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;
        interval = seconds / 3600;
        return moment(date).format("MM/DD/YYYY h:mm A");
    };

    const renderDate = (msg, index) => {
        let previousMessage = messages[index - 1];
        let date;

        if (!previousMessage) {
            date = moment(msg.CreatedAt).format("LLL");
        } else {
            let isPreviousDay = moment(previousMessage.CreatedAt).isBefore(msg.CreatedAt, "day");
            if (isPreviousDay) {
                date = moment(msg.CreatedAt).format("LLL");
            }
        }

        return date ? (
            <div className="ib-date">
                <p>{date}</p>
            </div>
        ) : null;
    };

    const getMessageContent = (msgObj) => {
        if (msgObj.IsAutomatedMessage === 1) {
            return msgObj.Automation?.IntroMessage;
        }
        return msgObj.Content;
    };

    const renderAutomatedMedia = (msgObj) => {
        if (
            msgObj.IsAutomatedMessage &&
            msgObj?.AutomationMedia &&
            msgObj?.AutomationMedia?.length
        ) {
            return (
                <div className="quick-msg automation-media-container">
                    {msgObj.AutomationMedia.map((media, index) => (
                        <a href={media?.Path} target="_blank">
                            <span key={index} className="q-bubble">
                                {media.Name}
                            </span>
                        </a>
                    ))}
                </div>
            );
        }
    };

    const replaceUrlWithHtmlTags = (content = "") => {
        let text = content.replace(/(?:\r\n|\r|\n)/g, "<br/>");
        text = anchorme(text, {
            attributes: [{ name: "target", value: "blank" }]
        });
        text = _.unescape(text);
        return text;
    };

    const renderMessage = (msgObj) => {
        if (msgObj.MediaUrl) {
            let url = msgObj.MediaUrl;

            let splittedUrl = url.split(".");
            let MediaUrlType = splittedUrl[splittedUrl.length - 1];
            MediaUrlType = MediaUrlType.includes("gif") ? "gif" : MediaUrlType;
            MediaUrlType = MediaUrlType.toLowerCase();

            if (AllowedFileTypes.includes(MediaUrlType)) {
                let documentImage;
                switch (MediaUrlType) {
                    case "pdf":
                        documentImage = pdfSvg;
                        break;
                    case "docx":
                        documentImage = mswordSvg;
                        break;
                    case "doc":
                        documentImage = mswordSvg;
                        break;
                    case "msword":
                        documentImage = mswordSvg;
                        break;
                    case "ppt":
                        documentImage = pptSvg;
                        break;
                    case "pptx":
                        documentImage = pptSvg;
                        break;
                    case "xlsx":
                        documentImage = xlsSvg;
                        break;
                    case "xls":
                        documentImage = xlsSvg;
                        break;
                    case "txt":
                        documentImage = txtSvg;
                        break;
                    case "ai":
                        documentImage = aiSvg;
                        break;
                    case "javascript":
                        documentImage = jsSvg;
                        break;
                    case "js":
                        documentImage = jsSvg;
                        break;
                    case "json":
                        documentImage = jsonSvg;
                        break;
                    case "css":
                        documentImage = cssSvg;
                        break;
                    case "csv":
                        documentImage = csvSvg;
                        break;
                    case "eps":
                        documentImage = epsSvg;
                        break;
                    case "exe":
                        documentImage = exeSvg;
                        break;
                    case "html":
                        documentImage = htmlSvg;
                        break;
                    case "mov":
                        documentImage = movSvg;
                        break;
                    case "mp3":
                        documentImage = mp3Svg;
                        break;
                    case "mpeg":
                        documentImage = mp3Svg;
                        break;
                    case "mp4":
                        documentImage = mp4Svg;
                        break;
                    case "php":
                        documentImage = phpSvg;
                        break;
                    case "psd":
                        documentImage = psdSvg;
                        break;
                    case "rar":
                        documentImage = rarSvg;
                        break;
                    case "svg":
                        documentImage = svgSvg;
                        break;
                    case "wav":
                        documentImage = wavSvg;
                        break;
                    case "zip":
                        documentImage = zipSvg;
                        break;
                    default:
                        documentImage = filePng;
                        break;
                }
                return (
                    <>
                        <div style={{ width: "100px", padding: "5px 0 5px 0" }}>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={documentImage}
                                    alt="uploaded"
                                    style={{ height: "100%", width: "100%" }}
                                    onClick={() => {
                                        window.open(url);
                                    }}
                                />
                            </a>
                        </div>
                        {msgObj?.IsAutomatedMessage === 1 || msgObj?.Content?.length > 0 ? (
                            <div
                                className="ib-panel-msg"
                                dangerouslySetInnerHTML={{
                                    __html: replaceUrlWithHtmlTags(getMessageContent(msgObj))
                                }}
                            />
                        ) : null}
                    </>
                );
            } else {
                return (
                    <>
                        <div style={{ width: "150px", padding: "5px 0 5px 0" }}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <img
                                src={msgObj.MediaUrl}
                                alt="uploaded"
                                style={{ height: "100%", width: "100%" }}
                            />
                            </a>
                        </div>
                        {msgObj?.IsAutomatedMessage === 1 || msgObj?.Content?.length > 0 ? (
                            <div
                                className="ib-panel-msg"
                                dangerouslySetInnerHTML={{
                                    __html: replaceUrlWithHtmlTags(getMessageContent(msgObj))
                                }}
                            />
                        ) : null}
                    </>
                );
            }
        }
        return (
            <div
                className="ib-panel-msg"
                dangerouslySetInnerHTML={{
                    __html: replaceUrlWithHtmlTags(getMessageContent(msgObj))
                }}
            ></div>
        );
    };

    return (
        messages.length != 0 &&
        messages.map((msgObj, index) => (
            <div key={index}>
                {!unReadStatus && renderDate(msgObj, index)}
                {msgObj.SenderType === 2 && msgObj?.Type == 2 ? (<div className="unread-msg-panel">
                    <div className="unread-msg-bar">{msgObj?.Agent?.Name} joined the chat</div>
                </div>) : null}
                {msgObj?.Type == 3 ? (<div className="unread-msg-panel">
                    <div className="unread-msg-bar">Visitor rejoined the chat</div>
                </div>) : null}
                {!(msgObj?.Type == 2 || msgObj?.Type == 3) ? (<div className="unread-msg-panel">
                    <div
                     className={
                            msgObj.SenderType === 1
                               ? "ib-panel ib-left-panel"
                                : "ib-panel ib-right-panel"
                        }
                    >
                        <div className="ib-panel-name">
                        {convertNameIntoInitials(getUserName(msgObj))}
                        </div>
                        <div className="ib-panel-body">
                            {renderMessage(msgObj)}
                            {renderAutomatedMedia(msgObj)}
                            <div className="ib-panel-date">
                                Sent:{" "}
                                <span>{`${unixToDate(new Date(msgObj.CreatedAt))}${
                                !msgObj?.IsAutomatedMessage && msgObj?.AutomationName
                                    ? `. From ${msgObj?.AutomationName}`
                                    : ""
                                }
                                ${
                                !msgObj?.IsAutomatedMessage && msgObj?.Agent?.Name && msgObj?.SenderType === 2
                                    ? ` by ${msgObj?.Agent?.Name}`
                                    : ""
                                }`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>) : null}
            </div>
        ))
    );
};

const mapStateToProps = (state) => {
    return {
        chatDetails: state.chat.currentChat.details,
        agentDetails: state.chat.currentChat.agentDetails,
        user: state.auth.user,
    };
};

export default connect(mapStateToProps)(ChatMessageList);

