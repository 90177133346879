import Config from "../Config";
import { store } from "../store";

export const convertBase64ToFileObj = (base64Url, customFile) => {
  return fetch(base64Url)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], customFile.name, { type: customFile.type });
      return file;
    });
};

export const objectContainsKey = (object, key) => {
  return typeof object === "object" && object && object[key] !== undefined;
};

export const convertInches = (inches) => {
  if (inches) {
    let feetFromInches = Math.floor(inches / 12);
    let inchesRemainder = inches % 12;

    return feetFromInches + "ft " + inchesRemainder + '"';
  }
  return "";
};

export const splitByCase = (word) => {
  return word.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
};

export const getIdsFromData = (data, key = "Id") => {
  return data.map((item) => item[key]);
};

export const getUserNames = (users) => {
  const chatUsers = [];
  {
    !!users.length &&
      users.map((user) => {
        if (user.UserId !== store.getState().auth.user.Id) chatUsers.push(user.Name);
      });
  }

  return chatUsers.join(", ");
};

export const escapeHTMLElementsFromMessage = (message) => {
  //todo places in util helper
  var htmlEscapes = {
    "<": "&lt;",
    ">": "&gt;"
  };
  // Regex containing the keys listed immediately above.
  var htmlEscaper = /[<>]/g;
  // Escape a string for HTML interpolation.
  return message.replace(htmlEscaper, function (match) {
    return htmlEscapes[match];
  });
};

export const getRandomNumber = () => {
  return new Date().getTime().toString() + Math.floor(Math.random() * 1000000);
};

export const canFileUploaded = (file) => {
  let totalBytes = file.size;
  let fileInMb = totalBytes / 1024 / 1024;
  if (fileInMb <= 8) {
    return true;
  }
  return false;
};

export const convertNameIntoInitials = (name) => {
  if (!name) {
    return "";
  }
  // var parts = name?.split(" ");
  // var initials = "";
  // for (var i = 0; i < parts.length; i++) {
  //     if (i < 2)
  //         if (parts[i].length > 0 && parts[i] !== "") {
  //             initials += parts[i][0];
  //         }
  // }
  // return initials?.toUpperCase();
  var initial = "";
  if (name.trim()) {
    initial = name?.trim()[0]?.toUpperCase();
  }
  return initial;
};

export const isEmailValid = (email) => {
  const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  return Boolean(!emailRegex.test(email));
};

export const getFormattedNumber = (num) => {
  return validateNumber(num) ? formatNumber(num) : num;
};

const validateNumber = (num) => {
  let re = /^[+]1[0-9]{10}$/g;
  if (re.test(num)) {
    return true;
  }
  return false;
};

const formatNumber = (num) => {
  num = `${num.slice(0, 2)} (${num.slice(2, 5)})-${num.slice(5, 8)}-${num.slice(8)}`;
  return num;
};

export const getScript = (ApiKey) => {
  return `<script type="text/javascript">
  (function(scope, doc, tagName, src, objectName, newElement, firstElement) {
    Array.isArray(scope["LongDriveAgency"]) ?
    scope["LongDriveAgency"].push(objectName) :
    (scope["LongDriveAgency"] = [objectName]);
    scope[objectName] =
    scope[objectName] ||
    function() {
    scope[objectName].queries = scope[objectName].queries || [];
    scope[objectName].queries.push(arguments);
    };
    scope[objectName].scriptInjectedAt = 1 * new Date();
    newElement = doc.createElement(tagName);
    newElement.setAttribute("id", "long-drive-agency-widget-script");
    firstElement = doc.getElementsByTagName(tagName)[0];
    newElement.async = 1;
    newElement.src = src;
    firstElement
    ?
    firstElement.parentNode.insertBefore(newElement, firstElement) :
    doc.getElementsByTagName("head")[0].appendChild(newElement);
    })(window, document, "script", "${
      Config.env().WEBCHAT_URL_DOMAIN
    }longdriveagency.js?v=1414556766776095155192326&api_key=${ApiKey}", "longDriveAgency");
    longDriveAgency("Init", "${ApiKey}");
    </script>`;
};
