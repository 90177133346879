import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import "./inbox.scss";

import ChatUserList from "./ChatUserList";
import ChatMessageSection from "./ChatMessageSection";

import Onboardlogo from "../../assets/images/Onboardlogo.svg";
import { clearChatInitialFetch, companyWidgetsListingAgent, setWidgetCompanyListing } from "../../store/actions";
import { useState } from "react";
import ErrorImage from "../../assets/images/403Illustration_3.svg"

const ActiveChat = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const companyWidgetListing = useSelector((state) => state.automation.widgetCompanyListing)
    useEffect(() => {
        dispatch(companyWidgetsListingAgent())
            .then((res) => {
                // setCompanyListing(res);
                dispatch(setWidgetCompanyListing(res))
                // dispatch()
            })
            .catch((err) => {
                console.log(err.message)
            });
        return () => {
            dispatch(clearChatInitialFetch());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(companyWidgetListing)
    }, [companyWidgetListing])

    return (
        <div className="inbox-wrapper" >
            <div className="auto-sec" style={{ height: !props.isHeaderStatus ? '100vh' : 'calc(100vh - 75px)' }}>
                {companyWidgetListing?.length > 0 ? (
                    <>

                        <Switch>
                            <Route path="/dashboard/inbox/:tabname/:id?">
                                {(props) => <ChatUserList {...props} />}
                            </Route>
                            <Redirect to="/dashboard/inbox/active" />
                        </Switch>

                        <Switch>
                            <Route path="/dashboard/inbox/:tabname/:id">
                                {(props) => <ChatMessageSection {...props} />}
                            </Route>
                            <Route>
                                <section className="blankInbox">
                                    <img src={Onboardlogo} alt="Logo" />
                                    <h3>No Messages</h3>
                                </section>
                            </Route>
                        </Switch>
                    </>
                ) : (
                    <div className="error-403-img-container">
                        <img src={ErrorImage} width={633} className="error-403-img"/>
                        <h3>Looks like you have not been assigned to any websites yet</h3>
                        <p>to create automation or view messages from websites please contact your Club’s admin</p>

                        <button>Contact Club Admin</button>
                    </div>
                )}
            </div>
        </div>
    );
};
const mapStateToProps = (state, ownProps) => {

    return {
        isHeaderStatus: state.auth.isHeaderStatus
    };
};
export default connect(mapStateToProps, null)(ActiveChat);
