export default [
    "vnd.ms-excel",
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "vnd.openxmlformats-officedocument.wordprocessingml.document",
    "msword",
    "pdf",
    "docx",
    "doc",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "txt",
    "ai",
    "js",
    "javascript",
    "json",
    "css",
    "csv",
    "eps",
    "exe",
    "html",
    "mov",
    "mp3",
    "mp4",
    "php",
    "psd",
    "rar",
    "svg",
    "wav",
    "zip",
    "mpeg",
    "vnd.ms-powerpoint",
    "svg+xml",
    "x-zip-compressed",
];