import React, { useEffect } from "react";
import WebSetup from "./step-one";
import WidgInst from "./step-two";
import AcessOther from "./step-three";
import { Steps, Button } from "antd";
import { useHistory } from "react-router";
import Onboardlogo from "../../assets/images/WebChat-icon.svg";
import { APP_ROUTES, getRoute } from "../../helpers/routesHelper";
import { connect } from "react-redux";
import { setCurrent } from "../../store/actions";
const { Step } = Steps;

const steps = [
    {
        title: "Website Setup",
        className: "myfirst-step",
        content: <WebSetup />
    },
    {
        title: "Widget Installation",
        content: <WidgInst />
    },
    {
        title: "Access to others",
        content: <AcessOther />
    }
];

const MultiStepForm = ({ current, setCurrent }) => {
    const history = useHistory();
    useEffect(() => {
        setCurrent(0);
    }, []);
    const redirectTo = () => {
        history.push(getRoute(APP_ROUTES.WEB_CHAT));
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const getHelp = () => {
        try{
            window.Intercom("show");
            let parentUrl = window.location?.ancestorOrigins[0];
            if(parentUrl){
                window.parent.postMessage({Message: "OPEN_INTERCOM", Data: true}, parentUrl)
            }
        }catch(err){
            console.log("intercom error", err)
        }
    }

    return (
        <>
            <div className="screen-title">
                <img src={Onboardlogo} alt="Logo" />
                <h3>Welcome to WebChat</h3>
                <p>You are only few steps away from improving your customer experience</p>
            </div>
            <Steps current={current}>
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
                {current > 0 && current < steps.length - 1 && (
                    <>
                        <Button
                            className="get-help-btn"
                            type="primary"
                            
                            style={{ margin: "0 8px" }}
                            onClick={()=> getHelp()}
                        >
                            Get Help
                        </Button>
                        <Button type="primary" onClick={() => next()}>
                            Next Step
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const current = state.automation.current;
    return { current };
};
const mapDispatchToProps = {
    setCurrent
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiStepForm);
