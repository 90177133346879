import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./modal.scss";
import { useHistory } from "react-router";
function AutoComplete({ show, hide, classcomp, icon, text, btntext1, route, text2, onSetLive }) {
    const history = useHistory();

    const redirectTo = () => {
        history.push(route);
    };
    const handleOnClick = () => {
        hide();
        redirectTo();
    };
    const handleSetLive = () => {
        hide();
        onSetLive();
    };

    return (
        <section className="as-complete-sec auto-comp-sec">
            <Modal
                backdrop="static"
                show={show}
                onHide={hide}
                className={classcomp}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="checkicon-sec">
                        <span></span>
                        {icon}
                    </div>
                    <p>{text}{text2 && <span style={{cursor:"pointer", color:"green", textDecoration:"underline"}} onClick={handleSetLive}>{text2}</span>}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleOnClick}>{btntext1}</Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}
export default AutoComplete;
