import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory, useParams } from "react-router-dom";
import { Spin } from "antd";
import { APP_ROUTES, getRoute } from "../../../helpers/routesHelper";
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown";
import { useDispatch } from "react-redux";
import {
    inactiveAutomationListing,
    activeAutomationListing
} from "../../../store/actions/automationActions";
import "./subSidebar.scss";
const SubSidebar = ({ cId = 1, statusChanged, disableAutomation }) => {
    const params = useParams();
    const [toggleActive, setToggleActive] = useState(
        params?.status == APP_ROUTES.ACTIVE && cId == params?.cId ? true : false
    );
    const [toggleInActive, setToggleInActive] = useState(
        params?.status === APP_ROUTES.IN_ACTIVE && cId == params?.cId ? true : false
    );
    const [isLoadingActive, setIsLoadingActive] = useState(false);
    const [tbldata, settbldata] = useState([]);
    const [activeListData, setActiveListData] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!disableAutomation) {
            setIsLoadingActive(true);
            dispatch(inactiveAutomationListing(cId))
                .then((res) => {
                    settbldata(res);
                    setIsLoadingActive(false);
                })
                .catch((err) => {
                    setIsLoadingActive(false);
                });
        } else {
            settbldata([]);
        }
    }, []);
    useEffect(() => {
        if (!disableAutomation) {
            setIsLoadingActive(true);
            dispatch(activeAutomationListing(cId))
                .then((res) => {
                    setActiveListData(res);
                    setIsLoadingActive(false);
                })
                .catch((err) => {
                    setIsLoadingActive(false);
                });
        } else {
            setActiveListData([]);
        }
    }, []);

    useEffect(
        () => {
            async function automationListChecking() {
                try {
                    setIsLoadingActive(true);

                    const res = await dispatch(inactiveAutomationListing(cId));
                    settbldata(res);

                    setIsLoadingActive(false);
                } catch {
                    settbldata([]);
                    setIsLoadingActive(false);
                }
            }
            if (statusChanged.id == cId) {
                automationListChecking();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [statusChanged.id, statusChanged.status]
    );
    useEffect(
        () => {
            async function automationListChecking() {
                try {
                    setIsLoadingActive(true);

                    const res2 = await dispatch(activeAutomationListing(cId));
                    setActiveListData(res2);
                    setIsLoadingActive(false);
                } catch {
                    setActiveListData([]);
                    setIsLoadingActive(false);
                }
            }
            if (statusChanged.id == cId) {
                automationListChecking();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [statusChanged.id, statusChanged.status]
    );

    useEffect(() => {
        setToggleInActive(
            params?.status === APP_ROUTES.IN_ACTIVE && cId == params?.cId ? true : false
        );
        setToggleActive(params?.status == APP_ROUTES.ACTIVE && cId == params?.cId ? true : false);
    }, [params?.id]);
    return (
        <div className="ls-ul">
            <ul className="ls-pai">
                <NavLink
                    to={
                        disableAutomation
                            ? getRoute(APP_ROUTES.WEB_CHAT, { cId })
                            : getRoute(APP_ROUTES.ACTIVE_AUTOMATION, {
                                  cId: cId
                              })
                    }
                    activeClassName=""
                    className={toggleActive ? "active" : "inactive"}
                    onClick={() => {
                        if (!toggleActive) {
                            setToggleInActive(false);
                        }
                        setToggleActive(!toggleActive);
                    }}
                >
                    <span>Active</span> <FaAngleDown />
                    <li className="ls-pa">
                        {!isLoadingActive ? (
                            <ul className="ls-cai">
                                {activeListData.map((tbdata, index) => {
                                    return (
                                        <Link
                                            className={
                                                tbdata.Id == params?.id
                                                    ? "automationName wc-bold multiline-truncate"
                                                    : "automationName multiline-truncate"
                                            }
                                            exact
                                            to={getRoute(APP_ROUTES.UPDATE_WEB_CAHT, {
                                                cId: cId,
                                                status: APP_ROUTES.ACTIVE,
                                                id: tbdata.Id
                                            })}
                                            title={tbdata.Name}
                                        >
                                            {tbdata.Name}
                                        </Link>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div className="loadings">
                                <Spin />
                            </div>
                        )}
                    </li>
                </NavLink>
                <NavLink
                    to={
                        disableAutomation
                            ? getRoute(APP_ROUTES.WEB_CHAT, { cId: cId })
                            : getRoute(APP_ROUTES.IN_ACTIVE_AUTOMATION, {
                                  cId: cId
                              })
                    }
                    activeClassName=""
                    className={toggleInActive ? "active" : "inactive"}
                    onClick={() => {
                        if (!toggleInActive) {
                            setToggleActive(false);
                        }
                        setToggleInActive(!toggleInActive);
                    }}
                >
                    <span>Inactive</span> <FaAngleDown />
                    <li className="ls-pa">
                        {!isLoadingActive ? (
                            <ul className="ls-cai">
                                {tbldata.map((tbdata, index) => {
                                    return (
                                        <Link
                                            className={
                                                tbdata.Id == params?.id
                                                    ? "automationName wc-bold multiline-truncate"
                                                    : "automationName multiline-truncate"
                                            }
                                            to={getRoute(APP_ROUTES.UPDATE_WEB_CAHT, {
                                                cId: cId,
                                                status: APP_ROUTES.IN_ACTIVE,
                                                id: tbdata.Id
                                            })}
                                            title={tbdata.Name}
                                        >
                                            {tbdata.Name}
                                        </Link>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div className="loadings">
                                <Spin />
                            </div>
                        )}
                    </li>
                </NavLink>
            </ul>
        </div>
    );
};
export default SubSidebar;
