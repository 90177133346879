import React, { useEffect, useRef } from 'react'

const Favicon = ({ children, count }) => {
    const ref = useRef(null)

    useEffect(() => {
        // alternatively, use React Helmet etc.
        const favicon = document.querySelector(
            'link[rel="icon"]',
        )

        if (favicon && ref.current) {
            favicon.href = `data:image/svg+xml;utf8,${encodeURIComponent(
                ref.current.outerHTML,
            )}`
        }
    }, [children, count])

    return (
        <div hidden={true}>
            <svg
                ref={ref}
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox={count === 0 ? "0 0 16 16" : "0 0 21.8 18.16"}
            >
                <defs>
                    <style />
                </defs>
                {count === 0 ? (
                    <>
                        <path
                            d="m15.78 13.95-.05-.05-1.25-1.2.05-.06c.83-.91 1.33-2.06 1.44-3.28v-.25c.01-3.12-2.15-5.38-5.58-5.84.42.82.64 1.73.64 2.65 0 .31-.03.62-.07.92.49.24.68.84.44 1.32-.17.33-.51.54-.88.54h-.2c-.21.38-.45.73-.74 1.05h2.22c.54 0 .98.44.98.98s-.44.98-.98.98H5.21c-1.13 0-2.24-.33-3.18-.95.92 2.56 3.78 4.39 7.15 4.46h6a.79.79 0 0 0 .61-1.29Z"
                            style={{
                                fill: "#142a38",
                            }}
                        />
                        <path
                            d="M9.15 2.93s-.02-.05-.04-.08a.304.304 0 0 0-.08-.08 5.01 5.01 0 0 0-7.01-1.01A5.06 5.06 0 0 0 .1 4.85c-.02.05-.03.1-.03.15v.04c-.04.24-.05.49-.05.74 0 2.74 2.19 4.97 4.93 5.01h.08c2.76 0 5.01-2.24 5.01-5 0-1.01-.31-2.01-.88-2.84Zm.09 3.36c-.08.56-.65 1.03-1.52 1.34.15-.41.24-.83.29-1.26.46-.1.9-.26 1.3-.49 0 .09 0 .18-.02.27-.03.04-.04.09-.05.14ZM1.36 3.57c.35.18.71.32 1.08.42-.18.54-.28 1.11-.3 1.68C1.33 5.46.87 5.21.8 5.05c.09-.52.28-1.02.55-1.48Zm7.33-.02c.26.42.44.89.54 1.37 0 .03-.01.06-.01.09 0 .15-.4.4-1.18.61a5.39 5.39 0 0 0-.33-1.68c.34-.1.67-.23.99-.4ZM7.31 5.79c-.57.1-1.14.16-1.71.18v-.3c0-.46 0-.91-.03-1.35.48-.02.95-.08 1.42-.18.2.52.31 1.08.32 1.64ZM5.54 3.6c-.03-.5-.07-.99-.12-1.45.51.34.93.8 1.24 1.33-.37.06-.75.1-1.12.12Zm-.85-1.54c.06.49.1 1.01.13 1.55-.46 0-.92-.05-1.38-.12.29-.57.72-1.06 1.25-1.43Zm.17 2.27c.01.43.02.89.02 1.34v.31c-.67 0-1.34-.05-2-.16 0-.56.09-1.12.27-1.65.56.1 1.14.16 1.71.16ZM.9 6.73s-.04-.05-.07-.08C.78 6.4.75 6.15.74 5.9c.45.24.93.4 1.43.5.04.43.14.86.28 1.27-.79-.25-1.33-.6-1.55-.95Zm2.02-.19c.64.09 1.29.14 1.94.14-.01.47-.03.93-.06 1.36-.5 0-1-.06-1.49-.15-.19-.43-.32-.89-.39-1.35Zm1.82 2.22-.09.79c-.34-.24-.63-.52-.87-.86.31.04.63.06.96.07Zm.64.76c.03-.25.06-.5.08-.76.3-.01.57-.03.84-.06-.26.32-.58.6-.93.83Zm.14-1.49c.03-.44.05-.89.06-1.36.56-.02 1.11-.07 1.67-.15-.07.47-.21.93-.43 1.36-.43.08-.87.13-1.31.15Zm2.73-5.08c-.27.13-.56.24-.85.32a4.94 4.94 0 0 0-1.49-1.69c.91.19 1.73.67 2.34 1.37ZM4.07 1.59c-.58.46-1.05 1.04-1.36 1.71-.32-.08-.62-.19-.92-.33.59-.69 1.39-1.18 2.28-1.38ZM1.39 8.03c.46.23.94.39 1.44.5.28.52.65.99 1.1 1.37a4.3 4.3 0 0 1-2.54-1.88Zm4.69 1.89c.5-.38.91-.86 1.23-1.4.48-.11.94-.28 1.37-.51-.59.95-1.52 1.64-2.6 1.91Z"
                            style={{
                                fill: "#246b47",
                            }}
                        />
                    </>
                ) : (
                    <g id="Layer_2">
                        <g id="Layer_1-2">
                            <path
                                d="m15.8 16.16-1.3-1.2v-.1c.8-.9 1.3-2.1 1.4-3.3v-.2c0-3.1-2.2-5.4-5.6-5.8.5.7.7 1.6.7 2.5 0 .3 0 .6-.1.9.5.2.7.8.4 1.3-.2.3-.5.5-.9.5h-.2c-.2.4-.5.7-.7 1h2.2c.5 0 1 .4 1 1 0 .5-.4 1-1 1H5.2c-1.1 0-2.2-.3-3.2-1 .9 2.6 3.8 4.4 7.1 4.5h6c.4 0 .8-.3.8-.8.1 0 0-.2-.1-.3Z"
                                style={{
                                    fill: "#142a38",
                                }}
                            />
                            <path
                                d="M9.1 5.06q0-.1-.1-.1c-1.7-2.2-4.8-2.7-7-1-1 .7-1.7 1.8-1.9 3v.1c-.1.4-.1.6-.1.9 0 2.7 2.2 5 4.9 5H5c2.8 0 5-2.2 5-5 0-1-.3-2-.9-2.9Zm.1 3.4c-.1.6-.6 1-1.5 1.3.2-.4.3-.8.3-1.2.5-.1.9-.3 1.3-.5v.3s-.1 0-.1.1Zm-7.8-2.7c.3.1.7.3 1 .4-.1.5-.2 1.1-.3 1.7-.8-.2-1.2-.5-1.3-.7.1-.5.3-1 .6-1.4Zm7.3 0c.2.4.4.8.5 1.3v.1c0 .1-.4.4-1.2.6 0-.6-.1-1.1-.3-1.7.3-.1.7-.2 1-.3Zm-1.4 2.2c-.6.1-1.1.1-1.7.2v-1.7c.5 0 1-.1 1.4-.2.2.6.3 1.1.3 1.7Zm-1.8-2.2c0-.5-.1-1-.1-1.5.5.3.9.8 1.2 1.3-.3.1-.7.2-1.1.2Zm-.8-1.6c.1.5.1 1 .1 1.5-.5 0-.9 0-1.4-.1.3-.5.8-1 1.3-1.4Zm.1 2.3v1.6c-.7 0-1.3-.1-2-.2 0-.6.1-1.1.3-1.7.6.2 1.2.3 1.7.3ZM.9 8.86c-.1-.3-.1-.5-.2-.8.4.2.9.4 1.4.5 0 .4.1.9.3 1.3-.7-.3-1.3-.6-1.5-1Zm2-.2c.6.1 1.3.1 1.9.1 0 .5 0 .9-.1 1.4-.4 0-.9 0-1.4-.1-.2-.4-.3-.9-.4-1.4Zm1.8 2.3c0 .3-.1.5-.1.8-.3-.3-.6-.6-.8-.9.3 0 .6.1.9.1Zm.7.7c0-.2.1-.5.1-.8.3 0 .6 0 .8-.1-.3.4-.6.7-.9.9Zm.1-1.5c0-.4.1-.9.1-1.4.6 0 1.1-.1 1.7-.1-.1.5-.3 1-.5 1.4-.4.1-.8.1-1.3.1Zm2.8-5-.9.3c-.4-.7-.9-1.3-1.5-1.7.9.2 1.7.7 2.4 1.4Zm-4.2-1.4c-.6.5-1 1-1.4 1.7l-.9-.3c.6-.7 1.4-1.2 2.3-1.4Zm-2.7 6.4c.5.2.9.4 1.4.5.3.5.7 1 1.1 1.4-1-.3-1.9-.9-2.5-1.9Zm4.7 1.9c.5-.4.9-.8 1.2-1.4.5-.1.9-.3 1.4-.5-.6 1-1.5 1.6-2.6 1.9Z"
                                style={{
                                    fill: "#246b47",
                                }}
                            />
                        </g>
                    </g>
                )}
                {count && (
                    <>
                        <circle
                            cx={16.82}
                            cy={4.97}
                            r={5}
                            style={{
                                fill: "#e94d4d",
                            }}
                        />
                        <text
                            xmlns="http://www.w3.org/2000/svg"
                            transform="translate(10.894 6.949)"
                            className="st2 st3 st4"
                            style={{
                                transform: "matrix(.6,0,0,.6,14.2942,7.9488)",
                                fill: "#fff",
                            }}
                        >
                            {count}
                        </text>
                    </>
                )}
            </svg>
        </div>
    )
}

export default Favicon