export const APP_ROUTES = {
    SIGNUP: "/signup-verification",
    LOGIN: "/login",
    ACCOUNT_SETUP: "/dashboard/account-setup/:id?",
    DASHBOARD: "/dashboard",
    CHAT: "/dashboard/inbox/:tabname?/:id?",
    API_FORM: "/dashboard/apiform",

    AUTOMATION: "/dashboard/automation/:cId?/:status?/:id?",
    WEB_CHAT: "/dashboard/automation/:cId?",
    UPDATE_WEB_CAHT: "/dashboard/automation/:cId/:status/:id",
    ADD_WEB_CHAT: "/dashboard/automation/:cId/add",
    IN_ACTIVE_AUTOMATION: "/dashboard/automation/:cId/in-active",
    ACTIVE_AUTOMATION: "/dashboard/automation/:cId/active",
    ACTIVE: "active",
    IN_ACTIVE: "in-active",
    COMPANY_LISTING: "/dashboard/company-listing",
    NOT_AUTHENTICATED : "/not-authenticated",
    ACCOUNT: "/account"
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys.forEach((item) => {
            route = route.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
