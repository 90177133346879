import React from "react";
import "./buttons.scss";

const DefaultButton = ({ onClick, _class, disabled = false, icon, title, tooltip }) => {

    return (
        <buttons
            title={tooltip}
            onClick={
                disabled
                    ? () => false
                    : onClick
            }
            className={disabled ? _class + " diable-button" : _class}
            disabled={disabled}
        >
            {icon}
            {title}
        </buttons>
    );
};
export default DefaultButton;
