import { APP_ROUTES } from "./helpers/routesHelper";

import SetupWebChat from "./views/setup-webchat/setup-webchat";

import Chat from "./views/inbox/Chat";
import ApiForm from "./ApiForm";
import Automation from "./views/automation-screens/automation";
import Cards from "./components/card/cards";
import NotAuthenticated from "./views/not-authenticated/NotAuthenticated";

const routes = [
    {
        path: APP_ROUTES.ACCOUNT_SETUP,
        name: "AccountSetup",
        exact: true,
        component: SetupWebChat
    },
    { path: APP_ROUTES.AUTOMATION, name: "Automation", exact: false, component: Automation },

    {
        path: APP_ROUTES.CHAT,
        name: "inbox",
        exact: true,
        component: Chat
    },
    {
        path: APP_ROUTES.API_FORM,
        name: "Api-form",
        exact: true,
        component: ApiForm
    },
    {
        path: APP_ROUTES.COMPANY_LISTING,
        name: "Company-listing",
        exact: true,
        component: Cards
    },
    {
        path: APP_ROUTES.NOT_AUTHENTICATED,
        name: "Not-Authenticated",
        exact: true,
        component: NotAuthenticated
    }
];
export default routes;
