import React from "react";
import MultiStep from "./multi-step";
import "./style.scss";
import Master from "../../components/common/master";
const SetupWebChat = () => {
    return (
        <Master isSidebar={false}>
            <section className="webchat-form-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-md-10 col-sm-11 setup-form-sec">
                            <div className="setup-form">
                                <MultiStep />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Master>
    );
};

export default SetupWebChat;
