export const playAudioOnNewMessage = () => {
    try{
        let audioUrl = "https://rangeconnect.tk/static/media/2_Blop.61113953.wav"
        let audio = new Audio(audioUrl);
        audio.addEventListener("canplaythrough", event => {
            audio.play();
        })
    }catch(err){
        console.log("audio error", err)
    }
}