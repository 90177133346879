import React from "react";
import "../../App.css";

class Dropdown extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.innerReference = React.createRef();
  }

  openDropDown = () => {
    this.setState({
      open: !this.state.open
    });
    this.bindDocumentListener();
  };
  isDropDownOpen = () => {
    return this.state.open;
  };
  isDropDownInstance = () => {
    return true;
  };
  bindDocumentListener = () => {
    document.getElementById("root").addEventListener("click", this.documentClick, false);
  };

  unbindDocumentListener = () => {
    document.getElementById("root").removeEventListener("click", this.documentClick, false);
  };

  documentClick = (e) => {
    const getSoundDocument = document.getElementById("sound-toggle");
    if (getSoundDocument?.contains(e.target)) {
      this.setState({
        open: true
      });
      return;
    }
    if (
      this.props.innerReference &&
      this.props.innerReference.current &&
      !this.props.innerReference.current.contains(e.target)
    ) {
      this.setState({
        open: false
      });
      if (this.props.closeCallback) {
        this.props.closeCallback();
      }
      this.unbindDocumentListener();
    }
  };

  render() {
    const { open } = this.state;
    return (
      <div onClick={() => this.openDropDown}>
        <div className={`dropdown-show ${!open ? "hide" : ""}`}> {this.props.children}</div>
      </div>
    );
  }
}

export default Dropdown;
