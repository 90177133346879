import { Howl, Howler } from 'howler';
import Alert from "../assets/Audio/2_Blop.wav"

const sound = new Howl({
    src: [Alert],
})
const sendNotification = (title, body, makeAlertSound = true) => {
    if (makeAlertSound) {
        Howler.autoSuspend = false
        Howler.autoUnlock = true
        Howler.usingWebAudio = true
        sound.play()
    }
    new Notification(title, { body, silent: true })
}

export default sendNotification