import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtil";

export const SET_CURRENT = "SET_CURRENT";
export const SET_POST_DATA = "SET_POST_DATA";
export const SET_COMPANY_LISTING = "SET_COMPANY_LISTING";
export const SET_ACTIVE_COMPANY = "SET_ACTIVE_COMPANY"
export const SET_WIDGET_COMPANY_LISTING = "SET_WIDGET_COMPANY_LISTING"
export const setCurrent = (value) => {
    return { type: SET_CURRENT, value };
};
export const setActiveCompany = (value) => {
    return { type: SET_ACTIVE_COMPANY, value }
}
export const setCompantListing = (value) => {
    return { type: SET_COMPANY_LISTING, value };
};

export const setWidgetCompanyListing = (value) => {
    return { type: SET_WIDGET_COMPANY_LISTING, value };
};

export const setPostData = (value) => {
    return { type: SET_POST_DATA, value };
};

export const inactiveAutomationListing = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automations/list/?Status=inactive&WebsiteId=${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const activeAutomationListing = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automations/list/?Status=active&WebsiteId=${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const getAutomation = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automation/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const postAutomationMedia = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: "/media",
        token,
        method: "POST",
        body,
        image: true
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const putAutomation = (body, id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automation/update/${id}`,
        token,
        method: "PUT",
        body: JSON.stringify(body)
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const createAutomation = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: "/create/automation",
        token,
        method: "POST",
        body: JSON.stringify(body)
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const createWebChat = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: "/create/webchat",
        token,
        method: "POST",
        body: JSON.stringify(body)
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const updateAutomationStatusToLive = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automation/set-live/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const updateAutomationStatusToPause = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automation/pause/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const companyWidgetsListing = (authToken) => (dispatch, getState) => {
    const token = authToken || getState().auth.token;

    return fetchUtil({
        url: "/webchats/listing",
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const companyWidgetsListingAgent = (authToken) => (dispatch, getState) => {
    const token = authToken || getState().auth.token;

    return fetchUtil({
        url: "/webchats/listing/agent",
        showError:false,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getCompanyAgents = () => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: "/get/company/users",
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const companyWidget = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/webchat/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const companyUsers = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/get/company/users`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const updateAssignedUsers = (id) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/update/user_assigned_status/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const assignedUsers = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/assign/users`,
        token,
        method: "POST",
        body: JSON.stringify(body)
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const deleteMedia = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automation-media/remove/multiple`,
        token,
        method: "DELETE",
        body: JSON.stringify(body)
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const addMedia = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automation-media/add`,
        token,
        method: "POST",
        body: JSON.stringify(body)
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const editMedia = (body) => (dispatch, getState) => {
    const token = getState().auth.token;

    return fetchUtil({
        url: `/automation-media`,
        token,
        method: "PUT",
        body: JSON.stringify(body)
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
