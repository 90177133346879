import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import {
  getWebChat,
  getWebChatWidgetSettings,
  updateWidgetSettings,
  getUsersByCompany
} from "../../../../../store/actions";
import dummyUser from "../../../../../assets/images/dummyuser.png";
import { Spinner } from "../../../../Loader";
import { toaster } from "../../../../../utils/loggerUtils";
import { getScript } from "../../../../../utils/commonUtil";

const DEFAULT_STYLES = {
  BACKGROUND_COLOR: "#1d3152",
  BUTTON_COLOR: "#3aaf50"
};
export const CHECKBOX_STATE = {
  Yes: "Yes",
  No: "No"
};

const AccountWebchat = ({
  getUsersByCompany,
  getWebChat,
  getWebChatWidgetSettings,
  updateWidgetSettings,
  loggedInUser,
  handleModalClose
}) => {
  const [loading, setLoading] = useState(false);
  const [companyUserLoading, setCompanyUserLoading] = useState(false);
  const [isWidgetSettingsLoading, setIsWidgetSettingsLoading] = useState(false);
  const [webchatData, setWebchatData] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);

  const [selectedWidget, setSelectedWidget] = useState(null);

  const [selectedWidgetSettings, setSelectedWidgetSettings] = useState({
    Id: null,
    websiteName: "",
    operatorName: "",
    backgroundColor: DEFAULT_STYLES.BACKGROUND_COLOR,
    buttonColor: DEFAULT_STYLES.BUTTON_COLOR,
    takeOperatorName: CHECKBOX_STATE.Yes
  });

  const [updateWidgetLoading, setUpdateWidgetLoading] = useState(false);

  useEffect(() => {
    if (selectedWidget) {
      fetchWidgetSettings(selectedWidget.Id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWidget]);

  useEffect(() => {
    fetchAllWebchatData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const companyUsersAllowChecking = (widgetUsers) => {
    let users = [...companyUsers];
    let filteredWidgetUsers = widgetUsers;
    if (widgetUsers.length) {
      filteredWidgetUsers = widgetUsers.filter((user) => user.DeletedAt === 0);
    }
    for (let i = 0; i < users.length; i++) {
      users[i].checked = 0;
      for (let j = 0; j < filteredWidgetUsers.length; j++) {
        if (Number(users[i].Id) === Number(filteredWidgetUsers[j].UserId)) {
          users[i].checked = 1;
          break;
        }
      }
    }

    if (users.length) {
      users = users.filter((user) => user.Id !== loggedInUser.Id);
    }
    setCompanyUsers(users);
  };

  const fetchWidgetSettings = async (WebsiteId) => {
    setIsWidgetSettingsLoading(true);
    try {
      const response = await getWebChatWidgetSettings(WebsiteId);
      setSelectedWidgetSettings({
        Id: response.Id,
        backgroundColor: response?.Settings?.BackgroundColor || DEFAULT_STYLES.BACKGROUND_COLOR,
        buttonColor: response?.Settings?.ButtonColor || DEFAULT_STYLES.BUTTON_COLOR,
        takeOperatorName: response?.Settings?.TakeOperatorName || CHECKBOX_STATE.Yes,
        websiteName: response.WebsiteName
      });
      setIsWidgetSettingsLoading(false);
      console.log(response.Users);
      companyUsersAllowChecking(response.Users);
    } catch (e) {
      setIsWidgetSettingsLoading(false);
    }
  };

  const fetchUsersByCompany = async () => {
    return new Promise((resolve, reject) => {
      setCompanyUserLoading(true);
      getUsersByCompany()
        .then((data) => {
          if (data.length) {
            data = data.filter((user) => user.Id !== loggedInUser.Id);
          }
          setCompanyUsers(data);
          setCompanyUserLoading(false);
          resolve(true);
        })
        .catch((e) => {
          console.log(e, "THIS IS ERROR");
          resolve(true);
        })
        .finally((e) => {
          // setLoading(false)
          setCompanyUserLoading(false);
          resolve(true);
        });
    });
  };
  const fetchAllWebchatData = async () => {
    setLoading(true);
    await fetchUsersByCompany();
    getWebChat()
      .then((data) => {
        setWebchatData(data);
        let firstWidget = data[0];
        if (firstWidget) {
          setSelectedWidget(firstWidget);
        }
      })
      .catch((e) => {
        console.log(e, "THIS IS ERROR");
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const handleOnChange = (event) => {
    setWidgetSettingsFieldValue(event.target.name, event.target.value);
  };

  const setWidgetSettingsFieldValue = (fieldKey, fieldValue) => {
    const clone = { ...selectedWidgetSettings };
    clone[`${fieldKey}`] = fieldValue;
    setSelectedWidgetSettings(clone);
  };

  const handleClickWidget = (element) => {
    if (element.Id !== selectedWidget.Id) {
      setSelectedWidget(element);
    }
  };

  const RenderEachWidget = ({ element }) => {
    return (
      <li
        onClick={() => handleClickWidget(element)}
        className={`widgetListItemContainer ${element.Id === selectedWidget.Id ? "isSelectedWidget" : ""
          }`}
      >
        <span onClick={() => handleClickWidget(element)}> {element.WebsiteName}</span>
        <span onClick={() => handleClickWidget(element)}>{element.WebsiteDomain}</span>
        <span>
          <button
            style={{ "text-decoration": "underline", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(getScript(element.ApiKey));
              toaster("Copied")
            }}
          >
            Copy Code
          </button>
        </span>
      </li>
    );
  };

  const handleUpdateWidgetSettings = () => {
    let AllowedUserIds = companyUsers.filter((user) => user.checked === 1).map((user) => user.Id);
    const payload = {
      WebsiteId: selectedWidgetSettings.Id,
      DisplayOperatorName: selectedWidgetSettings.takeOperatorName,
      BackgroundColor: selectedWidgetSettings.backgroundColor,
      ButtonColor: selectedWidgetSettings.buttonColor,
      AllowedUserIds: AllowedUserIds
    };

    setUpdateWidgetLoading(true);
    updateWidgetSettings(payload)
      .then((res) => {
        setUpdateWidgetLoading(false);
        handleModalClose();
        toaster("Settings updated successfully");
      })
      .catch((e) => { })
      .finally(() => {
        setUpdateWidgetLoading(false);
      });
  };

  const handleUpdateWebAccessUsers = (index) => {
    let users = [...companyUsers];
    users[index].checked = users[index].checked === 1 ? 0 : 1;
    setCompanyUsers(users);
  };

  return (
    <>
      <div className="account-webchat">
        <div className="webchat-table">
          <div className="row">
            <div className="w-12">
              <div className="tableHeadings">
                <span>
                  <h5>Websites</h5>
                </span>
                <span>
                  <h5>Domain</h5>
                </span>
                <span>
                  <h5>Installation</h5>
                </span>
              </div>
              <ul>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Spinner size={"lg"} color={"green"} />
                  </div>
                ) : webchatData.length > 0 ? (
                  webchatData.map((element) => {
                    return (
                      <React.Fragment key={element.Id}>
                        <RenderEachWidget element={element} />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <p>No Record found</p>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className={`${companyUsers && companyUsers.length ? "access-webchat" : ""}`}>
          <div className="row">
            {!companyUserLoading ? (
              companyUsers &&
              companyUsers.length > 0 && (
                <>
                  <div className="w-12">
                    <h3>Access to Webchat</h3>
                  </div>
                  <div className="contact-aside-content width-100 ">
                    <ul className="webAccess-grid webchataccess-container">
                      {companyUsers.map((user, index) => {
                        return (
                          <li>
                            <input
                              type="checkbox"
                              checked={user.checked}
                              onChange={() => {
                                handleUpdateWebAccessUsers(index);
                              }}
                            />
                            {/* <div className="cs-chck"></div> */}
                            {/* <label htmlFor={"label1"}></label> */}
                            <span style={{ marginLeft: 5 }}>{user.Name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )
            ) : (
              <div style={{ margin: "auto" }}>
                <Spinner size={"lg"} color={"green"} />{" "}
              </div>
            )}
            {/* // <div style={{ "margin": "auto" }}><Spinner size={"lg"} color={"green"} /> </div> */}
          </div>
        </div>
        <div className="webchat-last">
          {isWidgetSettingsLoading ? (
            <div
              className="w-12"
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Spinner size={"xxl"} color={"green"} />
            </div>
          ) : !isWidgetSettingsLoading && selectedWidgetSettings.Id ? (
            <div className="row">
              <div className="w-6">
                <h3>Customize Widget</h3>
                <p>Display operator name?</p>
                <ul className="radiobtn">
                  <li>
                    <input
                      type="radio"
                      id="takeOperatorNameyes"
                      name="takeOperatorName"
                      value={CHECKBOX_STATE.Yes}
                      checked={selectedWidgetSettings.takeOperatorName === CHECKBOX_STATE.Yes}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="takeOperatorNameyes">Yes</label>
                    <div className="check"></div>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="takeOperatorName"
                      name="takeOperatorName"
                      value={CHECKBOX_STATE.No}
                      checked={selectedWidgetSettings.takeOperatorName === CHECKBOX_STATE.No}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="takeOperatorName">No, just company name</label>
                    <div className="check"></div>
                  </li>
                </ul>

                <ul className="colorlist">
                  <li>
                    <label htmlFor="backgroundColor">Background color:</label>
                    <div className="colorbox">
                      <span>{selectedWidgetSettings.backgroundColor}</span>
                      <input
                        type="color"
                        id="backgroundColor"
                        name="backgroundColor"
                        value={selectedWidgetSettings.backgroundColor}
                        onChange={handleOnChange}
                      />
                    </div>
                  </li>
                  <li>
                    <label htmlFor="buttonColor">Button color:</label>
                    <div className="colorbox">
                      <span>{selectedWidgetSettings.buttonColor}</span>
                      <input
                        type="color"
                        id="buttonColor"
                        name="buttonColor"
                        value={selectedWidgetSettings.buttonColor}
                        onChange={handleOnChange}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="w-6" style={{ padding: "10px" }}>
                <div className="company-box">
                  <div
                    className="companyHeader"
                    style={{
                      backgroundColor: `${selectedWidgetSettings.backgroundColor}`
                    }}
                  >
                    <div className="clogo">
                      <img src={dummyUser} alt="" />
                    </div>
                    <div className="cContent">
                      <h6>{selectedWidgetSettings.websiteName}</h6>
                      {selectedWidgetSettings.takeOperatorName === CHECKBOX_STATE.Yes ? (
                        <p>Operator Name</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="companyBody">
                    <button
                      style={{
                        backgroundColor: `${selectedWidgetSettings.buttonColor}`
                      }}
                    >
                      Becoming a Member
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="w-12">
              <div className="btngrps">
                <Button
                  color="green"
                  disabled={updateWidgetLoading || isWidgetSettingsLoading || companyUserLoading}
                  onClick={handleUpdateWidgetSettings}
                >
                  {updateWidgetLoading ? <Spinner color="white" size="lg" /> : "Update"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = {
  getWebChat,
  getUsersByCompany,
  getWebChatWidgetSettings,
  updateWidgetSettings
};
export default connect(null, mapDispatchToProps)(AccountWebchat);
