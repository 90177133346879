import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import "../Form.css";

import { LogoSvg } from "../../../assets/svg-icon/index";
import { Spinner } from "../../../components/Loader";
import { validateEmail } from "../../../utils/validateHelperUtils";
import { APP_ROUTES } from "../../../helpers/routesHelper";
import WithBodyBackground from "../WithBackground";
import { resetPassword } from "../../../store/actions";

const ForgetLogin = (props) => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [forgotPasswordStatus, setforgotPasswordStatus] = useState(false);
    const [toLogin, settoLogin] = useState(false);
    const dispatch = useDispatch();

    const handleLoginSubmit = (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setEmailError("Please enter valid email address");
            return;
        }
        setIsLoading(true);
        dispatch(resetPassword(email))
            .then((res) => {
                setIsLoading(false);
                setforgotPasswordStatus(true);
            })
            .catch((err) => {
                setEmailError("There's no user associated with this email");
                setIsLoading(false);
            });
    };

    const handleOnChange = (e) => {
        setEmailError(null);
        setEmail(e.target.value);
    };

    if (toLogin) {
        return <Redirect to={APP_ROUTES.ACCOUNT} />;
    }

    return (
        <form className="login-form" onSubmit={handleLoginSubmit}>
            <div className="login-logo">
                <img src={LogoSvg} className="img-fluid" alt="img" />
            </div>
            {!forgotPasswordStatus ? (
                <>
                    <div className="login-form">
                        <div className="form-group">
                            <h2>Need a reset?</h2>
                            <h5>{emailError ? emailError : `Enter your account email`}</h5>
                            <input
                                type="email"
                                className={`form-control ${emailError ? `red` : ``}`}
                                value={email}
                                required
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={isLoading}>
                                <span>Reset Password</span>
                                {isLoading ? <Spinner color="white" size="lg" /> : null}
                            </button>
                            <a href="/account/login">Return to login</a>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="login-form">
                        <div className="form-group">
                            <h2>Check your email</h2>
                            <h5>You will receive a link instructions on resetting the password.</h5>
                        </div>
                        <div className="form-group">
                            <button type="submit" onClick={() => settoLogin(true)}>
                                <span>Return to login</span>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </form>
    );
};

export default WithBodyBackground(ForgetLogin);
