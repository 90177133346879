import React, { useState, useEffect, useRef } from "react";
import "./AutomaionChatForm.scss";
import { MdOutlineAttachment } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Modal, Button, Form } from "react-bootstrap";
import { Radio, Space, Tabs, Spin, message } from "antd";
import { BiCheck } from "react-icons/bi";
import { useParams, useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form as FForm, ErrorMessage } from "formik";
import "react-app-polyfill/ie11";
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";

import Whenthemessageshouldpopup from "../../../assets/images/Whenthemessageshouldpop-up.svg";
import CollectingInformation from "../../../assets/images/CollectingInformation.svg";
import ChatBoxScreen from "../../chat-box-screen/ChatBoxScreen";
import DefaultButton from "../../../components/buttons/buttons";
import { APP_ROUTES, getRoute } from "../../../helpers/routesHelper";
import AttachModal from "../../../components/automation-modal/attachment-modal";
import AutoComplete from "../../../components/automation-modal/automation-complete";
import EditAutomationModal from "../../../components/automation-modal/editAutomationModal";
import InactiveModal from "../../../components/automation-modal/inactive-modal";
import SetLiveModal from "../../../components/automation-modal/SetLiveModal";
import SetAutomationLive from "../../../components/automation-modal/set-automation-live";
import PauseAutomation from "../../../components/automation-modal/pause-automation";
import { errorToaster } from "../../../utils/loggerUtils";

import {
    getAutomation,
    postAutomationMedia,
    putAutomation,
    createAutomation,
    updateAutomationStatusToPause,
    updateAutomationStatusToLive,
    addMedia,
    deleteMedia,
    editMedia,
    companyWidget,
    activeAutomationListing
} from "../../../store/actions/automationActions";
const AutomaionChatForm = ({ statusChanged, setStatusChanged }) => {
    const params = useParams();
    const formikRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const [automationParams, setAutomationParams] = useState({});
    const [websiteInfo, setWebsiteInfo] = useState({});
    const [justCreatedAutomationId, setJustCreatedAutomationId] = useState(null);
    const [isLoadingActive, setIsLoadingActive] = useState(false)
    const [activeListData, setActiveListData] = useState([])
    
    const currentCompanyId = location.pathname.split("/")[3]

    const truncateSlash = (url ="") => {
        let truncated = url.trim();
        if(truncated){
            if(truncated[truncated.length - 1] == "/"){
                truncated = truncated.slice(0, truncated.length - 1)
            }
        }
        return truncated;
    }

    const getTrimmedUrl = (url="") => {
        let trimmedUrl = url
        if(url.startsWith("https://")){
        trimmedUrl = url.replace("https://", "")
    }
    else if(url.startsWith("http://")){
        trimmedUrl = url.replace("http://", "")
    }
    return trimmedUrl
    }

    const getBaseUrl = (url="") => {
        let baseUrl = getTrimmedUrl(url)
        baseUrl = baseUrl.split("/")[0];
        return baseUrl
    }
    const getPathUrl = (fullUrl, baseUrl) => {
        fullUrl = getTrimmedUrl(fullUrl)
        baseUrl= getTrimmedUrl(baseUrl)
        let pathUrl = fullUrl.replace(baseUrl, "");
        if(pathUrl == fullUrl){
            pathUrl=""
        }
        return pathUrl;
    }

    useEffect(() => {
        console.log("activeListData", activeListData)
    }, [activeListData])

    useEffect(()=>{
        setDescription(`It's a beautiful day at ${websiteInfo.websiteName}, how can I assist you today?`)
    }, [websiteInfo])

    useEffect(() => {
        setAutomationParams(params);
        if(location?.state?.inCompleted){
            formikRef?.current?.setValues({
                PageUrl: location?.state?.pageUrl,
                Name: "Welcome Message",
            });
            setWebsiteInfo({
                websiteName: location?.state?.websiteName,
                brandedFooter: location?.state?.brandedFooter,
            })
        }

        setIsLoadingActive(true);
        dispatch(activeAutomationListing(currentCompanyId))
            .then((res) => {
                setActiveListData(res);
                setIsLoadingActive(false);
            })
            .catch((err) => {
                setIsLoadingActive(false);
            });

            
        return () => {
            formikRef?.current?.setValues({
                PageUrl: "",
                Name: "",
                IntroMessage: "",
                ChatTrigger: "",
                TakeUsersName: false,
                TakeUsersEmail: false,
                TakeUsersPhone: false,
                InformationAutoResponse: "",
                FormHeader: "",
                Media: ""
            });
            setUserFiles([]);
            setEditDisable(false);
            setDonotCheckbox(false);
        };

      
    }, [params?.id]);

    useEffect(() => {
        if (automationParams?.id) {
            setIsLoading(true);
            dispatch(getAutomation(automationParams?.id))
                .then((res) => {
                     //get base URL
                    let baseUrl = getBaseUrl(res?.automation?.PageUrl)
                    setFullPageUrl((res?.automation?.PageUrl));
                    setEditDisable(true);
                    // setautomationFormValue(res?.automation)
                    setautomationFormValue((prev)=>({...res?.automation, PageUrl: baseUrl+'/'}));
        
                    if (res.media.length > 0) {
                        setUserFiles(res.media); 
                    }

                    setCheckboxs({
                        TakeUsersName: Boolean(res.automation.TakeUsersName),
                        TakeUsersEmail: Boolean(res.automation.TakeUsersEmail),
                        TakeUsersPhone: Boolean(res.automation.TakeUsersPhone)
                    });
                    if(!res.automation.TakeUsersName && !res.automation.TakeUsersEmail && !res.automation.TakeUsersPhone) {
                        setDonotCheckbox(true) 
                    } 
                    if(res?.automation?.WebsiteId){
                        dispatch(companyWidget(res?.automation?.WebsiteId))
                        .then((res) => {
                            if (res.WebsiteName) {
                               setWebsiteInfo({
                                   websiteName: res?.WebsiteName,
                                   brandedFooter: res?.BrandedFooter,
                               })
                            }
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            console.log("errror", err)
                        });
                    }else{
                        setIsLoading(false);
                    }
                })
                .catch((err) => setIsLoading(false));
        }else if(!location?.state?.inCompleted && location?.state?.pageUrl){
            setautomationFormValue({});
            let baseUrl = getBaseUrl(location?.state?.pageUrl)
                    setFullPageUrl(location?.state?.pageUrl);
                    setautomationFormValue((prev)=>({PageUrl: baseUrl+'/'}));
            setWebsiteInfo({
                websiteName: location?.state?.websiteName,
                brandedFooter: location?.state?.brandedFooter,
            })
        } else {
            setautomationFormValue({});
        }
       
    }, [automationParams?.id]);

    const dispatch = useDispatch();

    const [fullPageUrl, setFullPageUrl] = useState("");
    const [automationformValue, setautomationFormValue] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMedia, setIsLoadingMedia] = useState(false);
    const [fileName, setFileName] = useState("");
    const [automationSaved, setAutomationSaved] = useState(false);
    const [editAutomationModal, setEditAutomationModal] = useState(false);
    const [compTheAutoSetup, setCompTheAutoSetup] = useState(false);
    const [inactiveModalSetup, setInactiveModalSetup] = useState(false);
    const [liveSetModalSetup, setLiveSetModalSetup] = useState(false);
    const [automationLive, setAutomationLive] = useState(false);
    const [pauseAutomation, setPauseAutomation] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFileEditModal, setShowFileEditModal] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [uploadFile, setUploadFile] = useState(null);
    const [userFiles, setUserFiles] = useState([]);
    const [editDisable, setEditDisable] = React.useState(automationParams?.id ? true : false);
    const [checkboxs, setCheckboxs] = useState({
        TakeUsersName: false,
        TakeUsersPhone: false,
        TakeUsersEmail: false
    });
    const [errAccordion1, setErrAccordion1] = useState(false);
    const [errAccordion2, setErrAccordion2] = useState(false);
    const [errInformationAutoResponse, setErrInformationAutoResponse] = useState(false);
    const [errFormHeader, setErrFormHeader] = useState(false);
    const [donotCheckbox, setDonotCheckbox] = useState(false);
    // const description =
    //     "It's a beautiful day at BraeBurn Country Club, how can I assist you today?";
    const [deletedAutomation, setDeletedAutomation] = useState([]);
    const [newAutomation, setNewAutomation] = useState([]);
    const [editFileName, setEditFileName] = useState({});
    const [description, setDescription] = useState("")
    const handleShow = () => setShowModal(true);
    const handleClose = () => {
        setShowModal(false);
        setUploadFile("");
        setFileName("");
    };
    const handleShowFileEditModal = (fileName, path) => {
        setShowFileEditModal(true);
        setEditFileName({name: fileName, path: path })
    }
    const handleCloseShowFileEditModal = () => {
        setShowFileEditModal(false)
    }
    const handleSaveEditFileName = () => {
        let userFilesCopy = JSON.parse(JSON.stringify(userFiles));
        userFilesCopy.map((item)=>{
        if(item.Path == editFileName.path){
            item.Name = editFileName.name;
        }
    })
    setUserFiles(userFilesCopy)
    
    let newAutomationCopy = JSON.parse(JSON.stringify(newAutomation));
    if(newAutomationCopy.length > 0){
    newAutomationCopy.map((item)=>{
        if(item.Path == editFileName.path){
            item.Name = editFileName.name;
        }
    })
    setNewAutomation(newAutomationCopy);
}

        setShowFileEditModal(false)
    }

    const canFileUploaded = (file) => {
        if(file){
        let totalBytes = file.size;
        let fileInMb = totalBytes / 1024 / 1024;
        if (fileInMb <= 50) {
            setUploadFile(file);
            return true;
        } else {
            message.error("The file size exceeds");
            setUploadFile("")
            return false;
        }
    }
    };

    const saveAndClose = (file, values) => {
        setShowModal(false);
        if (uploadFile) {
            setIsLoadingMedia(true);
            const body = new FormData();
            body.append("name", fileName);
            body.append("file", uploadFile);

            dispatch(postAutomationMedia(body))
                .then((res) => {
                    setFileName("");
                    setNewAutomation([...newAutomation, { Name: fileName, Path: res?.location }]);

                    let data = [...userFiles];
                    setUserFiles([{ Name: fileName, Path: res?.location }, ...data]);
                    setIsLoadingMedia(false);
                    setUploadFile("");
                })
                .catch((err) => setIsLoadingMedia(false));
        } else {
            let data = [...userFiles];
            setUserFiles([{ Name: fileName, Path: fileUrl }, ...data]);
            setNewAutomation([...newAutomation, { Name: fileName, Path: fileUrl }]);
            setFileName("");
            setFileUrl("");
        }
    };
    const { TabPane } = Tabs;

    // Yup formik Validation
    const AutomationSchema1 = Yup.object({
        // PageUrl: Yup.string().url("https://www.example.com").required("Please enter a valid URL"),
        PageUrl: Yup.string(),
        Name: Yup.string().required("Required").max(255),
        IntroMessage: Yup.string().required("Required").max(300),
        InformationAutoResponse: Yup.string().max(255),
        FormHeader: Yup.string().max(255),
    });
    const AutomationSchema2 = Yup.object({
        PageUrl: Yup.string(),
        Name: Yup.string().required("Required").max(255),
        IntroMessage: Yup.string().required("Required").max(300),
        InformationAutoResponse: Yup.string().max(255),
        FormHeader: Yup.string().max(255)
    });

    const renderError = (message) => <p className="erroe-message">{message}</p>;

    const handleRemoveUserFile = (item) => {
        if (automationParams?.id) {
            const findAutomation = userFiles.find((file) => file.Path === item.Path);
            if (findAutomation?.Id) {
                setDeletedAutomation([...deletedAutomation, parseInt(findAutomation.Id)]);
            }
        }
        const filteredList = userFiles.filter((file) => file.Path !== item.Path);
        const newfilteredList = newAutomation.filter((file) => file.Path !== item.Path);
        setNewAutomation(newfilteredList);
        setUserFiles(filteredList);
    };

    const checkBoxDonotHandler = (e) => {
        setDonotCheckbox(!donotCheckbox);
        setCheckboxs({ TakeUsersName: false, TakeUsersPhone: false, TakeUsersEmail: false });
        formikRef?.current?.setValues({
            ...formikRef.current.values,
            TakeUsersName: false,
            TakeUsersEmail: false,
            TakeUsersPhone: false,
        });
        setErrFormHeader(false);
        setErrInformationAutoResponse(false);
        setErrAccordion2(false);
    };

    const setLivefunc = () => {
        try {
            if (automationParams?.id) {
                dispatch(updateAutomationStatusToLive(automationParams.id)).then(() => {
                    setStatusChanged({
                        id: automationParams?.cId,
                        status: !statusChanged.status
                    });
                    setLiveSetModalSetup(true);
                });
            } else {
                if(justCreatedAutomationId){
                    dispatch(updateAutomationStatusToLive(justCreatedAutomationId)).then(() => {
                        setStatusChanged({
                            id: automationParams?.cId,
                            status: true
                        });
                        setLiveSetModalSetup(true);
                    });
                }
            }
        } catch (err) {}
    };

    const setPausefunc = () => {
        try {
            if (automationParams?.id) {
                dispatch(updateAutomationStatusToPause(automationParams.id)).then(() => {
                    setStatusChanged({
                        id: automationParams?.cId,
                        status: !statusChanged.status
                    });
                    setInactiveModalSetup(true);
                });
            } else {
            }
        } catch (err) {}
    };

    const renderErrorMsg = (errors, touched, field) => {
        if (errors[field] && touched[field]) {
            return <p className="required-error">{errors[field]}</p>;
        } else {
            return null;
        }
    };
    function isValidURL(string) {
        var res = string.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );

        return res !== null;
    }

    const CheckAccordion = (values) => {
        if (values?.ChatTrigger.length == "") {
            setErrAccordion1(true);
        }
        if(values?.FormHeader == "" && !donotCheckbox){
            setErrFormHeader(true);
            setErrAccordion2(true);
        }
        if (values?.InformationAutoResponse == "" && !donotCheckbox) {
            setErrInformationAutoResponse(true);
            setErrAccordion2(true);
        }
        if((values?.InformationAutoResponse == "" || values?.FormHeader == "") && !donotCheckbox){
            return true;
        }
         else {
            return false;
        }
    };

    const renderInfoErr = (errors) => {
        setErrAccordion2(true);
        return <p className="required-error">{errors.InformationAutoResponse}</p>;
    };

    return (
        <>
            <section className="awm-chat-form">
                <div className="container">
                    {!isLoading ? (
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                                PageUrl: getPathUrl(fullPageUrl, automationformValue?.PageUrl) || "",
                                Name: (location?.state?.inCompleted || automationformValue.IsWelcome || activeListData?.length===0) ? "Welcome Message" : automationformValue?.Name || "",
                                IntroMessage: automationformValue?.IntroMessage || "",
                                ChatTrigger: automationformValue?.ChatTrigger || "",
                                TakeUsersName: checkboxs.TakeUsersName,
                                TakeUsersEmail: checkboxs.TakeUsersEmail,
                                TakeUsersPhone: checkboxs.TakeUsersPhone,
                                InformationAutoResponse:
                                    automationformValue?.InformationAutoResponse || "",
                                FormHeader:
                                    automationformValue?.FormHeader || "",
                                ...(userFiles.length > 0 && { Media: [...userFiles] })
                            }}
                            validationSchema={
                                automationParams?.id ? AutomationSchema2 : AutomationSchema1
                            }
                            onSubmit={async (values) => {
                                CheckAccordion(values);
                                if (
                                    !errAccordion1 &&
                                    !errAccordion2 &&
                                    !CheckAccordion(values) &&
                                    !isLoadingMedia
                                ) {
                                    try {
                                        // if (!userFiles.length) {
                                        //     setCompTheAutoSetup(true);
                                        // } else {
                                            if (automationParams?.id) {
                                                if (deletedAutomation.length > 0) {
                                                    await dispatch(
                                                        deleteMedia({ MediaIds: deletedAutomation })
                                                    );
                                                }

                                                if (newAutomation.length > 0) {
                                                    if(userFiles.length){
                                                    await dispatch(
                                                        addMedia({
                                                            AutomationId: parseInt(
                                                                automationParams?.id
                                                            ),
                                                            Media: newAutomation
                                                        })
                                                    );
                                                    }
                                                }
                                                if(userFiles.length){
                                                    userFiles.map(async (item) => {
                                                        if(item.AutomationId){
                                                            await dispatch(
                                                                editMedia({
                                                                    MediaId: item.Id,
                                                                    Name: item.Name,
                                                                    Path: item.Path,
                                                                })
                                                            );
                                                        }
                                                    })
                                                }
                                                dispatch(
                                                    putAutomation(
                                                        {
                                                            ...values,
                                                            ...checkboxs,
                                                            WebsiteId: parseInt(
                                                                automationParams?.cId
                                                            ),
                                                            PageUrl: `${automationformValue?.PageUrl}${truncateSlash(values.PageUrl)}`,
                                                            IsWelcomeMessage: false
                                                        },
                                                        automationParams.id
                                                    )
                                                )
                                                    .then(() => {
                                                        setStatusChanged({
                                                            id: automationParams?.cId,
                                                            status: !statusChanged.status
                                                        });
                                                        setEditAutomationModal(true);
                                                    })
                                                    .catch((err) => {
                                                        errorToaster(err?.Errors)
                                                    });
                                            } else {
                                                dispatch(
                                                    createAutomation({
                                                        ...values,
                                                        Media: userFiles.map((file) => {
                                                            return {
                                                                Name: file.Name,
                                                                Path: file.Path
                                                            };
                                                        }),
                                                        ...checkboxs,
                                                        WebsiteId: parseInt(automationParams?.cId),
                                                        // PageUrl: `${automationformValue?.PageUrl}${truncateSlash(values.PageUrl)}`,
                                                        ...(location?.state?.inCompleted
                                                            ? { IsWelcomeMessage: true }
                                                            : { IsWelcomeMessage: false }),
                                                        ...(location?.state?.inCompleted
                                                             ? {PageUrl: `${location?.state?.pageUrl}`}
                                                             : {PageUrl: `${automationformValue?.PageUrl}${truncateSlash(values.PageUrl)}`})
                                                    })
                                                )
                                                    .then((data) => {
                                                        setStatusChanged({
                                                            id: automationParams?.cId,
                                                            // status: !statusChanged.status
                                                            status: false
                                                        });
                                                        setAutomationSaved(true);
                                                        setJustCreatedAutomationId(data?.Id)
                                                    })
                                                    .catch((err) => {
                                                        errorToaster(err?.Errors)
                                                    });
                                            }
                                        //}
                                    } catch (err) {}
                                }
                            }}
                        >
                            {(formikProps) => {
                                const {
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    errors,
                                    touched,
                                    values,
                                } = formikProps;

                                return (
                                    <FForm onSubmit={handleSubmit} className="main-form">
                                        <div className="row">
                                            <div className="col-xl-7 col-lg-7 col-md-12">
                                                <div
                                                    className={`formSide ${
                                                        editDisable ? "disable" : ""
                                                    }`}
                                                >
                                                    <div>
                                                        <h5>Name</h5>
                                                        <div className="msg-display">
                                                            <h3>
                                                                <Field
                                                                    name="Name"
                                                                    type="text"
                                                                    className={
                                                                        errors.Name && touched.Name
                                                                            ? "invalid CompNameInput"
                                                                            : "CompNameInput"
                                                                    }
                                                                    placeholder="Welcome Message"
                                                                    value={values.Name}
                                                                    onChange={handleChange("Name")}
                                                                    disabled={location?.state?.inCompleted || automationformValue.IsWelcome || activeListData?.length===0}
                                                                />
                                                                {renderErrorMsg(
                                                                    errors,
                                                                    touched,
                                                                    "Name"
                                                                )}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div className="form-head">
                                                        <p className="t-bold">
                                                            {location?.state?.inCompleted || automationformValue.IsWelcome || activeListData?.length===0
                                                                 ? "The Welcome message will appear on all website pages"
                                                                 : "Automation will override the Welcome Message for this page only"}
                                                        </p>
                                                        <div className="msg-display">
                                                            <span
                                                                className={
                                                                    errors.PageUrl &&
                                                                    touched.PageUrl
                                                                        ? "invalid pageURLBox pageURLBox"
                                                                        : "pageURLBox"
                                                                }
                                                            >
                                                                <span>
                                                                    {automationformValue?.PageUrl
                                                                        ? `${automationformValue?.PageUrl}`
                                                                        : ""}
                                                                </span>
                                                                <Field
                                                                    name="PageUrl"
                                                                    // type="url"
                                                                    placeholder={location?.state?.inCompleted || automationformValue.IsWelcome || activeListData?.length===0 ? "" : "Page URL"}
                                                                    value={
                                                                        !automationformValue?.automation
                                                                            ? values.PageUrl
                                                                            : ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        handleChange(e, "PageUrl");
                                                                    }}
                                                                    disabled={location?.state?.inCompleted || automationformValue.IsWelcome || activeListData?.length===0}
                                                                />
                                                            </span>

                                                            {/* todo */}

                                                            {renderErrorMsg(
                                                                errors,
                                                                touched,
                                                                "PageUrl"
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="form-body">
                                                        <div className="form-intro">
                                                            <p className="t-bold">Intro message</p>
                                                            <p className="t-thin LDA-font">
                                                                Under 300 characters are best
                                                            </p>
                                                        </div>
                                                        <div className="form-fields">
                                                            <div className="msg-display">
                                                                <ErrorMessage
                                                                    name="textareainput"
                                                                    render={renderError}
                                                                />
                                                                <Field
                                                                    className={
                                                                        errors.IntroMessage &&
                                                                        touched.IntroMessage
                                                                            ? "invalid automation-textarea"
                                                                            : "automation-textarea"
                                                                    }
                                                                    name="IntroMessage"
                                                                    as="textarea"
                                                                    placeholder={description}
                                                                    value={
                                                                        !automationformValue?.automation
                                                                            ? values.IntroMessage
                                                                            : ""
                                                                    }
                                                                    onChange={handleChange(
                                                                        "IntroMessage"
                                                                    )}
                                                                />

                                                                {renderErrorMsg(
                                                                    errors,
                                                                    touched,
                                                                    "IntroMessage"
                                                                )}
                                                            </div>
                                                        </div>

                                                        <Accordion defaultActiveKey="0" flush>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header className="attached-icon t-bold">
                                                                    <MdOutlineAttachment />
                                                                    Attachments
                                                                </Accordion.Header>
                                                                <Accordion.Body className="add-help-file-modal">
                                                                    {userFiles.length < 3 ? (
                                                                        <Button
                                                                            variant="add-new"
                                                                            onClick={handleShow}
                                                                            disabled={
                                                                                isLoadingMedia
                                                                            }
                                                                        >
                                                                            Add new
                                                                        </Button>
                                                                    ) : null}
                                                                    <div class="quick-msg">
                                                                        {!isLoadingMedia ? (
                                                                            userFiles.map(
                                                                                (item) => (
                                                                                    <>
                                                                                        <span className=" q-bubble LDA-max-width ">
                                                                                            <span className="  multiline-truncate">
                                                                                                <strong
                                                                                                    title={
                                                                                                        item.Name
                                                                                                    }
                                                                                                    onClick={() => {handleShowFileEditModal(item.Name, item.Path)}}
                                                                                                >
                                                                                                    {
                                                                                                        item.Name
                                                                                                    }
                                                                                                </strong>
                                                                                            </span>
                                                                                            <span className="ms-1">
                                                                                                <ImCross
                                                                                                    onClick={() =>
                                                                                                        handleRemoveUserFile(
                                                                                                            item
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </span>
                                                                                    </>
                                                                                )
                                                                            )
                                                                        ) : (
                                                                            <Spin />
                                                                        )}
                                                                    </div>
                                                                    {
                                                                        showFileEditModal && (
                                                                            <Modal
                                                                            show={showFileEditModal}
                                                                            onHide={handleCloseShowFileEditModal}
                                                                            className="add-help-file"
                                                                            aria-labelledby="contained-modal-title-vcenter"
                                                                            centered
                                                                        >
                                                                            <Modal.Header
                                                                                closeButton
                                                                            ></Modal.Header>
                                                                            <Modal.Body>
                                                                                <h3>
                                                                                    Edit File Name
                                                                                </h3>
                                                                                <form>
                                                                                    <div className="form-group">
                                                                                        <label style={{
                                                                                            color: "#748997",
                                                                                            fontFamily: "Montserrat",
                                                                                            fontSize: "12px",
                                                                                            fontWeight: "600",
                                                                                            letterSpacing: "0",
                                                                                            lineHeight: "13px",
                                                                                        }}>
                                                                                        Name your file
                                                                                        </label>
                                                                                        <Field
                                                                                         style={{fontSize: "14px"}}
                                                                                            name="fileName"
                                                                                            value = {editFileName.name}
                                                                                           
                                                                                            type="text"
                                                                                            onChange={(
                                                                                                evt
                                                                                            ) =>
                                                                                                setEditFileName((prevState)=>(
                                                                                                    {...prevState,
                                                                                                         name:
                                                                                                    evt
                                                                                                        .target
                                                                                                        .value

                                                                                                    }
                                                                                                )
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    
                                                                                </form>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <DefaultButton
                                                                                    _class={`d-btn ${      
                                                                                        editFileName?.name?.trim()
                                                                                            ? ""
                                                                                            : "disable"
                                                                                    }       
                                                                                    `}
                                                                                    type="primary"
                                                                                    variant="secondary"
                                                                                    onClick={() =>
                                                                                        handleSaveEditFileName()
                                                                                    }
                                                                                    title={
                                                                                        "Save and close"
                                                                                    }
                                                                                />
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                        )
                                                                    }
                                                                    {showModal && (
                                                                        <Modal
                                                                            show={showModal}
                                                                            onHide={handleClose}
                                                                            className="add-help-file"
                                                                            aria-labelledby="contained-modal-title-vcenter"
                                                                            centered
                                                                        >
                                                                            <Modal.Header
                                                                                closeButton
                                                                            ></Modal.Header>
                                                                            <Modal.Body>
                                                                                <h3>
                                                                                    Add Help Files
                                                                                </h3>
                                                                                <form>
                                                                                    <div className="form-group">
                                                                                        <label style={{
                                                                                            color: "#748997",
                                                                                            fontFamily: "Montserrat",
                                                                                            fontSize: "12px",
                                                                                            fontWeight: "600",
                                                                                            letterSpacing: "0",
                                                                                            lineHeight: "13px",
                                                                                        }}>
                                                                                        Name your file
                                                                                        </label>
                                                                                        <Field
                                                                                        style={{fontSize: "14px"}}
                                                                                            name="fileName"
                                                                                            value={
                                                                                                fileName
                                                                                            }
                                                                                            type="text"
                                                                                            maxLength={30}
                                                                                            onChange={(
                                                                                                evt
                                                                                            ) => {
                                                                                                    setFileName(
                                                                                                        evt
                                                                                                            .target
                                                                                                            .value
                                                                                                    )
                                                                                            }  
                                                                                            }
                                                                                        />
                                                                                         <label style={{color:"#a7b9c5", fontSize: "11px", display: "flex", justifyContent: "flex-end"}}>
                                                                                           {fileName.length}/30 Characters
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-group for-tabs-url">
                                                                                        <Tabs
                                                                                            defaultActiveKey="1"
                                                                                            onChange={() => {
                                                                                                setFileUrl(
                                                                                                    ""
                                                                                                );
                                                                                                setUploadFile(
                                                                                                    ""
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <TabPane
                                                                                                tab="Upload File"
                                                                                                key="1"
                                                                                            >
                                                                                                <Field
                                                                                                    name="Media.Path"
                                                                                                    type="file"
                                                                                                    onChange={(
                                                                                                        evt
                                                                                                    ) => {
                                                                                                        canFileUploaded(
                                                                                                            evt
                                                                                                                .target
                                                                                                                .files[0]
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <label style={{color:"#a7b9c5", fontSize: "11px"}}>
                                                                                           Max. file size is 50mb
                                                                                        </label>
                                                                                            </TabPane>
                                                                                            <TabPane
                                                                                                tab="Paste URL"
                                                                                                key="2"
                                                                                            >
                                                                                                <Field
                                                                                                    name="MediaUrl"
                                                                                                    className={
                                                                                                        fileUrl &&
                                                                                                        !isValidURL(
                                                                                                            fileUrl
                                                                                                        )
                                                                                                            ? "wc-border-outline"
                                                                                                            : ""
                                                                                                    }
                                                                                                    type="text"
                                                                                                    value={
                                                                                                        fileUrl
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        setFileUrl(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </TabPane>
                                                                                        </Tabs>
                                                                                    </div>
                                                                                </form>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <DefaultButton
                                                                                    _class={`d-btn ${
                                                                                        (uploadFile ||
                                                                                            (fileUrl &&
                                                                                                isValidURL(
                                                                                                    fileUrl
                                                                                                ))) &&
                                                                                        fileName.trim()
                                                                                            ? ""
                                                                                            : "disable"
                                                                                    }`}
                                                                                    type="primary"
                                                                                    variant="secondary"
                                                                                    onClick={(e) =>
                                                                                        saveAndClose(
                                                                                            e,
                                                                                            values
                                                                                        )
                                                                                    }
                                                                                    title={
                                                                                        "Save and close"
                                                                                    }
                                                                                />
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    )}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item
                                                                eventKey="1"
                                                                className={
                                                                    errAccordion1
                                                                        ? " wc-border-outline"
                                                                        : " "
                                                                }
                                                            >
                                                                <Accordion.Header class="t-bold">
                                                                    <img
                                                                        src={
                                                                            Whenthemessageshouldpopup
                                                                        }
                                                                        alt="MSG icon"
                                                                    />{" "}
                                                                    When the message should pop-up?
                                                                </Accordion.Header>
                                                                <Accordion.Body className="t-thin">
                                                                    <Form>
                                                                        <small>
                                                                            Note: Message will show up in case visitor clicks the chat icon.{" "}
                                                                        </small>
                                                                        <Radio.Group
                                                                            onChange={(evt) => {
                                                                                setFieldValue(
                                                                                    "ChatTrigger",
                                                                                    evt.target.value
                                                                                );
                                                                                setErrAccordion1(
                                                                                    false
                                                                                );
                                                                            }}
                                                                            value={
                                                                                values.ChatTrigger
                                                                            }
                                                                        >
                                                                            <Space
                                                                                name="ChatTrigger"
                                                                                direction="vertical"
                                                                            >
                                                                                <Radio value="OnLeaveWindow">
                                                                                    On leave intent
                                                                                    (event triggers
                                                                                    when user's
                                                                                    mouse leaves
                                                                                    website tab)
                                                                                </Radio>
                                                                                <Radio value="15SecDelay">
                                                                                    After 15 second
                                                                                    delay
                                                                                </Radio>
                                                                                {/* <Radio value="OnClickChat">
                                                                                    When someone at
                                                                                    the club is
                                                                                    online
                                                                                </Radio> */}
                                                                            </Space>
                                                                        </Radio.Group>
                                                                    </Form>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            <div>
                                                                <Accordion.Item
                                                                    eventKey="2"
                                                                    className={
                                                                        errAccordion2
                                                                            ? " wc-border-outline"
                                                                            : " "
                                                                    }
                                                                >
                                                                    <Accordion.Header className="t-bold">
                                                                        <img
                                                                            src={
                                                                                CollectingInformation
                                                                            }
                                                                            alt="Collecting Information"
                                                                        />{" "}
                                                                        Collecting Information
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <div className="">
                                                                            <div className="ah_note">
                                                                                {" "}
                                                                                <small>
                                                                                    Choose what to
                                                                                    collect, write a
                                                                                    custom message
                                                                                    automacally sent
                                                                                    when the form is
                                                                                    completed.{" "}
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                        <form>
                                                                            <div
                                                                                className={
                                                                                    errors.IntroMessage &&
                                                                                    touched.IntroMessage
                                                                                        ? "invalid ant-checkbox-group"
                                                                                        : "ant-checkbox-group"
                                                                                }
                                                                                role="group"
                                                                                aria-labelledby="my-radio-group"
                                                                            >
                                                                                <label>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="TakeUsersName"
                                                                                        disabled={
                                                                                            donotCheckbox
                                                                                        }
                                                                                        checked={
                                                                                            values.TakeUsersName
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            handleChange(
                                                                                                e
                                                                                            );
                                                                                            setCheckboxs(
                                                                                                {
                                                                                                    ...checkboxs,
                                                                                                    TakeUsersName:
                                                                                                        !checkboxs.TakeUsersName
                                                                                                }
                                                                                            );

                                                                                            setErrAccordion2(
                                                                                                false
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    Name
                                                                                </label>
                                                                                <label>
                                                                                    <Field
                                                                                        type="checkbox"
                                                                                        name="TakeUsersPhone"
                                                                                        disabled={
                                                                                            donotCheckbox
                                                                                        }
                                                                                        checked={
                                                                                            values.TakeUsersPhone
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            handleChange(
                                                                                                e
                                                                                            );
                                                                                            setCheckboxs(
                                                                                                {
                                                                                                    ...checkboxs,
                                                                                                    TakeUsersPhone:
                                                                                                        !checkboxs.TakeUsersPhone
                                                                                                }
                                                                                            );
                                                                                            setErrAccordion2(
                                                                                                false
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    Phone
                                                                                </label>
                                                                                <label>
                                                                                    <Field
                                                                                        type="checkbox"
                                                                                        name="TakeUsersEmail"
                                                                                        disabled={
                                                                                            donotCheckbox
                                                                                        }
                                                                                        checked={
                                                                                            values.TakeUsersEmail
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            handleChange(
                                                                                                e
                                                                                            );
                                                                                            setCheckboxs(
                                                                                                {
                                                                                                    ...checkboxs,
                                                                                                    TakeUsersEmail:
                                                                                                        !checkboxs.TakeUsersEmail
                                                                                                }
                                                                                            );
                                                                                            setErrAccordion2(
                                                                                                false
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    Email
                                                                                </label>
                                                                                <label>
                                                                                    <Field
                                                                                        type="checkbox"
                                                                                        name="InformationCollection"
                                                                                        checked={
                                                                                            donotCheckbox
                                                                                        }
                                                                                        placeholder="Do not collect (not recommended)"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            checkBoxDonotHandler(
                                                                                                e
                                                                                            );

                                                                                            setErrAccordion2(
                                                                                                false
                                                                                            );
                                                                                        }}
                                                                                    />{" "}
                                                                                    Do not collect
                                                                                    (not
                                                                                    recommended)
                                                                                </label>
                                                                            </div>
                                                                            <lable>
                                                                                Autoresponse
                                                                            </lable>
                                                                            <div className="msg-display">
                                                                                <Field
                                                                                    className={`
                                                                                        ${errInformationAutoResponse ||
                                                                                        (errors.InformationAutoResponse &&
                                                                                            touched.InformationAutoResponse)
                                                                                            ? "invalid w-100"
                                                                                            : "w-100"
                                                                                    } ${donotCheckbox ? "disabled": ""}`}
                                                                                    name="InformationAutoResponse"
                                                                                    as="textarea"
                                                                                    value={
                                                                                        values.InformationAutoResponse
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        handleChange(
                                                                                            e,
                                                                                            "InformationAutoResponse"
                                                                                        );
                                                                                        setErrInformationAutoResponse(
                                                                                            false
                                                                                        );
                                                                                        setErrAccordion2(
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                {errors.InformationAutoResponse &&
                                                                                touched.InformationAutoResponse
                                                                                    ? renderInfoErr(
                                                                                          errors
                                                                                      )
                                                                                    : !errInformationAutoResponse
                                                                                    ? setErrAccordion2(
                                                                                          false
                                                                                      )
                                                                                    : null}
                                                                                {errInformationAutoResponse ? (
                                                                                    <p className="required-error">
                                                                                        Required
                                                                                    </p>
                                                                                ) : null}
                                                                            </div>
                                                                            <lable style={{marginTop:"10px"}}>
                                                                                Form Header
                                                                            </lable>
                                                                            <div className="msg-display">
                                                                                <Field
                                                                                    className={`${
                                                                                        errFormHeader ||
                                                                                        (errors.FormHeader &&
                                                                                            touched.FormHeader)
                                                                                            ? "invalid w-100"
                                                                                            : "w-100"
                                                                                        } ${donotCheckbox ? "disabled": ""}`}
                                                                                    name="FormHeader"
                                                                                    as="textarea"
                                                                                    value={
                                                                                        values.FormHeader
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        handleChange(
                                                                                            e,
                                                                                            "FormHeader"
                                                                                        );
                                                                                        setErrFormHeader(
                                                                                            false
                                                                                        );
                                                                                        setErrAccordion2(
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                {errors.FormHeader &&
                                                                                touched.FormHeader
                                                                                    ? renderInfoErr(
                                                                                          errors
                                                                                      )
                                                                                    : !errFormHeader
                                                                                    ? setErrAccordion2(
                                                                                          false
                                                                                      )
                                                                                    : null}
                                                                                {errFormHeader ? (
                                                                                    <p className="required-error">
                                                                                        Required
                                                                                    </p>
                                                                                ) : null}
                                                                            </div>
                                                                        </form>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </div>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-12">
                                                <div className="cbt-buttons">
                                                    {!automationParams?.id ? (
                                                        <>
                                                            <button
                                                                className={"d-btn"}
                                                                type="submit"
                                                            >
                                                                Save and Close
                                                            </button>
                                                            <button
                                                                className={"d-btn btndisableColor"}
                                                                type="button"
                                                                onClick={history.goBack}
                                                            >
                                                                Finish Later
                                                            </button>
                                                        </>
                                                    ) : automationformValue?.Status === 0 ? (
                                                        editDisable ? (
                                                            <DefaultButton
                                                                _class={
                                                                    "d-btn btndisableColor LDA-flex"
                                                                }
                                                                title={"Set Live"}
                                                                icon={<AiFillPlayCircle />}
                                                                onClick={() =>
                                                                    setAutomationLive(true)
                                                                }
                                                            />
                                                        ) : (
                                                            <DefaultButton
                                                                _class={"d-btn btndisableColor "}
                                                                title={"Cancel"}
                                                                onClick={history.goBack}
                                                            />
                                                        )
                                                    ) : editDisable ? (
                                                        <DefaultButton
                                                            _class={
                                                                "d-btn btndisableColor LDA-flex"
                                                            }
                                                            title={"Pause"}
                                                            icon={<AiFillPauseCircle />}
                                                            onClick={() => {
                                                                setPauseAutomation(true);
                                                            }}
                                                        />
                                                    ) : (
                                                        <DefaultButton
                                                            _class={"d-btn btndisableColor "}
                                                            title={"Cancel"}
                                                            onClick={history.goBack}
                                                        />
                                                    )}

                                                    {automationParams.id && (
                                                        <DefaultButton
                                                            _class={"d-btn"}
                                                            variant="secondary"
                                                            onClick={(values) => {
                                                                if (editDisable) {
                                                                    setEditDisable(false);
                                                                } else { 
                                                                    handleSubmit(values);
                                                                }
                                                            }}
                                                            title={
                                                                editDisable
                                                                    ? "Edit"
                                                                    : "Save and close"
                                                            }
                                                        />
                                                    )}
                                                    {compTheAutoSetup && (
                                                        <AttachModal
                                                            show={() => setCompTheAutoSetup(true)}
                                                            hide={() => setCompTheAutoSetup(false)}
                                                            classchat={"as-complete-modal"}
                                                            title={"Complete the automation set up"}
                                                            text={
                                                                "You did not add attachments to the welcome message, are you sure you don’t want to? You can always edit it later."
                                                            }
                                                            btntext1={"Save and close"}
                                                            btnattach={"Add attachments"}
                                                            onClickAtMdl={() => {
                                                                handleShow();
                                                                setCompTheAutoSetup(false);
                                                            }}
                                                        />
                                                    )}
                                                    {automationLive && (
                                                        <SetAutomationLive
                                                            show={() => setAutomationLive(true)}
                                                            hide={() => setAutomationLive(false)}
                                                            classchat={"as-complete-modal "}
                                                            title={"Set Automation live"}
                                                            text={
                                                                "Confirming will set the automation live if the widget is properly installed on the website."
                                                            }
                                                            btntext2={"Never mind"}
                                                            btntext1={"Set Automation Live"}
                                                            onClickSAL={() => {
                                                                setLivefunc();
                                                                setAutomationLive(false);
                                                            }}
                                                        />
                                                    )}
                                                    {pauseAutomation && (
                                                        <PauseAutomation
                                                            show={() => setPauseAutomation(true)}
                                                            hide={() => setPauseAutomation(false)}
                                                            classchat={"as-complete-modal"}
                                                            title={"Pause Automation"}
                                                            text={
                                                                "Automation will be moved to Inactive tab, you can set it live again anytime. Are you sure you want to pause it?"
                                                            }
                                                            btntext2={"Never mind"}
                                                            btntext1={"Pause Automation"}
                                                            onClickPA={() => {
                                                                setPausefunc();
                                                                setPauseAutomation(false);
                                                            }}
                                                        />
                                                    )}
                                                    {automationSaved && (
                                                        <AutoComplete
                                                            show={() => setAutomationSaved(true)}
                                                            hide={() => setAutomationSaved(false)}
                                                            classcomp={
                                                                "as-complete-modal auto-comp-modal"
                                                            }
                                                            icon={<BiCheck />}
                                                            text={location?.state?.inCompleted 
                                                                    ? "Automation is saved. Now, make sure the widget is installed on your website." 
                                                                    : "Automation is saved. Now, make sure the widget is installed on your website. Automation is currently inactive. "
                                                                
                                                            }
                                                            text2={!location?.state?.inCompleted ? "Please set the automation live." : ""}
                                                            btntext1={"close"}
                                                            route={
                                                                location?.state?.inCompleted
                                                                    ? getRoute(
                                                                          APP_ROUTES.ACTIVE_AUTOMATION,
                                                                          { cId: params?.cId }
                                                                      )
                                                                    : getRoute(
                                                                          APP_ROUTES.IN_ACTIVE_AUTOMATION,
                                                                          { cId: params?.cId }
                                                                      )
                                                            }
                                                            onSetLive={() => {
                                                                setLivefunc()
                                                            }}
                                                        />
                                                    )}
                                                    {editAutomationModal && (
                                                        <EditAutomationModal
                                                            show={() =>
                                                                setEditAutomationModal(true)
                                                            }
                                                            hide={() =>
                                                                setEditAutomationModal(false)
                                                            }
                                                            classcomp={
                                                                "as-complete-modal auto-comp-modal"
                                                            }
                                                            icon={<BiCheck />}
                                                            text={
                                                                "Automation " +
                                                                values.Name +
                                                                " is updated."
                                                            }
                                                            btntext1={"close"}
                                                            route={
                                                                params?.status == APP_ROUTES.ACTIVE
                                                                    ? getRoute(
                                                                          APP_ROUTES.ACTIVE_AUTOMATION,
                                                                          { cId: params?.cId }
                                                                      )
                                                                    : getRoute(
                                                                          APP_ROUTES.IN_ACTIVE_AUTOMATION,
                                                                          { cId: params?.cId }
                                                                      )
                                                            }
                                                        />
                                                    )}
                                                    {inactiveModalSetup && (
                                                        <InactiveModal
                                                            show={() => setInactiveModalSetup(true)}
                                                            hide={() =>
                                                                setInactiveModalSetup(false)
                                                            }
                                                            classcomp={
                                                                "as-complete-modal auto-comp-modal"
                                                            }
                                                            icon={<BiCheck />}
                                                            text={
                                                                "Automation was moved to inactive."
                                                            }
                                                            btntext1={"close"}
                                                            route={getRoute(
                                                                APP_ROUTES.IN_ACTIVE_AUTOMATION,
                                                                { cId: params?.cId }
                                                            )}
                                                        />
                                                    )}
                                                    {liveSetModalSetup && (
                                                        <SetLiveModal
                                                            show={() => setLiveSetModalSetup(true)}
                                                            hide={() => setLiveSetModalSetup(false)}
                                                            classcomp={
                                                                "as-complete-modal auto-comp-modal"
                                                            }
                                                            icon={<BiCheck />}
                                                            text={"Automation was moved to Live."}
                                                            btntext1={"close"}
                                                            route={getRoute(
                                                                APP_ROUTES.ACTIVE_AUTOMATION,
                                                                { cId: params?.cId }
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                                <ChatBoxScreen
                                                    description={
                                                        values.IntroMessage
                                                            ? values.IntroMessage
                                                            : description
                                                    }
                                                    userFiles={userFiles}
                                                    websiteInfo={websiteInfo}
                                                />
                                            </div>
                                        </div>
                                    </FForm>
                                );
                            }}
                        </Formik>
                    ) : (
                        <div className="loading">
                            <Spin />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default AutomaionChatForm;
