import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import Config from "../../Config";

const WidgInst = ({ postData }) => {
    const [copiedState, setCopiedState] = useState(false);
    const handleCopyToClipboard = () => {
        let codeSnippet = `<script type="text/javascript">
        (function(scope, doc, tagName, src, objectName, newElement, firstElement) {
        Array.isArray(scope["LongDriveAgency"]) ?
        scope["LongDriveAgency"].push(objectName) :
        (scope["LongDriveAgency"] = [objectName]);
        scope[objectName] =
        scope[objectName] ||
        function() {
        scope[objectName].queries = scope[objectName].queries || [];
        scope[objectName].queries.push(arguments);
        };
        scope[objectName].scriptInjectedAt = 1 * new Date();
        newElement = doc.createElement(tagName);
        newElement.setAttribute("id", "long-drive-agency-widget-script");
        firstElement = doc.getElementsByTagName(tagName)[0];
        newElement.async = 1;
        newElement.src = src;
        firstElement
        ?
        firstElement.parentNode.insertBefore(newElement, firstElement) :
        doc.getElementsByTagName("head")[0].appendChild(newElement);
        })(window, document, "script", "${Config.env().WEBCHAT_URL_DOMAIN}longdriveagency.js?v=1414556766776095155192326&api_key=${postData.ApiKey}", "longDriveAgency");
        longDriveAgency("Init", "${postData.ApiKey}");
        </script>`;
        navigator.clipboard.writeText(codeSnippet).then(() => {
            setCopiedState(true);
        });
    };
    return (
        <div className="widget-install-sec">
            <h6>Basic Javascript</h6>
            <p>{`To enable Webchat, add the following code before the closing </body> tag of every page you want it to appear.`}</p>
            <div className="copy-code-sec">
                <pre>
                    <code>
                        &lt;script type="text/javascript"&gt;
                        <br />
                        (function(scope, doc, tagName, src, objectName, newElement, firstElement){" "}
                        {"{"}
                        <br />
                        Array.isArray(scope["LongDriveAgency"]) ?<br />
                        scope["LongDriveAgency"].push(objectName) :<br />
                        (scope["LongDriveAgency"] = [objectName]);
                        <br />
                        scope[objectName] =<br />
                        scope[objectName] ||
                        <br />
                        function() {"{"}
                        <br />
                        scope[objectName].queries = scope[objectName].queries || [];
                        <br />
                        scope[objectName].queries.push(arguments);
                        <br />
                        {"}"};<br />
                        scope[objectName].scriptInjectedAt = 1 * new Date();
                        <br />
                        newElement = doc.createElement(tagName);
                        <br />
                        newElement.setAttribute("id", "long-drive-agency-widget-script");
                        <br />
                        firstElement = doc.getElementsByTagName(tagName)[0];
                        <br />
                        newElement.async = 1;
                        <br />
                        newElement.src = src;
                        <br />
                        firstElement
                        <br />
                        ?<br />
                        firstElement.parentNode.insertBefore(newElement, firstElement) :<br />
                        doc.getElementsByTagName("head")[0].appendChild(newElement);
                        <br />
                        {"}"})(window, document, "script",
                        {`"${Config.env().WEBCHAT_URL_DOMAIN}longdriveagency.js?v=1414556766776095155192326`}
                        {"&"}api_key={postData.ApiKey}", "longDriveAgency");
                        <br />
                        longDriveAgency("Init", "{postData.ApiKey}");
                        <br />
                        &lt;/script&gt;
                    </code>
                </pre>
                <div className="copy-tooltip">
                    <button className="copy-code-sec-btn" onClick={handleCopyToClipboard}>
                        {"Copy Code"}
                    </button>
                    <span class="copy-tooltiptext">
                        {copiedState ? "Copied!" : "Copy to clipboard"}
                    </span>
                </div>
            </div>
            <p className="bottom-text">The widget won’t be visible until the Welcome Message has been set up</p>
        </div>
    );
};

const mapStateToProps = (state) => {
    const postData = state.automation.postData;
    return { postData };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WidgInst);
