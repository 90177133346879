import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { GlobalContext } from "../../context/global-context";
import { Formik, Field, Form } from "formik";
import Checkbox from "../../components/checkbox";
import { useHistory } from "react-router";
import { Button, message, Spin } from "antd";
import { APP_ROUTES, getRoute } from "../../helpers/routesHelper";
import {
  setCurrent,
  updateAssignedUsers,
  assignedUsers,
  getCompanyAgents
} from "../../store/actions/automationActions";

const AcessOther = ({ postData, setCurrent, user }) => {
  const ChatContext = useContext(GlobalContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [teamName, setTeamName] = useState([]);

  useEffect(() => {
    dispatch(getCompanyAgents())
      .then((res) => {
        setIsLoading(false);
        let users = res?.filter((item) => item.Id !== user?.Id);
        console.log("users", users);
        setTeamName(users);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  const { setCheckCacheTeam, setassignUsersRes } = ChatContext;

  const { Id } = postData;

  const handleSubmitForm = (value) => {
    // if (value.Users.length <= 0) {
    //   dispatch(updateAssignedUsers(Id))
    //     .then((res) => {
    //       setassignUsersRes({
    //         ...res,
    //         ...{ WebChatId: parseInt(Id) }
    //       });
    //       setCheckCacheTeam(true);
    //       history.push(getRoute(APP_ROUTES.WEB_CHAT, { cId: Id }));
    //     })
    //     .catch((err) => message.error(err.response.data.Message));
    // } else {
      dispatch(assignedUsers({ Users: [...value.Users, user?.Id], WebChatId: parseInt(Id) }))
        .then((res) => {
          setassignUsersRes({
            ...res,
            ...{ Users: [...value.Users, user?.Id], WebChatId: parseInt(Id) }
          });
          setCheckCacheTeam(true);
          history.push(getRoute(APP_ROUTES.WEB_CHAT, { cId: Id }));
        })
        .catch((err) => message.error(err.response.data.Message));
    // }
  };

  const getHelp = () => {
    try {
      window.Intercom("show");
      let parentUrl = window.location?.ancestorOrigins[0];
      if (parentUrl) {
        window.parent.postMessage({ Message: "OPEN_INTERCOM", Data: true }, parentUrl);
      }
    } catch (err) {
      console.log("intercom error", err);
    }
  };

  return (
    <div className="acces-other-sec">
      <h6>Webchat Team</h6>
      <Formik
        initialValues={{
          Users: []
        }}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {(formikUserProps) => {
          const { handleChange, handleSubmit, values } = formikUserProps;
          return !isLoading && teamName.length === 0 ? (
            <React.Fragment>
              <p>You are the only user under the account. Contact us to add more users.</p>
              <div className="steps-action">
                <Button
                  className="get-help-btn"
                  type="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => getHelp()}
                >
                  Get Help
                </Button>
                <Button type="primary" onClick={() => handleSubmit(values)}>
                  Done
                </Button>
              </div>
            </React.Fragment>
          ) : !isLoading && teamName.length ? (
            <React.Fragment>
              <p>Selected users will have full access to messages and edit all automations</p>
              <Form onSubmit={handleSubmit}>
                <div className="checkbox-group" role="group" aria-labelledby="checkbox-group">
                  {teamName.map((users, index) => {
                    return (
                      <label key={index}>
                        <Field type="checkbox" name="Users" value={String(users.Id)} />
                        {users.Name}
                      </label>
                    );
                  })}
                </div>
                <div className="steps-action">
                  <Button
                    className="get-help-btn"
                    type="primary"
                    style={{ margin: "0 8px" }}
                    onClick={() => getHelp()}
                  >
                    Get Help
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrent(0);

                      handleSubmit(values);
                    }}
                    // disabled={values.Users.length <= 0}
                  >
                    Done
                  </Button>
                </div>
              </Form>
            </React.Fragment>
          ) : (
            <div className="loading">
              <Spin />
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  const postData = state.automation.postData;
  return {
    postData,
    user: state.auth.user
  };
};
const mapDispatchToProps = { setCurrent };

export default connect(mapStateToProps, mapDispatchToProps)(AcessOther);
