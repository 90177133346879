import { useState, useEffect } from "react";
import api from "../Api/api";
import { store } from "../store";

export const WebChatHook = () => {
    const [companyData, setCompanyData] = useState({});
    const [checkCache, setCheckCache] = useState(false);
    const fetchNewApi = async () => {
        try {
            const response = await api.get("/get/user/company", {
                headers: { Authorization: store.getState().auth.token }
            });
            const data = response.data;
            setCompanyData(data);
        } catch (error) {}
    };
    useEffect(() => {
        if (checkCache) {
            //fetchNewApi();
            setCheckCache(false);
        } else {
            //fetchNewApi();
        }
    }, [checkCache, setCheckCache]);

    return { companyData, checkCache, setCheckCache };
};
