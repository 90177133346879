import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../Api/api";
import { store } from "../store";

export const AllInboxCHatHook = () => {
    const [allInboxData, setAllInboxData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedValue, setSelectedValue] = useState("");
    const [statusTab, setStatusTab] = useState("active");
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isDataAvailable, setIsDataAvailable] = useState(true);

    const history = useHistory();
    const params = useParams();

    const TabStatus = statusTab;

    const fetchNewApi = async () => {
        try {
            setIsLoading(true);
            const apiAllInboxData = await api.get(
                `/chats/?q=${search}&Status=${statusTab}&WebsiteId=${selectedValue}&Page=${currentPage}&Limit=5`,
                // const allInboxData = await api.get(`/chats/?Status=active`,
                { headers: { Authorization: store.getState().auth.token } }
            );
            // if (!params.id) {
            //     history.push(`/inbox/${params.tabname}/${apiAllInboxData?.data?.data?.List[0].Id}`);
            // }
            setIsLoading(false);

            // setAllInboxData(allInboxDataapi.data);
            // setAllInboxData(apiAllInboxData?.data.data.List);

            setAllInboxData((prev) => {
                if (currentPage === 1) {
                    return apiAllInboxData?.data?.data?.List || [];
                } else {
                    return [...prev, ...apiAllInboxData?.data?.data?.List];
                }
            });

            setIsDataAvailable(apiAllInboxData?.data.data.List.length == 5);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchNewApi();
    }, [selectedValue, search, statusTab, currentPage]);

    return {
        allInboxData,
        setAllInboxData,
        setSelectedValue,
        setSearch,
        search,
        isLoading,
        setIsLoading,
        setStatusTab,
        currentPage,
        setCurrentPage,
        isDataAvailable
    };
};
