import { useState, useEffect } from "react";
import api from "../Api/api";
import { store } from "../store";

export const AccessOtherHook = () => {
    const [teamName, setTeamName] = useState("");
    const [checkCacheTeam, setCheckCacheTeam] = useState(false);
    const fetchNewApi = async () => {
        try {
            const response = await api.get("/get/company/users", {
                headers: { Authorization: store.getState().auth.token }
            });
            const data = response.data;
            setTeamName(data);
        } catch (error) {}
    };
    useEffect(() => {
        if (checkCacheTeam) {
            //fetchNewApi();
            setCheckCacheTeam(false);
        } else {
            //fetchNewApi();
        }
    }, [checkCacheTeam, setCheckCacheTeam]);

    return { teamName, checkCacheTeam, setCheckCacheTeam };
};
