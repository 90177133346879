import { useState, useEffect } from "react";
import api from "../Api/api";
import { store } from "../store";

export const LoginUserApiHook = () => {
    const [loginUserData, setLoginUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchNewApi = async () => {
        try {
            setIsLoading(true);
            const getLoginUSerData = await api.get("/me", {
                headers: { Authorization: store.getState().auth.token }
            });
            setIsLoading(false);

            setLoginUserData(getLoginUSerData.data);
        } catch (arror) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        //fetchNewApi();
        // }
    }, []);

    return { loginUserData, setLoginUserData, isLoading, setIsLoading };
};
