import { combineReducers } from "redux";

import root from "./rootReducer";
import authReducer from "./authReducer";
import entityReducer from "./entityReducer";
import chatReducer from "./chatReducer";
import automationReducer from "./automationReducer";
import ssoAuthReducer from "./ssoAuthReducer";
import { SIGN_OUT } from "../actions";

const appReducer = combineReducers({
    auth: authReducer,
    root: root,
    entities: entityReducer,
    chat: chatReducer,
    automation: automationReducer,
    ssoAuth: ssoAuthReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
