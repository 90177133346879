import React from "react";
import { connect } from "react-redux";

// import AccountWebchat from "./AccountWebchat";
import { ApplicationTypes } from "../../../../../constants";
import AccountWebChat from "./AccountWebChat";

class AccountSettingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "webchat"
    };
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  changeTab = (type) => {
    this.setState({ ...this.state, currentTab: type });
  };
  handleCloseModal = () => {
    this.props.closeModal();
  };

  ShowWebChatTab = () => {
    return (
      this.props.user.Type === 3 &&
      this.props.user &&
      this.props.user.Apps &&
      this.props.user.Company &&
      Boolean(Object.keys(this.props.user.Apps.filter((item) => item.Id === 4)).length) &&
      this.props.user.Company.WebChatAccess === 1 &&
      this.props.user.ShowWebChatTab
    );
  };

  isClubChatAccessAccessAllowed = () => {
    let clubChat =
      this.props.user && this.props.user.Apps.find((app) => app.Id === ApplicationTypes.CLUB_CHAT);
    if (clubChat) {
      return true;
    }
    return false;
  };

  render() {
    console.log("user", this.props.user);
    return (
      <div className="chats-autoresponder-modal" onClick={(e) => this.stopPropagation(e)}>
        <div
          className="modal-content setting-modals webchataccess-container"
          style={{ maxHeight: "650px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-header">
            <h3>Settings</h3>
            <span onClick={() => this.handleCloseModal()} className="close">
              &times;
            </span>
          </div>
          <div className="setting-tab">
            <div className="row">
              {this.ShowWebChatTab() ? (
                <div
                  style={{ marginLeft: "13px" }}
                  className={`w-3 ${this.state.currentTab === "webchat" ? "active" : ""}`}
                  onClick={() => this.changeTab("webchat")}
                >
                  <div className="tab-heading">
                    <h4>WebChat</h4>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {this.state.currentTab === "webchat" ? (
            <AccountWebChat
              loggedInUser={this.props.user}
              handleModalClose={this.handleCloseModal}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(AccountSettingModal);
