import React, { useContext } from "react";
import { GlobalContext } from "./context/global-context";
import { Formik, Form } from "formik";
import api from "./Api/api";
import { Button } from "antd";

const PostData = () => {
    const ChatContext = useContext(GlobalContext);
    const { setCheckCache } = ChatContext;

    const next = () => {};

    const handleSubmit = (value) => {};

    return (
        <Formik
            initialValues={{
                compname: "",
                WebDomain: "",
                WebName: ""
            }}
            onSubmit={async (values) => {
                handleSubmit(values);
                const postData = await api.post("/posts", { ...values });
                setCheckCache(true);
                // setCurrent(current + 1);
            }}
            onSubmit={(values) => {
                handleSubmit(values);
                // setCurrent(current + 1)
            }}
        >
            {(formikProps) => {
                const { handleChange, handleBlur, handleSubmit, setFieldValue, values } =
                    formikProps;
                return (
                    <Form onSubmit={handleSubmit}>
                        <div className="form-field">
                            <label htmlFor="compname">Company Name</label>
                            <input
                                id="comp_name"
                                name="compname"
                                placeholder="BraeBurn Country Club"
                                type="text"
                                value="Name"
                            />
                        </div>

                        <div className="form-field">
                            <label htmlFor="WebDomain">Website Domain</label>
                            <input
                                id="web_domain"
                                name="WebDomain"
                                placeholder="braeburncc.com"
                                type="url"
                                value={values.WebDomain}
                                onChange={handleChange("WebDomain")}
                            />
                        </div>

                        <div className="form-field">
                            <label htmlFor="WebName">Website Name</label>
                            <input
                                id="web_name"
                                name="WebName"
                                placeholder="BraeBurn Public"
                                type="text"
                                value={values.WebName}
                                onChange={handleChange("WebName")}
                            />
                        </div>

                        <div className="steps-action">
                            {/* <button type="primary" onClick={(values) => handleSubmit(values)}>
                Continue to widget Installation
              </button> */}
                            <Button type="primary" onClick={(values) => handleSubmit(values)}>
                                Continue to widget Installation
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
export default PostData;
