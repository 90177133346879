import React, { useEffect } from "react";
import "./header.scss";
import Profile from "./profiles/Profile";
import MainLogo from "../../../../assets/images/logo.PNG";
import { WebChatActive } from "../../../../assets/svg-icon";
import { useSelector } from "react-redux";
import Favicon from "../../../Favicon";

const Header = (i) => {
    const name = "WebChat"
    const { unreadWebChatList } = useSelector((state) => state.chat)

    useEffect(() => {
        if (!("Notification" in window)) {
            console.log("Browser does not support desktop notification");
        } else {
            Notification.requestPermission();
        }
    }, [])

    return (
        <section className="header">
            <Favicon count={unreadWebChatList?.length}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <figure className="main-logo">
                            <a>
                                <img src={MainLogo} alt="Logo" />
                            </a>
                        </figure>
                        <ul className="head-ul">

                            <li>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img className="navbarimage" src={WebChatActive} alt="not loaded" />
                                    {unreadWebChatList?.length > 0 && (
                                        <div className="aside-menu-unread-message"><span className="unread-message-counter">{unreadWebChatList.length}</span></div>
                                    )}
                                    <span className="navbarText">{name}</span>
                                </div>

                            </li>
                            <li>
                                <Profile />
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;
