import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Spin } from "antd";
import routes from "../../routes";
import { Socket } from "../../utils/socketUtils";
import { APP_ROUTES, getRoute } from "../../helpers/routesHelper";
import Header from "../../components/common/layouts/header/header";
import { getMe, setSoundStatus, setHeaderStatus } from "../../store/actions/authActions";
import { companyWidgetsListing, setCompantListing } from "../../store/actions/automationActions";
import "../automation-screens/welcome-message-screen/WelcomeMessageScreen.scss";
import "react-toastify/dist/ReactToastify.css";
import { isSSOAuthenticated } from "../../utils/ssoUtil";
import { getWebchatUnreadCount, setUnreadWebChatList } from "../../store/actions";
import sendNotification from "../../helpers/sendNotification";
import checkIframe from "../../helpers/checkIframe";

const DefaultLayout = (props) => {
    const { isAuthenticated, user } = props;
    const { unreadWebChatList, currentChat } = useSelector((state) => state.chat)

    const unreadListRef = useRef()
    const currentChatRef = useRef()
    const userRef = useRef()

    unreadListRef.current = unreadWebChatList
    currentChatRef.current = currentChat
    userRef.current = user

    const history = useHistory();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const search = useLocation().search;
    const [headerStatus, setHeader] = useState();
    const authToken = new URLSearchParams(search).get('token');
    const headerKey = new URLSearchParams(search).get('header'); // this is  header from Iframe

    useEffect(
        () => {
            async function companyListChecking() {
                try {
                    let params = new URLSearchParams(window.location.search);
                    if (authToken || isAuthenticated) {
                        // if (!tokenVerified) {
                        setIsLoading(true);
                        if (headerKey === "true") {
                            setHeader(headerKey); // this is  header from Iframe
                            dispatch(setHeaderStatus(false));

                        }
                        const [result1, result2] = await Promise.all([
                            dispatch(getMe(authToken || localStorage.WebChatToken)),
                            dispatch(companyWidgetsListing(authToken || localStorage.WebChatToken))
                        ]);

                        setIsLoading(false);
                        dispatch(setCompantListing(result2));
                        let isCompanyListing = false;
                        if (result2?.find((item) => item.IsCompleted == 0)) {
                            isCompanyListing = true;
                            history.push(getRoute(APP_ROUTES.COMPANY_LISTING));
                        }
                        if (!isCompanyListing) {
                            if (!location.pathname.includes("inbox")) {
                                history.push('/dashboard/inbox/active');
                            }
                        }
                        Socket.init();
                        // }
                    } else {
                        if (!props.userAuthenticated && sessionStorage.apiKey) {
                            return <Redirect to={`/SSO/${sessionStorage.apiKey}`} />;
                        } else if (!props.isAuthenticated && !isSSOAuthenticated()) {
                            return <Redirect to="/account/login" />;
                        }
                    }
                } catch {
                    setIsLoading(false);
                }
            }
            dispatch(getWebchatUnreadCount())
            companyListChecking();

            Socket.listenReadUnreadWebchat((data) => {
                handleWebchatReadUnread(data)
            })

            window.addEventListener("message", iframeParentEvent, false);
            return () => {
                window.removeEventListener("message", iframeParentEvent, false);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const iframeParentEvent = (event) => {
        let parentUrl = window.location?.ancestorOrigins[0];
        if (event.origin !== parentUrl) {
            return;
        }
        let data = event.data;
        if (data) {
            if (data.Message === "SET_SOUND_STATUS") {
                if (data.Data) {
                    try {
                        console.log("webchat iframe sound listening ", data.Data);
                        dispatch(setSoundStatus(data.Data?.SoundStatus));
                    } catch (err) {
                        console.log("err ", err);
                    }
                }
            }
        }
    };

    const handleWebchatReadUnread = (data) => {
        const unreadConvos = [...unreadListRef.current]
        const newConvo = unreadConvos.find((item) => item.Id === data?.ChatId && data?.data?.ReadStatus === 2)
        if (data?.data?.ChatData && !checkIframe()) {
            sendNotification("Notification", `${data?.data?.ChatData?.at(0)?.user?.Name} sent a new message!`, Boolean(userRef.current.Company?.SoundStatus))
        }
        //adding unread conversation to state
        if (!newConvo && data?.data?.ReadStatus === 2 && currentChatRef.current?.id !== data?.ChatId) {
            unreadConvos.push(data.data)
            dispatch(setUnreadWebChatList([...unreadConvos]))
        }
        else if (data.data.ReadStatus === 1) { //removing read conversation from state
            const newUnreadConvos = unreadConvos.filter(item => item.Id !== data?.ChatId)
            dispatch(setUnreadWebChatList([...newUnreadConvos]))
        }
    }


    return (
        <>
            {isLoading ? (
                <div className="loading ">
                    <Spin />
                </div>
            ) : (
                <div id="app-wrapper" className="app-wrapper">
                    {!headerStatus ? <Header /> : null}
                    <Switch>
                        {isAuthenticated || authToken ? (
                            routes.map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        name={route.name}
                                        exact={route.exact}
                                        render={(props) => <route.component {...props} />}
                                    />
                                );
                            })
                        ) : (
                            <Redirect to={APP_ROUTES.ACCOUNT} />
                        )}

                    </Switch>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        tokenVerified: state.root.tokenVerified,
        userAuthenticated: state.ssoAuth.userAuthenticated
    };
};

export default connect(mapStateToProps)(DefaultLayout);
