import { fetchUtil } from "../../utils/fetchUtil";
import { setSoundStatusRequest } from "./authActions";

export const getWebChat = () => (dispatch, getState) => {
  let token = getState().auth.token;
  return fetchUtil({
    url: `/webchats/listing/agent`,
    token
  })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const getUsersByCompany = () => (dispatch, getState) => {
  let token = getState().auth.token;
  return fetchUtil({
    url: `/get/company/users`,
    token
  })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getWebChatWidgetSettings = (WebsiteId) => (dispatch, getState) => {
  let token = getState().auth.token;
  return fetchUtil({
    url: `/webchat/${WebsiteId}`,
    token: token
  })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const updateWidgetSettings = (payload) => (dispatch, getState) => {
  let token = getState().auth.token;
  return fetchUtil({
    method: "PUT",
    body: JSON.stringify(payload),
    url: `/update/widget_settings`,
    token
  })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const setWebchatSoundStatus = (status) => (dispatch, getState) => {
  let token = getState().auth.token;
  let body = JSON.stringify({
    status
  });
  return fetchUtil({
    method: "POST",
    body,
    url: "/setting/sound",
    token,
  })
    .then((response) => {
      dispatch(setSoundStatusRequest(status));
      // setSoundStatus(status)
      return Promise.resolve(true);

    })
    .catch((err) => {
      return Promise.reject(err);
    });
}