import React from "react";

import "./Form.css";

const WithBodyBackground =
    (WrappedComponent) =>
    ({ ...otherProps }) => {
        return (
            <div className="login-screen">
                <div className="container">
                    <div className="row">
                        <div className="w-12">
                            <div className="login-box">
                                <WrappedComponent {...otherProps} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

export default WithBodyBackground;
